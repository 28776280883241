import React, {useEffect, useState} from "react";
import ArrowDown from "../../images/svg/arrow-down-red.svg";
import ArrowUp from "../../images/svg/arrow-up-red.svg";
import PotentialInfluencer from "../../images/svg/potential-influencer.svg";
import Facebook from "../../images/svg/facebook-outline.svg";
import Youtube from "../../images/svg/youtube.svg";
import Instagram from "../../images/svg/insta-outline.svg";
import TikTok from "../../images/svg/tiktok-outline.svg";
import BgAnalyticsSocialMedia from "../../images/sm-deco-left.png";
import AnalyticsData from "../../images/svg/analytics-data.svg";
import ArrowRight from "../../images/svg/arrow-right.svg";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getUserDetailUserRegisteredApi} from "../../api/user-registered.api";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";
import {formatDateToReadable, formatFollower} from "../../ui-util/general-variable";

const UserProfileComponent = () => {
    const [isHide, setIsHide] = useState(true)
    const {id} = useParams();
    const dispatch = useDispatch()
    const {userDetail} = useSelector(state => state.userRegisteredReducer)

    useEffect(() => {
        getUserDetailUserRegisteredApi(id)
            .then(response => {
                dispatch({type: USER_REGISTERED_REDUCER, payload: {userDetail: response}})
            })
            .catch(err => {
                console.log(err.response)
            })
    }, [])

    const getLogoSocialMedia = (socialMedia) => {
        switch (socialMedia) {
            case 'Facebook':
                return Facebook
                break;
            case 'Instagram':
                return Instagram
                break;
            case 'Youtube':
                return Youtube
                break;
            case 'TikTok':
                return TikTok
                break;
        }
    }

    return (
        <div className={"flex flex-row items-center justify-between font-FuturaBT-Book"}>
            <div className={"flex flex-row items-center space-x-20"}>
                <div className={"flex flex-row items-center space-x-3"}>
                    <img src={userDetail?.photo} className={"w-20 h-20 rounded-full"}/>
                    <div>
                        <div className={"flex flex-row items-center space-x-1"}>
                            <p className={"font-FuturaBT-Medium text-lg"}>{userDetail?.full_name}</p>
                            {!!userDetail?.is_potential && <img src={PotentialInfluencer} className={"w-4 h-4"}/>}
                        </div>
                        <p className={"text-xs"}>{formatDateToReadable(userDetail?.birth_date)}</p>
                        {!!userDetail?.is_potential &&
                            <p className={"text-xs text-milano-red"}>Potential Influencer</p>}
                    </div>
                </div>
                {
                    isHide ?
                        <div className={"flex flex-row items-center space-x-5 text-xs font-bold"}>
                            {!!userDetail?.social_medias &&
                                userDetail?.social_medias.map((item, index) => {
                                    switch (item.name) {
                                        case 'Facebook':
                                        case 'Instagram':
                                        case 'Youtube':
                                        case 'TikTok':
                                            return (
                                                <Link
                                                    className={"hover:text-milano-red"}
                                                    to={`/goddess-gang/gg/user-detail/${item.id}/${userDetail?.id}`}
                                                    key={index}>
                                                    <div
                                                        className={"flex flex-col items-center space-y-2"}>
                                                        <img src={getLogoSocialMedia(item.name)} className={"w-6 h-6"}/>
                                                        <p>{formatFollower(item.followers)}</p>
                                                    </div>
                                                </Link>
                                            )
                                            break;
                                    }
                                })
                            }
                        </div>
                        :
                        <div className={"text-sm flex flex-row items-center space-x-20"}>
                            <div>
                                <p>Notes</p>
                                <p>-</p>
                            </div>
                            <div className={"flex flex-row items-center space-x-5"}>
                                {!!userDetail?.social_medias &&
                                    userDetail?.social_medias.map((item, index) => {
                                        switch (item.name) {
                                            case 'Facebook':
                                            case 'Instagram':
                                            case 'Youtube':
                                            case 'TikTok':
                                                return (
                                                    <Link
                                                        className={"hover:text-milano-red"}
                                                        to={`/goddess-gang/gg/user-detail/${item.id}/${userDetail?.id}`}
                                                        key={index}>
                                                        <div className={"flex border h-20 w-44 relative"} key={index}>
                                                            <img src={BgAnalyticsSocialMedia}
                                                                 className={"w-16 h-10 absolute bottom-0 left-0"}/>
                                                            <img src={AnalyticsData}
                                                                 className={"w-7 h-7 absolute bottom-0 left-0"}/>
                                                            <div className={"p-2 flex flex-row space-x-2 w-full"}>
                                                                <img src={getLogoSocialMedia(item.name)}
                                                                     className={"w-4 h-4"}/>
                                                                <div className={"text-xs"}>
                                                                    <p className={"font-bold"}>{item.name}</p>
                                                                    <p>@{item.username}</p>
                                                                    <div
                                                                        className={"flex flex-row items-center space-x-1"}>
                                                                        <p className={"text-tiny text-milano-red"}>{formatFollower(item.followers)}</p>
                                                                        <p className={"font-bold text-xxs"}>Followers</p>
                                                                    </div>
                                                                </div>
                                                                <div className={"flex flex-col-reverse"}>
                                                                    <div
                                                                        className={"flex flex-row items-center space-x-1"}>
                                                                        <p className={"text-milano-red font-bold text-xs"}>Analytics</p>
                                                                        <img src={ArrowRight} className={"w-2 h-2"}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                                break;
                                        }
                                    })
                                }
                            </div>
                        </div>
                }
            </div>

            <button className={"text-sm font-FuturaBT-Medium text-milano-red flex flex-row items-center space-x-2"}
                    onClick={() => setIsHide(!isHide)}>
                <p>{isHide ? 'Show' : 'Hide'} Detail</p>
                <img src={isHide ? ArrowDown : ArrowUp} className={"w-3 h-3"}/>
            </button>
        </div>
    )
}

export default UserProfileComponent

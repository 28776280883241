import React, {useState} from "react";
import TaskDetailComponent from "./task-detail.component";
import Tiktok from "../../images/svg/tiktok-outline.svg";
import Youtube from "../../images/svg/youtube.svg";
import Instagram from "../../images/svg/insta-outline.svg";
import Facebook from "../../images/svg/facebook-outline.svg";
import ErrorMessageComponent from "../../components/error-message.component";
import {useSelector} from "react-redux";

const CampaignTaskComponent = () => {
    const {createCampaignErrorMessage} = useSelector(state => state.createCampaignReducer)
    const [taskTiktok, setTaskTiktok] = useState(false)
    const [taskYoutube, setTaskYoutube] = useState(false)
    const [taskInstagram, setTaskInstagram] = useState(false)
    const [taskFacebook, setTaskFacebook] = useState(false)

    const taskList1 = [
        {
            key: 'tiktok_task',
            label: 'TikTok',
            taskKey: taskTiktok,
            setTask: setTaskTiktok,
            icon: Tiktok,
            content: 'tiktok_task_content',
            mention: 'tiktok_task_mention',
            hashtag: 'tiktok_task_hashtag'
        }, {
            key: 'youtube_task',
            label: 'Youtube',
            taskKey: taskYoutube,
            setTask: setTaskYoutube,
            icon: Youtube,
            content: 'youtube_task_content',
            mention: 'youtube_task_mention',
            hashtag: 'youtube_task_hashtag'
        },
    ]
    const taskList2 = [
        {
            key: 'instagram_task',
            label: 'Instagram',
            taskKey: taskInstagram,
            setTask: setTaskInstagram,
            icon: Instagram,
            content: 'instagram_task_content',
            mention: 'instagram_task_mention',
            hashtag: 'instagram_task_hashtag'
        }, {
            key: 'facebook_task',
            label: 'Facebook',
            taskKey: taskFacebook,
            setTask: setTaskFacebook,
            icon: Facebook,
            content: 'facebook_task_content',
            mention: 'facebook_task_mention',
            hashtag: 'facebook_task_hashtag'
        }
    ]

    return (
        <div className={"my-5 py-5 border-y"}>
            <p className={"text-2xl text-gray-400 font-FuturaBT-Medium font-bold"}>Task</p>
            <div className={"mt-5 flex flex-row justify-between"}>
                <div className={"w-1/2 space-y-5"}>
                    {
                        taskList1.map((item, index) => <TaskDetailComponent item={item} index={index} key={index}/>)
                    }
                </div>
                <div className={"w-1/2 space-y-5"}>
                    {
                        taskList2.map((item, index) => <TaskDetailComponent item={item} index={index} key={index}/>)
                    }
                </div>
            </div>
            {!!createCampaignErrorMessage['task'] &&
                <ErrorMessageComponent message={createCampaignErrorMessage['task']}/>}
        </div>
    )
}

export default CampaignTaskComponent

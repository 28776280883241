import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CAMPAIGN_REDUCER} from "../../reducers/campaign.reducer";
import {TOOLS_REDUCER} from "../../reducers/tools.reducer";

const CampaignTabComponent = () => {
    const dispatch = useDispatch()
    const {activeTab} = useSelector(state => state.campaignReducer)
    const [tabList] = useState([{
        label: 'Upcoming',
        key: 'upcoming'
    }, {
        label: 'Approvement',
        key: 'approvement'
    }, {
        label: 'Ongoing',
        key: 'ongoing'
    }, {
        label: 'Past',
        key: 'past'
    }, {
        label: 'Draft',
        key: 'draft'
    }])

    const changeTabHandler = (key) => {
        dispatch({type: TOOLS_REDUCER, payload: {currentPage: 1, pageSize: 5, startDate: '', endDate: ''}})
        dispatch({type: CAMPAIGN_REDUCER, payload: {activeTab: key}})
    }

    return (
        <div className={"flex flex-row items-center font-FuturaBT-Book"}>
            {
                tabList.map((item, index) => (
                    <button className={"border px-8 py-4"} onClick={() => changeTabHandler(item.key)} key={index}>
                        <p className={`text-2xl font-semibold ${activeTab === item.key ? 'text-milano-red' : 'text-gray-400'}`}>{item.label}</p>
                    </button>
                ))
            }
        </div>
    )
}

export default CampaignTabComponent

import {combineReducers} from 'redux';
import {missionReducer} from "./mission.reducer";
import {userRegisteredReducer} from "./user-registered.reducer";
import {modalReducer} from "./modal.reducer";
import {toolsReducer} from "./tools.reducer";
import {groupReducer} from "./group.reducer";
import {createCampaignReducer} from "./create-campaign.reducer";
import {campaignReducer} from "./campaign.reducer";
import {approvalCampaignReducer} from "./approval-campaign.reducer";
import {analyticsReducer} from "./analytics.reducer";
import {bannerReducer} from "./banner.reducer";
import {questionerReducer} from "./questioner.reducer";
import {timelineReducer} from "./timeline.reducer";
import {redeemReducer} from "./redeem.reducer";

export default combineReducers({
    missionReducer,
    userRegisteredReducer,
    modalReducer,
    toolsReducer,
    groupReducer,
    createCampaignReducer,
    campaignReducer,
    approvalCampaignReducer,
    analyticsReducer,
    bannerReducer,
    questionerReducer,
    timelineReducer,
    redeemReducer
})

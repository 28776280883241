import React, {useEffect, useState} from "react";
import ArrowDown from "../images/svg/arrow-down.svg";
import Sosmed from "../images/svg/ico-sosmed.svg";
import Location from "../images/svg/ico-location.svg";
import {useDispatch, useSelector} from "react-redux";
import {TOOLS_REDUCER} from "../reducers/tools.reducer";
import {USER_REGISTERED_REDUCER} from "../reducers/user-registered.reducer";

const SelectComponent = (props) => {
    const {title} = props
    const dispatch = useDispatch()
    const {cityList, socialMediaList} = useSelector(state => state.toolsReducer)
    const {groupListAll} = useSelector(state => state.groupReducer)
    const [showOption, setShowOption] = useState(false)
    const [selectSearchKey, setSelectedSearchKey] = useState('')
    const [value, setValue] = useState(title)
    const [statusList] = useState([
        {name: 'Approved', id: 'approve'}, {name: 'Rejected', id: 'reject'}
    ])

    useEffect(() => {
        dispatch({type: TOOLS_REDUCER, payload: {selectValue: title}})
    }, [])

    const setSelectValue = (item) => {
        setValue(item.name)
        selectHandler(item)
    }

    const selectHandler = (item) => {
        let data = {}
        switch (title) {
            case 'Status':
                data = 'statusToUpdate'
                break;
            case 'All Sosmed':
                data = 'social_media_id'
                break;
            case 'City':
                data = 'city_id'
                break;
            case 'Group':
                data = 'groupToUpdate'
                break;
        }
        dispatch({type: USER_REGISTERED_REDUCER, payload: {[data]: item.id}})
    }

    const optionList = () => {
        switch (title) {
            case 'Status':
                return statusList
                break;
            case 'Group':
                return groupListAll
                break;
            case 'All Sosmed':
                return socialMediaList
                break;
            case 'City':
                return cityList
                break;
            default:
                return []
        }
    }

    const imagePrefix = () => {
        switch (title) {
            case 'All Sosmed':
                return Sosmed
            case 'City':
                return Location
            default:
                return;
        }
    }

    const showPrefixImage = () => {
        switch (title) {
            case 'All Sosmed':
            case 'City':
                return true
            default:
                return false
        }
    }

    const onChangeSearchInput = (e) => {
        setSelectedSearchKey(e.target.value)
    }

    const filterListBySearchKey = () => {
        const filteredList = optionList().filter((item) => item.name.toLowerCase().includes(selectSearchKey.toLowerCase()))

        return filteredList
    }

    return (
        <div className={"min-w-min group"}>
            <button className={"btn relative height-resp px-1 space-x-1"} onClick={() => setShowOption(!showOption)}>
                {showPrefixImage() && <img className={"filter-icon-resp"} src={imagePrefix()}/>}
                <p className={"font-size-resp"}>{value}</p>
                <img className={"w-2 h-2"} src={ArrowDown}/>
            </button>
            {
                <ul className={"invisible group-hover:visible absolute  font-FuturaBT-Book border flex-col p-4 ml-1 bg-white overflow-scroll max-h-52 "}>
                    {(title === 'Interest' || title === 'City') &&
                        <input placeholder={"Search"} className={"p-2 border outline-0"}
                               onChange={onChangeSearchInput}/>}
                    {
                        title === 'Group' &&
                        <a href={'/goddess-gang/group/create'}>
                            <li className={"p-2 hover:bg-gray-200"}>Add New Group +</li>
                        </a>
                    }
                    {
                        filterListBySearchKey().map((item, index) => (
                            <li className={"p-2 hover:bg-gray-200"} onClick={() => setSelectValue(item)}
                                key={index.toString()}>{item.name}</li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}

export default SelectComponent

import React, {useEffect} from "react";
import ProfileComponent from "./profile.component";
import DistributionComponent from "./distribution.component";
import TimelineComponent from "./timeline.component";
import ExportSocialMediaComponent from "./export-social-media.component";
import {useDispatch, useSelector} from "react-redux";
import {getAnalyticsSocialMediaApi} from "../../api/user-registered.api";
import {ANALYTICS_REDUCER} from "../../reducers/analytics.reducer";
import {useLocation, useParams} from "react-router-dom";

const AnalyticsSocialMediaPage = () => {
    const dispatch = useDispatch()
    const {id} = useParams();
    const location = useLocation();
    const pathRaw = location.pathname.split('/')
    const {analyticsFacebookResult, limit_similliar_influencer} = useSelector(state => state.analyticsReducer)

    useEffect(() => {
        let requestBody = {
            user_id: id,
            social_media_id: Number(pathRaw[4]),
            limit_similliar_influencer,
            influencers_brand_name_sort: "",
            influencers_brand_name_search: ""
        }
        getAnalyticsSocialMediaApi(requestBody)
            .then(response => {
                dispatch({type: ANALYTICS_REDUCER, payload: {analyticsFacebookResult: response}})
            })
            .catch(err => console.log(err))

    }, [limit_similliar_influencer])

    if (!!analyticsFacebookResult?.username) {
        return (
            <div className={"space-y-8 pb-10"}>
                <div className={"flex flex-row-reverse"}>
                    <ExportSocialMediaComponent/>
                </div>
                <ProfileComponent/>
                <DistributionComponent/>
                <TimelineComponent/>
            </div>
        )
    } else {
        return (
            <div className={"pt-20 flex flex-row w-full justify-center font-FuturaBT-Medium"}>
                <p>No data user analytics, sorry</p>
            </div>
        )
    }
}

export default AnalyticsSocialMediaPage

import React, {useEffect} from "react";
import CampaignPage from "../campaign";
import {useDispatch} from "react-redux";
import {CAMPAIGN_REDUCER} from "../../reducers/campaign.reducer";

const ApprovementCampaignPage = () => {
  const dispatch = useDispatch()

    useEffect(() => {
      dispatch({type: CAMPAIGN_REDUCER, payload: {activeTab: 'approvement'}})
    }, [])

  return (
      <CampaignPage />
  )
}

export default ApprovementCampaignPage

import React, {useState} from "react";
import Calendar from "../../images/svg/ico-calendar-red.svg";
import {customDateToReadable, totalCalculation} from "../../ui-util/general-variable";
import Time from "../../images/svg/ico-time.svg";
import FacebookRed from "../../images/svg/facebook-outline.svg";
import YoutubeRed from "../../images/svg/youtube.svg";
import TiktokRed from "../../images/svg/tiktok-outline.svg";
import InstagramRed from "../../images/svg/insta-outline.svg";
import HTMLReactParser from "html-react-parser";
import {useSelector} from "react-redux";
import User from "../../images/svg/ico-man.svg";
import Plane from "../../images/svg/plane-outline-white.svg";
import Video from "../../images/svg/ico-video.svg";
import Scenery from "../../images/svg/ico-image.svg";
import CommentIcon from "../../images/svg/ico-comment.svg";
import Engagement from "../../images/svg/ico-broadcast.svg";

const ReportCampaignDetailComponent = () => {
    const {campaignDetail} = useSelector(state => state.campaignReducer)
    const [TotalList] = useState([
        {
            key: 'influencer',
            icon: User,
            label: 'Total Influencers',
            unit: 'People',
            extra: false
        }, {
            key: 'likes',
            icon: Plane,
            label: 'Total Likes',
            unit: 'Likes',
            extra: false,
        }, {
            key: 'stories',
            icon: Video,
            label: 'Total Stories',
            unit: 'Story',
            extra: false
        }, {
            key: 'posts',
            icon: Scenery,
            label: 'Total Post',
            unit: 'Post',
            extra: false
        }, {
            key: 'comments',
            icon: CommentIcon,
            label: 'Total Comment',
            unit: 'Comment',
            extra: false,
        }, {
            key: 'engagement',
            icon: Engagement,
            label: 'Total Engagement',
            unit: 'People',
            extra: true
        }, {
            key: 'esr',
            icon: Video,
            label: 'Total EST. Reach',
            unit: '',
            extra: false,
        }, {
            key: 'media_value',
            icon: Scenery,
            label: 'Total Media Value',
            unit: '',
            extra: false
        },
    ])

    return (
        <div>
            <p className={"font-bold capitalize text-lg-responsive my-10"}>{campaignDetail?.data?.title}</p>
            <div className={"flex flex-row justify-between w-full space-x-5"}>
                <img src={campaignDetail?.data?.thumbnail} className={"w-1/5 object-contain"}/>
                <div className={"w-2/5 text-tiny-responsive"}>
                    <p className={"font-bold h-1/6 text-tiny-responsive"}>Detail Campaign</p>
                    <div className={"border p-5 text-sm h-5/6 flex flex-col justify-center"}>
                        <div className={"grid grid-cols-3 gap-3 text-tiny-responsive flex flex-row justify-between"}>
                            <div>
                                <p className={"font-bold"}>Start date</p>
                                <div className={"flex flex-row items-center"}>
                                    <img src={Calendar} className={"img-5-responsive"}/>
                                    <p>{customDateToReadable(campaignDetail?.data?.campaign_start_date, 'dddd')}, {customDateToReadable(campaignDetail?.data?.campaign_start_date, 'LL')}</p>
                                </div>
                            </div>
                            <div>
                                <p className={"font-bold"}>End date</p>
                                <div className={"flex flex-row items-center"}>
                                    <img src={Calendar} className={"img-5-responsive"}/>
                                    <p>{customDateToReadable(campaignDetail?.data?.campaign_end_date, 'dddd')}, {customDateToReadable(campaignDetail?.data?.campaign_end_date, 'LL')}</p>
                                </div>
                            </div>
                            <div>
                                <p className={"font-bold"}>Point Campaign</p>
                                <p>{campaignDetail?.data?.point} Point</p>
                            </div>
                            <div>
                                <p className={"font-bold"}>Start time</p>
                                <div className={"flex flex-row items-center space-x-1"}>
                                    <img src={Time} className={"img-4-responsive"}/>
                                    <p>{campaignDetail?.data?.campaign_start_time}</p>
                                </div>
                            </div>
                            <div>
                                <p className={"font-bold"}>End time</p>
                                <div className={"flex flex-row items-center space-x-1"}>
                                    <img src={Time} className={"img-4-responsive"}/>
                                    <p>{campaignDetail?.data?.campaign_end_time}</p>
                                </div>
                            </div>
                            <div>
                                <p className={"font-bold"}>Upload</p>
                                <div className={"flex flex-row items-center space-x-4-responsive"}>
                                    {
                                        !!campaignDetail?.data?.facebook_task?.content &&
                                        <img src={FacebookRed} className={"img-3-responsive"}/>
                                    }
                                    {
                                        !!campaignDetail?.data?.youtube_task?.content &&
                                        <img src={YoutubeRed} className={"img-5-responsive"}/>
                                    }
                                    {
                                        !!campaignDetail?.data?.tiktok_task?.content &&
                                        <img src={TiktokRed} className={"img-3-responsive"}/>
                                    }
                                    {
                                        !!campaignDetail?.data?.instagram_task?.content &&
                                        <img src={InstagramRed} className={"img-3-responsive"}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"w-2/5 text-tiny-responsive"}>
                    <p className={"font-bold h-1/6"}>Deskripsi Campaign</p>
                    <div className={"border h-5/6 p-5"}>
                        {!!campaignDetail?.data?.description && HTMLReactParser(campaignDetail?.data?.description)}
                    </div>
                </div>
            </div>
            <div className={"grid grid-cols-8 gap-5-responsive mt-10 font-FuturaBT-Medium"}>
                {
                    TotalList.map((item, index) => (
                        <div
                            className={"border p-3-responsive bg-no-repeat bg-right-bottom bg-70% bg-campaign-detail-background relative"}
                            key={index}>
                            <p className={"text-sm-responsive"}>{item.label}</p>
                            <p className={"text-milano-red text-base-responsive"}>{!!campaignDetail?.calculation && totalCalculation(item.key, campaignDetail?.calculation[item.key]?.total)} {item.unit}</p>
                            <p className={"text-xxs-responsive text-milano-red"}>{item.extra && !!campaignDetail?.calculation && campaignDetail?.calculation[item.key]?.caption}</p>
                            <img src={item.icon} className={"img-6-responsive absolute bottom-0 right-0"}/>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ReportCampaignDetailComponent

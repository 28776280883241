import React from "react";
import {TOOLS_REDUCER} from "../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";

const PageSizeComponent = () => {
    const dispatch = useDispatch()
    const {pageSize} = useSelector(state => state.toolsReducer)

    const onChangePageSizeHandler = (value) => {
        if(!isNaN(value)){
            dispatch({type: TOOLS_REDUCER, payload: {pageSize: value, currentPage: 1}})
        }
    }

    return (
        <div className={"flex flex-row items-center font-FuturaBT-Book ml-2"}>
            <input className={"border height-resp xl:w-7 2xl:w-8 text-center font-size-resp"} value={pageSize}
                   onChange={(e) => onChangePageSizeHandler(e.target.value)}/>
            <p className={"flex flex-row ml-2 xl:text-sm 2xl:text-tiny"}>/Page</p>
        </div>
    )
}

export default PageSizeComponent

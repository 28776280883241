import React from "react";

const ErrorMessageComponent = (props) => {
    const {message} = props

    return (
        <p className={"text-milano-red text-xs mt-2 font-FuturaBT-Medium"}>{message}</p>
    )
}

export default ErrorMessageComponent

import React, {useEffect, useState} from "react";
import CalendarRed from "../../images/svg/ico-calendar-red.svg";
import {formatDateToReadable} from "../../ui-util/general-variable";
import {Link, useParams} from "react-router-dom";
import AddInfluencer from "../../images/svg/add-friends.svg";
import BackgroundTotal from "../../images/mission-deco.jpg";
import {useDispatch, useSelector} from "react-redux";
import TotalMission from "../../images/svg/ico-mission.svg";
import TotalOngoing from "../../images/svg/ico-loading.svg";
import TotalDone from "../../images/svg/approve-white.svg";
import {getGroupDetailApi} from "../../api/group.api";
import {GROUP_REDUCER} from "../../reducers/group.reducer";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";

const GroupDetailComponent = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const {groupDetailToShow, reload} = useSelector(state => state.groupReducer)
    const [totalList] = useState([
        {
            key: 'total_mission',
            icon: TotalMission,
            label: 'Total Mission'
        }, {
            key: 'total_ongoing',
            icon: TotalOngoing,
            label: 'Total Ongoing'
        }, {
            key: 'total_done',
            icon: TotalDone,
            label: 'Total Done'
        }
    ])

    useEffect(() => {
        getGroupDetailApi(id)
            .then(response => {
                dispatch({type: GROUP_REDUCER, payload: {groupDetailToShow: response}})
            })
            .catch(err => {
                console.log('Error getGroupDetailApi', err.response)
            })
    }, [reload])

    return (
        <div className={"font-FuturaBT-Medium flex flex-row justify-between items-center"}>
            <div>
                <span className={"text-xl"}>{groupDetailToShow?.name}</span>
                <p className={"text-sm mt-5"}>{groupDetailToShow?.campaign_name}</p>
                <div className={"flex flex-row items-center"}>
                    <img className={"2xl:w-8 2xl:h-8 xl:w-6 xl:h-6 -ml-2"} src={CalendarRed} alt={'calendar'}/>
                    <p className={"text-sm"}>{formatDateToReadable(groupDetailToShow?.created_at)}</p>
                </div>
                <div className={"flex flex-row items-center space-x-2"}>
                    {groupDetailToShow?.group_users?.length > 0 &&
                        groupDetailToShow?.group_users.map((i, idx) => (
                            <img src={i.user.photo} className={"w-8 h-8 rounded-full"} key={idx.toString()}/>
                        ))
                    }
                    <p className={"text-xs text-gray-600"}>{groupDetailToShow?.total_user} Orang</p>
                </div>
            </div>
            <Link to={"/goddess-gang/all-users"}>
                <button className={"flex flex-row items-center bg-milano-red px-5 h-10 hover:bg-opacity-90"}
                        onClick={() => dispatch({
                            type: USER_REGISTERED_REDUCER,
                            payload: {groupToUpdate: groupDetailToShow?.id, groupDetail: groupDetailToShow}
                        })}
                >
                    <img className={"filter-icon-resp"} src={AddInfluencer} alt={'AddInfluencer'}/>
                    <p className={"text-white text-sm"}>Add Influencer</p>
                </button>
            </Link>
            <div className={"flex flex-row items-center space-x-5"}>
                {
                    totalList.map((item, index) => (
                        <div key={index.toString()} className={"relative"}>
                            <img src={BackgroundTotal} className={"relative w-44 h-28"}/>
                            <div className={"absolute bottom-0 right-0 p-2"}>
                                <img src={item.icon} className={"bg-none w-4 h-4 bg-black"}/>
                            </div>
                            <div className={"absolute left-0 top-0 w-full"}>
                                <p className={"mt-2 ml-2"}>{item.label}</p>
                                <p className={"mt-1 ml-2 text-milano-red"}>{groupDetailToShow[item.key]} Mission</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default GroupDetailComponent

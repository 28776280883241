import React from "react";
import BackgroundGroup from "../../images/group-deco.jpg";
import Trash from "../../images/svg/trash.svg";
import {useDispatch, useSelector} from "react-redux";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {formatDateToReadable} from "../../ui-util/general-variable";
import {GROUP_REDUCER} from "../../reducers/group.reducer";
import {Link} from "react-router-dom";

const GroupListComponent = () => {
    const dispatch = useDispatch()
    const {groupList} = useSelector(state => state.groupReducer)

    const showDeleteModalHandler = (groupDetail) => {
        dispatch({type: MODAL_REDUCER, payload: {deleteGroupModalVisibility: 'block'}})
        dispatch({type: GROUP_REDUCER, payload: {groupDetail}})
    }

    return (
        <>
            <div className={"grid gap-10 grid-cols-5 mt-10 font-FuturaBT-Medium"}>
                {
                    groupList.map((item, index) => (
                        <div className={"relative flex"} key={index.toString()}>
                            <div className={"absolute "}>
                                <img src={BackgroundGroup} className={"bg-none w-8 h-8"}/>
                            </div>
                            <button onClick={() => showDeleteModalHandler(item)}
                                    className={"absolute right-0 top-0 bg-gray-300 border border-gray-400 rounded"}>
                                <img src={Trash} className={"bg-none w-4 h-4"}/>
                            </button>
                            <div className={"flex flex-col items-center justify-center w-full space-y-2 my-2"}>
                                <p className={"font-FuturaBT-Medium text-sm"}>{item.name}</p>
                                <div className={"flex flex-row items-center space-x-2"}>
                                    <p className={"text-xs"}>{item.campaign_name}</p>
                                    <div className={"bg-black rounded w-0.5 h-0.5"}/>
                                    <p className={" text-xs text-gray-400"}>{formatDateToReadable(item.created_at)}</p>
                                </div>
                                <div className={"flex flex-row items-center space-x-2"}>
                                    {
                                        item.group_users.map((i, idx) => (
                                            <img src={i.user.photo} className={"w-8 h-8 rounded-full"}
                                                 key={idx.toString()}/>
                                        ))
                                    }
                                    <p className={"text-xs text-gray-600"}>{item.total_user} Orang</p>
                                </div>
                                <Link to={`/goddess-gang/group/group-detail/${item.id}`}>
                                    <button className={"text-xs text-milano-red"}>See Detail</button>
                                </Link>
                            </div>
                            <div className={"absolute bottom-0 right-0 rotate-180"}>
                                <img src={BackgroundGroup} className={"bg-none w-8 h-8"}/>
                            </div>
                        </div>
                    ))
                }
            </div>
            {groupList.length === 0 &&
                <div className={"flex flex-row justify-center mt-20 items-center text-milano-red font-FuturaBT-Book"}>No
                    data</div>
            }
        </>
    )
}

export default GroupListComponent

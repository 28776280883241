import React, {useState} from "react";
import {DatePicker} from "antd";
import moment from "moment";
import {customDateToReadable} from "../../ui-util/general-variable";
import {getDataExportAffable} from "../../api/affable.api";
import ExportComponent from "../../components/export.component";

const LogAffablePage = () => {
    const [requestProfileList, setRequestProfileList] = useState(null)
    const [getProfileList, setGetProfileList] = useState(null)
    const [month, setMonth] = useState(moment())

    const exportHandler = (type) => {
        let requestBody = {
            month: customDateToReadable(month, 'MM-YYYY'),
            type,
            level: 'info',
            action: ''
        }

        getDataExportAffable(requestBody)
            .then(response => {
                if(type === 'LOG_AFFABLE_GET_DETAIL_PROFILE'){
                    setGetProfileList(response)
                } else {
                    setRequestProfileList(response)
                }
            })
            .catch(err => console.log(err))
    }
    return (
        <div className={"font-FuturaBT-Medium flex flex-col space-y-16 mt-10"}>
            <div className={"flex flex-row items-center space-x-10"}>
                <p className={"w-44"}>Request Profile Affable</p>
                <DatePicker defaultValue={moment()} format={'MM-YYYY'} picker="month"
                            onChange={(date) => {
                                setMonth(date)
                                setRequestProfileList(null)
                            }}/>
                <button className={`bg-milano-red text-white px-8 h-8`}
                        onClick={() => exportHandler('LOG_AFFABLE_REQUEST_INFLUENCER_PROFILING')}>Get data
                </button>
                {
                    !!requestProfileList &&
                    <ExportComponent dataSource={requestProfileList} fileName={`Request Influencer profiling - ${customDateToReadable(month, 'MM-YYYY')}`}/>
                }
            </div>
            <div className={"flex flex-row items-center space-x-10"}>
                <p className={"w-44"}>Get Profile Affable</p>
                <DatePicker defaultValue={moment()} format={'MM-YYYY'} picker="month"
                            onChange={(date) => {
                                setMonth(date)
                                setGetProfileList(null)
                            }}/>
                <button className={"bg-milano-red text-white px-8 h-7"}
                        onClick={() => exportHandler('LOG_AFFABLE_GET_DETAIL_PROFILE')}>Get Data
                </button>
                {
                    !!getProfileList &&
                    <ExportComponent dataSource={getProfileList} fileName={`Get Detail profile - ${customDateToReadable(month, 'MM-YYYY')}`}/>
                }
            </div>
        </div>
    )
}

export default LogAffablePage

import React, {useEffect} from "react";
import IntroductionComponent from "./introduction.component";
import UnpublishedComponent from "./unpublished.component";
import PublishedComponent from "./published.component";
import {createAndUpdateQuestionerApi, getQuestionerListApi} from "../../api/questioner.api";
import {useDispatch, useSelector} from "react-redux";
import {QUESTIONER_REDUCER} from "../../reducers/questioner.reducer";
import SuccessModal from "../../components/success.modal";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";

const QuestionerFormPage = () => {
    const dispatch = useDispatch()
    let {questionerFormDetail, reload} = useSelector(state => state.questionerReducer)

    useEffect(() => {
        getQuestionerListApi()
            .then(response => {
                dispatch({
                    type: QUESTIONER_REDUCER,
                    payload: {
                        questionerFormDetail: response,
                    }
                })
            })
            .catch(err => {
                console.log(err)
            })
    }, [reload])

    const publishHandler = () => {
        questionerFormDetail.details = questionerFormDetail.detail_published.concat(questionerFormDetail.detail_unpublished)
        createAndUpdateQuestionerApi(questionerFormDetail)
            .then(response => {
                dispatch({type: QUESTIONER_REDUCER, payload: {reload: !reload}})
                dispatch({type: MODAL_REDUCER, payload: {display: 'block'}})
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div>
            <IntroductionComponent/>
            <div className={"grid grid-cols-2 gap-10 mt-5"}>
                <UnpublishedComponent/>
                <PublishedComponent/>
            </div>
            <div className={"my-10 border-t pt-10 flex flex-row-reverse"}>
                <button className={"bg-milano-red text-white font-FuturaBT-Medium px-8 h-7"}
                        onClick={() => publishHandler()}
                >Publish</button>
            </div>
            <SuccessModal message={'Questioner berhasil di perbaharui'}/>
        </div>
    )
}

export default QuestionerFormPage

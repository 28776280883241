const INITIAL_STATE = {
    campaignList: [],
    activeTab: 'upcoming',
    campaignDetailActiveTab: 'influencer',
    campaignDetail: {},
    influencerList: [],
    influencerDetail: {},
    campaignDetailToDelete: {},
    reloadInfluencerList: false,
    status: '',
    contentCampaign: {},
    campaignIdToDelete: '',
    reload: false,
    mention: [],
    hashtag: []
}

export const CAMPAIGN_REDUCER = 'CAMPAIGN_REDUCER';

export const campaignReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CAMPAIGN_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}

import React, {useEffect} from "react";
import RejectedFilterComponent from "./rejected-filter.component";
import SuccessModal from "../../components/success.modal";
import UserListComponent from "../user-registered/user-list.component";
import {useDispatch, useSelector} from "react-redux";
import {getCityList, getSocialMediaList} from "../../api/user-registered.api";
import {FLUSH_SORTING, TOOLS_REDUCER} from "../../reducers/tools.reducer";
import {getGoddessGangUserList} from "../../api/user.api";
import {FLUSH_USER_REGISTERED_REDUCER, USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";
import {customDateToReadable} from "../../ui-util/general-variable";

const RejectedPage = () => {
    const dispatch = useDispatch()
    const {pageSize, currentPage, searchKey, sort, orderBy, startDate, endDate, reloadDatePicker} = useSelector(state => state.toolsReducer)
    const {
        min_correct,
        max_correct,
        reload,
        ig_followers_min,
        ig_followers_max,
        ig_er_min,
        ig_er_max,
        ig_reach_min,
        ig_reach_max,
        tiktok_followers_min,
        tiktok_followers_max,
        tiktok_er_min,
        tiktok_er_max,
        tiktok_reach_min,
        tiktok_reach_max,
        social_media_id,
        interest_id,
        city_id
    } = useSelector(state => state.userRegisteredReducer)

    useEffect(() => {
        Promise.all([getCityList(), getSocialMediaList()])
            .then(response => {
                let cityList = [...[{id: '', name: 'All City'}], ...response[0]]
                let socialMediaList = [...[{id: '', name: 'All Social Media'}], ...response[1]]
                dispatch({
                    type: TOOLS_REDUCER, payload: {
                        cityList,
                        socialMediaList,
                    }
                })
            })
            .catch(err => {
                console.log('Error get promise all user registered index', err.response)
            })

        return () => {
            dispatch({type: FLUSH_USER_REGISTERED_REDUCER})
            dispatch({type: FLUSH_SORTING})
        }
    }, [])

    useEffect(() => {
        let params = {
            page: currentPage,
            limit: pageSize,
            orderby: orderBy,
            sort
        }
        let bodyRequest = {
            keyword: searchKey,
            status: 'reject',
            min_correct,
            max_correct,
            ig_followers_min,
            ig_followers_max,
            ig_er_min,
            ig_er_max,
            ig_reach_min,
            ig_reach_max,
            tiktok_followers_min,
            tiktok_followers_max,
            tiktok_er_min,
            tiktok_er_max,
            tiktok_reach_min,
            tiktok_reach_max,
            start_date: startDate && customDateToReadable(startDate, 'YYYY-MM-DD'),
            end_date: endDate && customDateToReadable(endDate, 'YYYY-MM-DD'),
            social_media_id,
            interest_id,
            city_id
        }

        getGoddessGangUserList(bodyRequest, params)
            .then(response => {
                dispatch({
                    type: TOOLS_REDUCER, payload: {
                        totalData: response.total_data
                    }
                })
                dispatch({
                    type: USER_REGISTERED_REDUCER, payload: {
                        userList: response.rows
                    }
                })
            })
            .catch(err => {
                console.log('Error getGoddessGangUserList', err.response)
            })

    }, [
        pageSize,
        reload,
        ig_followers_min,
        ig_followers_max,
        ig_er_min,
        ig_er_max,
        ig_reach_min,
        ig_reach_max,
        tiktok_followers_min,
        tiktok_followers_max,
        tiktok_er_min,
        tiktok_er_max,
        tiktok_reach_min,
        tiktok_reach_max,
        social_media_id,
        interest_id,
        city_id,
        searchKey,
        orderBy,
        sort,
        currentPage,
        reloadDatePicker
    ])
    return (
        <>
            <RejectedFilterComponent/>
            <UserListComponent/>
            <SuccessModal message={'Successfully update user profile'}/>
        </>
    )
}

export default RejectedPage

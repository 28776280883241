import React from "react";
import SearchComponent from "../../components/search.component";
import PopoverFilterInstagramTiktokComponent from "../../components/popover-filter-instagram-tiktok.component";
import ExportComponent from "../../components/export.component";
import SendEmailComponent from "../../components/send-email.component";
import PageSizeComponent from "../../components/page-size.component";
import Trash from '../../images/svg/trash.svg'
import {useDispatch, useSelector} from "react-redux";
import {removeUserFromGroupApi} from "../../api/group.api";
import {GROUP_REDUCER} from "../../reducers/group.reducer";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import DeleteConfirmationModal from "../../components/modal/delete-confirmation.modal";
import {useParams} from "react-router-dom";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";
import {formatDateToReadable} from "../../ui-util/general-variable";

const GroupDetailFilterComponent = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const {selectedRowKeys, reload, selectedRows} = useSelector(state => state.userRegisteredReducer)

    const removeUserHandler = () => {
        let requestBody = {
            group_id: id,
            user_ids: selectedRowKeys
        }
        removeUserFromGroupApi(requestBody)
            .then(response => {
                dispatch({type: GROUP_REDUCER, payload: {reload: !reload}})
                dispatch({type: MODAL_REDUCER, payload: {deleteGroupModalVisibility: 'hidden'}})
                dispatch({type: USER_REGISTERED_REDUCER, payload: {selectedRowKeys: [], selectedRows: []}})
            })
            .catch(err => {
                console.log('Error removeUserFromGroupApi', err.response)
            })
    }

    const prepareDataForExport = () => {
        let dataNew = []

        selectedRows.forEach(item =>{
            let a = {
                "Name": item.full_name,
                "Birthdate": formatDateToReadable(item.birth_date),
                "Email": item.email,
                "Register Session": `${item.goddes_gang_session}x`
            }
            dataNew.push(a)
        })

        return dataNew
    }

    return (
        <div className={"flex flex-row items-center justify-between mt-10"}>
            <div className={"flex flex-row items-center space-x-2"}>
                <PopoverFilterInstagramTiktokComponent/>
                <ExportComponent dataSource={prepareDataForExport()} fileName={"User List Group"} />
                <SendEmailComponent/>
                <button className={`btn height-resp p-2 space-x-1 font-size-resp disabled:bg-gray-300`}
                        disabled={selectedRowKeys.length === 0}
                        onClick={() => dispatch({type: MODAL_REDUCER, payload: {deleteGroupModalVisibility: 'block'}})}
                >
                    <img className={"filter-icon-resp"} src={Trash}/>
                    <p>Remove Selected</p>
                </button>
                <PageSizeComponent/>
            </div>
            <SearchComponent placeholder={'Search'}/>
            <DeleteConfirmationModal
                deleteHandler={removeUserHandler}
                message={"Are you sure want to delete this user from group?"}
            />
        </div>
    )
}

export default GroupDetailFilterComponent

import React, {useState} from "react";
import ArrowDown from "../../images/svg/arrow-down.svg";
import ArrowUp from "../../images/svg/arrow-up.svg";
import BarChartComponent from "../../components/bar-chart.component";
import BarChartHorizontalComponent from "../../components/bar-chart-horizontal.component";
import CircleChartComponent from "../../components/circle-chart.component";
import InfluencerBrandAffinityComponent from "./influencer-brand-affinity.component";
import EngagementComponent from "./engagement.component";
import {useSelector} from "react-redux";
import {formatPercent, getSocialMediaText} from "../../ui-util/general-variable";
import {useLocation} from "react-router-dom";

const DistributionComponent = () => {
    const location = useLocation();
    const pathRaw = location.pathname.split('/')
    const [isHide, setIsHide] = useState(false)
    const {analyticsFacebookResult} = useSelector(state => state.analyticsReducer)

    return (
        <div className={"font-FuturaBT-Book"}>
            <div
                onClick={() => setIsHide(!isHide)}
                className={"border-b flex flex-row items-center justify-between text-milano-red text-sm font-FuturaBT-Medium mt-5 pb-2 hover:cursor-pointer"}>
                <p>Distribution</p>
                <div className={"flex flex-row items-center space-x-2"}>
                    <p className={"text-black"}>Hide</p>
                    <img src={isHide ? ArrowDown : ArrowUp} className={"w-2 h-2"}/>
                </div>
            </div>
            {
                !isHide &&
                <div className={"grid grid-cols-3 gap-5 mt-5"}>
                    {
                        !!analyticsFacebookResult?.age_distribution &&
                        <BarChartComponent
                            title={'Age Distribution'}
                            tooltip={'Age distribution of influencer\'s engaged followers'}
                            data={analyticsFacebookResult?.age_distribution}
                        />
                    }
                    {
                        !!analyticsFacebookResult?.gender_distribution &&
                        <CircleChartComponent
                            title={'Gender Distribution'}
                            tooltip={'Gender distribution of influencer\'s engaged followers'}
                            data={analyticsFacebookResult?.gender_distribution}
                            plotOptions={{
                                pie: {
                                    dataLabels: {
                                        offset: -30,
                                    },
                                }
                            }}
                            labels={['Perempuan', 'Laki-laki']}
                            type={"pie"}
                            distributeData={
                                <div className={"text-sm font-FuturaBT-Book mt-10"}>
                                    <div className={"flex flex-row items-center space-x-2"}>
                                        <div className={"bg-milano-red w-2 h-2"}/>
                                        <p>Perempuan {formatPercent(analyticsFacebookResult?.gender_distribution[0], (analyticsFacebookResult?.gender_distribution[0] + analyticsFacebookResult?.gender_distribution[1]))} User</p>
                                    </div>
                                    <div className={"flex flex-row items-center space-x-2 text-gray-400"}>
                                        <div className={"bg-gray-400 w-2 h-2"}/>
                                        <p>Laki
                                            Laki {formatPercent(analyticsFacebookResult?.gender_distribution[1], (analyticsFacebookResult?.gender_distribution[0] + analyticsFacebookResult?.gender_distribution[1]))} User</p>
                                    </div>
                                </div>
                            }
                        />
                    }
                    <EngagementComponent/>
                    {
                        !!analyticsFacebookResult?.influencer_brand_affinity &&
                        <InfluencerBrandAffinityComponent/>
                    }
                    {
                        !!analyticsFacebookResult?.audience_brand_affinity &&
                        <BarChartComponent
                            title={'Audience Brand Affinity'}
                            tooltip={`Top brands that influencer's followers follow on ${getSocialMediaText(pathRaw[4])}`}
                            data={analyticsFacebookResult?.audience_brand_affinity}
                        />
                    }
                    {
                        !!analyticsFacebookResult?.audience_location_distribution &&
                        <BarChartComponent
                            title={'Audience Location Distribution'}
                            tooltip={'Location distribution of influencer\'s engaged followers'}
                            data={analyticsFacebookResult?.audience_location_distribution}
                        />
                    }
                    {
                        !!analyticsFacebookResult?.branded_content_distribution &&
                        <CircleChartComponent
                            title={'Branded Content Distribution'}
                            tooltip={'Branded content distribution of influencer\'s engaged followers'}
                            data={analyticsFacebookResult?.branded_content_distribution?.distribution}
                            plotOptions={{
                                donut: {
                                    dataLabels: {
                                        offset: -30,
                                    },
                                }
                            }}
                            labels={[]}
                            type={"donut"}
                            distributeData={
                                <div className={"text-sm font-FuturaBT-Book mt-10"}>
                                    <div className={"flex flex-row items-center space-x-2"}>
                                        <div className={"bg-milano-red w-2 h-2"}/>
                                        <p>{analyticsFacebookResult?.branded_content_distribution?.distribution[0]}%
                                            Branded content</p>
                                    </div>
                                    <div className={"flex flex-row items-center space-x-2 text-gray-400"}>
                                        <div className={"bg-gray-400 w-2 h-2"}/>
                                        <p>{analyticsFacebookResult?.branded_content_distribution?.distribution[1]}%
                                            Branded content</p>
                                    </div>
                                </div>
                            }
                        />
                    }
                    {
                        !!analyticsFacebookResult?.audience_interest_distribution &&
                        <BarChartHorizontalComponent/>
                    }
                </div>

            }
        </div>
    )
}

export default DistributionComponent

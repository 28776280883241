const INITIAL_STATE = {
    questionerFormDetail: {},
    reload: false
}

export const QUESTIONER_REDUCER = 'QUESTIONER_REDUCER';

export const questionerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case QUESTIONER_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}

import React from "react";
import UserProfileComponent from "./user-profile.component";
import MissionListComponent from "./mission-list.component";

const UserDetailGoddessGangPage = () => {

    return (
        <div>
            <UserProfileComponent/>
            <MissionListComponent/>
        </div>
    )
}

export default UserDetailGoddessGangPage

import React, {useState} from "react";
import ReportUserItemComponent from "./report-user-item.component";
import {useSelector} from "react-redux";
import Instagram from "../../images/svg/insta-outline.svg";
import Facebook from "../../images/svg/facebook-outline.svg";
import TikTok from "../../images/svg/tiktok-outline.svg";
import ReportDistributionComponent from "./report-distribution.component";
import ExportVerticalComponent from "./export-vertical.component";
import Export from "../../images/svg/exports.svg";

const ReportSocialMediaComponent = () => {
    const [contentKey, setContentKey] = useState('instagram')
    const {contentCampaign} = useSelector(state => state.campaignReducer)
    const [contentList] = useState([
        {
            key: 'instagram',
            label: 'Instagram',
            logo: Instagram
        }, {
            key: 'facebook',
            label: 'Facebook',
            logo: Facebook
        }, {
            key: 'tiktok',
            label: 'TikTok',
            logo: TikTok
        }
    ])
    return (
        <div>
            <div className={"flex flex-row items-center justify-center space-x-10"}>
                {
                    contentList.map((item, index) => (
                        <button key={index}
                                className={`flex flex-row items-center space-x-2-responsive ${item.key === contentKey && 'border-milano-red border-b-2'} pb-3-responsive`}
                                onClick={() => setContentKey(item.key)}
                        >
                            <img src={item.logo} className={"img-6-responsive"}/>
                            <p className={"text-lg-responsive"}>{item.label}</p>
                        </button>
                    ))
                }
            </div>
            {
                !!contentCampaign[contentKey]?.mediaReports &&
                <div className={"flex flex-row justify-end space-x-2 text-xxs-responsive"} id={"section-dont-print"}>
                    <ExportVerticalComponent socialMedia={contentKey}/>
                    <button onClick={() => window.print()} className={"btn p-2 cursor-pointer"}>
                        <img className={"img-4-responsive"} src={Export}/>
                        <p>Export Pdf</p>
                    </button>
                </div>
            }
            {
                !!contentCampaign[contentKey]?.mediaReports &&
                <ReportDistributionComponent socialMediaData={contentCampaign[contentKey]}/>
            }
            <div className={"py-10 flex flex-col space-y-5"}>
                {
                    !!contentCampaign[contentKey]?.mediaReports &&
                    [contentCampaign[contentKey]?.mediaReports[0]].map((item, index) => <ReportUserItemComponent item={item}
                                                                                                            key={index}/>)
                }
            </div>
            {!contentCampaign[contentKey]?.mediaReports && <p className={"text-center text-gray-400"}>No Data</p>}
        </div>
    )
}

export default ReportSocialMediaComponent

import React, {useState} from "react";
import {useSelector} from "react-redux";
import PaginationComponent from "../../components/pagination.component";
import ArrowDown from "../../images/svg/arrow-down.svg";
import ArrowUp from "../../images/svg/arrow-up.svg";
import PotentialInfluencer from "../../images/svg/potential-influencer.svg";
import {doConvertNumberToRupiahFormat, formatDateToReadable} from "../../ui-util/general-variable";

const UserRedeemListComponent = () => {
    const [isHide, setIsHide] = useState(false)
    const {redeemList, userDetail} = useSelector(state => state.redeemReducer)

    return (
        <div className={"font-FuturaBT-Medium"}>
            <div className={"flex flex-row items-center justify-between text-base pb-3 border-b"}>
                <p className={"text-milano-red font-bold"}>Detail Redeem</p>
                <div className={"flex flex-row items-center space-x-2 cursor-pointer"} onClick={() => setIsHide(!isHide)}>
                    <p className={"text-black"}>Hide</p>
                    <img src={isHide ? ArrowDown : ArrowUp} className={"w-3 h-3"}/>
                </div>
            </div>
            <div className={`mt-10 ${isHide && 'hidden'}`}>
                <div className={"flex flex-col space-y-3"}>
                {
                    redeemList.map((item, index) => (
                        <div key={index} className={"border p-3 grid grid-cols-9"}>
                            <div className={"flex flex-row items-center col-span-2"}>
                                <img className={"mr-2 w-14 h-14 rounded-full"} src={item?.user.photo}/>
                                <div className={"font-FuturaBT-Book"}>
                                    <div className={"flex flex-row items-center"}>
                                        <p className={"font-large-resp font-FuturaBT-Medium font-bold text-xl"}>{item?.user.full_name}</p>
                                        {userDetail?.is_potential && <img className={"h-5 w-5 ml-1"} src={PotentialInfluencer}/>}
                                    </div>
                                    <p className={"text-sm"}>{formatDateToReadable(item?.user.birth_date)}</p>
                                    <p className={"text-tiny"}>{item?.user.email}</p>
                                </div>
                            </div>
                            <div className={"flex flex-col items-center space-y-3"}>
                                <p className={"font-bold text-base"}>Tanggal</p>
                                <p className={"text-gray-400"}>{formatDateToReadable(item.created_at)}</p>
                            </div>
                            <div className={"flex flex-col items-center space-y-3"}>
                                <p className={"font-bold text-base"}>Point Redeem</p>
                                <p className={"text-milano-red font-bold text-xl"}>{item.point_withdraw} Point</p>
                            </div>
                            <div className={"flex flex-col items-center space-y-3 justify-center"}>
                                <div className={"bg-milano-red w-8 h-8 flex flex-col items-center justify-center text-white text-3xl rounded-md"}>
                                    =
                                </div>
                            </div>
                            <div className={"flex flex-col items-center space-y-3"}>
                                <p className={"font-bold text-base"}>Konversi Rupiah</p>
                                <p className={"font-bold text-xl"}>{doConvertNumberToRupiahFormat(item.total_withdraw)}</p>
                            </div>
                            <div className={"flex flex-col items-center space-y-3"}>
                                <p className={"font-bold text-base"}>Biaya Admin</p>
                                <p className={"font-bold text-xl text-milano-red"}>{doConvertNumberToRupiahFormat(item.admin_fee)}</p>
                            </div>
                            <div className={"flex flex-col items-center space-y-3"}>
                                <p className={"font-bold text-base"}>Total Dana</p>
                                <p className={"font-bold text-xl"}>{doConvertNumberToRupiahFormat(item.grand_total)}</p>
                            </div>
                            <div className={"flex flex-col items-center space-y-3"}>
                                <p className={"font-bold text-base"}>Sisa Point</p>
                                <p className={"font-bold text-xl text-gray-400"}>{item.point_left} Point</p>
                            </div>
                        </div>
                    ))
                }
                </div>
                <PaginationComponent/>
            </div>
        </div>
    )
}

export default UserRedeemListComponent

import React, {useEffect, useState} from "react";
import CalendarRed from "../../images/svg/ico-calendar-red.svg";
import NoImage from "../../images/no-image.jpg";
import Edit from "../../images/svg/ico-edit.svg";
import Folder from "../../images/svg/ico-folder.svg";
import 'react-quill/dist/quill.snow.css';
import SelectMultipleComponent from "../../components/select-multiple.component";
import {getAllUserTheGoddessGangApi} from "../../api/user.api";
import {useDispatch, useSelector} from "react-redux";
import {TOOLS_REDUCER} from "../../reducers/tools.reducer";
import {CREATE_CAMPAIGN_REDUCER} from "../../reducers/create-campaign.reducer";
import moment from "moment";
import ErrorMessageComponent from "../../components/error-message.component";
import ReactQuill from "react-quill";

const CampaignDetailComponent = () => {
    const dispatch = useDispatch()
    const {selectDataEmail, selectDataGroup} = useSelector(state => state.toolsReducer)
    const {
        description,
        thumbnail,
        createCampaignErrorMessage,
        campaignDetailEdit
    } = useSelector(state => state.createCampaignReducer)
    const [campaignSetting, setCampaignSetting] = useState(4)
    const [imagePreview, setImagePreview] = useState()
    const today = moment().format('YYYY-MM-DD')
    const [registrationStartDate, setRegistrationStartDate] = useState(today)
    const [campaignStartDate, setCampaignStartDate] = useState(today)

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
        ],
    }

    const [radioData] = useState([
        {
            value: 1,
            label: 'All with Approvement'
        }, {
            value: 2,
            label: 'All without Approvement'
        }, {
            value: 3,
            label: 'Unique by Email'
        }, {
            value: 4,
            label: 'Added by Group'
        }
    ])

    useEffect(() => {
        if (!!campaignDetailEdit['campaign_setting']) {
            radioButtonHandler(campaignDetailEdit['campaign_setting'])
        }
    }, [campaignDetailEdit])

    const onChangeRtf = (value) => {
        dispatch({type: CREATE_CAMPAIGN_REDUCER, payload: {description: value}})
    }

    const getUserList = (value) => {
        getAllUserTheGoddessGangApi()
            .then(result => {
                const data = result.data.map(d => ({
                    user_id: d.id,
                    full_name: `${d.full_name} (${d.email})`
                }))
                dispatch({type: TOOLS_REDUCER, payload: {selectDataEmail: data}})
                setCampaignSetting(value)
            })
            .catch(err => {
                console.log("error :", err)
                alert("something went wrong !")
            })
    }

    const radioButtonHandler = (value) => {
        if ((value === 3) && (selectDataEmail.length === 0)) {
            getUserList(value)
        } else {
            setCampaignSetting(value)
        }
    }

    const imageProcessing = (event) => {
        const thumbnail = event.target.files[0]
        if (thumbnail.type.includes('image')) {
            let reader = new FileReader();
            reader.onloadend = () => {
                dispatch({
                    type: CREATE_CAMPAIGN_REDUCER, payload: {
                        thumbnail: thumbnail
                    }
                })
                let image = reader.result
                setImagePreview(image)
            }
            reader.readAsDataURL(thumbnail)
        } else {
            alert('Mohon masukkan file image')
        }
    }

    return (
        <div className={"grid grid-cols-2"} id={"form"}>
            <div className={"flex flex-col space-y-10 font-FuturaBT-Book pr-10"}>
                <input
                    name={"title"}
                    defaultValue={campaignDetailEdit['title']}
                    className={"w-1/2 border-b-2 border-b-milano-red h-32 text-3xl font-FuturaBT-Medium outline-0 text-gray-400"}
                    placeholder={'Add title Campaign'}/>
                {!!createCampaignErrorMessage['title'] &&
                    <ErrorMessageComponent message={createCampaignErrorMessage['title']}/>}
                <div className={"mt-8 h-72"}>
                    <p className={"text-tiny font-FuturaBT-Medium mb-2"}>Description Campaign</p>
                    <div className={"h-64"}>
                        <ReactQuill
                            theme="snow"
                            modules={modules}
                            value={description}
                            className={"h-52"}
                            onChange={onChangeRtf}/>
                    </div>
                    {!!createCampaignErrorMessage['description'] &&
                        <ErrorMessageComponent message={createCampaignErrorMessage['description']}/>}
                </div>
                <div>
                    <p className={"text-tiny font-FuturaBT-Medium"}>Campaign Point</p>
                    <input type={"number"} className={"border outline-0 text-sm h-7 mt-2 pl-2"}
                           placeholder={"500 Points"} name={"point"} defaultValue={campaignDetailEdit['point']}/>
                    {!!createCampaignErrorMessage['point'] &&
                        <ErrorMessageComponent message={createCampaignErrorMessage['point']}/>}
                </div>
                <div>
                    <p className={"text-2xl text-gray-400 font-FuturaBT-Medium font-bold"}>Registration Form
                        Submission</p>
                    <div className={"flex flex-row justify-between items-center mt-5"}>
                        <div>
                            <p className={"text-tiny font-FuturaBT-Medium"}>Registration Date</p>
                            <div className={"flex flex-row mt-3"}>
                                <img className={"2xl:w-8 2xl:h-8 xl:w-6 xl:h-6 -ml-2 -mt-1"} src={CalendarRed}
                                     alt={'calendar'}/>
                                <div className={"flex flex-col space-y-1"}>
                                    <input type={'date'} className={"text-sm border px-2"}
                                           name={"registration_start_date"} min={today}
                                           defaultValue={campaignDetailEdit['registration_start_date']}
                                           onChange={(e) => {
                                               let date = moment(e.target.value).add(1, 'days').format("YYYY-MM-DD")
                                               setRegistrationStartDate(date)
                                           }}/>
                                    {!!createCampaignErrorMessage['registration_start_date'] && <ErrorMessageComponent
                                        message={createCampaignErrorMessage['registration_start_date']}/>}
                                    <p className={"text-xs ml-2"}>until</p>
                                    <input type={'date'} className={"text-sm border px-2"}
                                           defaultValue={campaignDetailEdit['registration_end_date']}
                                           name={"registration_end_date"} min={registrationStartDate}/>
                                    {!!createCampaignErrorMessage['registration_end_date'] && <ErrorMessageComponent
                                        message={createCampaignErrorMessage['registration_end_date']}/>}
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className={"text-tiny font-FuturaBT-Medium"}>Registration Time</p>
                            <div className={"flex flex-row mt-3"}>
                                <img className={"2xl:w-8 2xl:h-8 xl:w-6 xl:h-6 -ml-2 -mt-1"} src={CalendarRed}
                                     alt={'calendar'}/>
                                <div className={"flex flex-col space-y-1"}>
                                    <input type={'time'} className={"text-sm border px-2"}
                                           defaultValue={campaignDetailEdit['registration_start_time']}
                                           name={"registration_start_time"}/>
                                    {!!createCampaignErrorMessage['registration_start_time'] && <ErrorMessageComponent
                                        message={createCampaignErrorMessage['registration_start_time']}/>}
                                    <p className={"text-xs ml-2"}>until</p>
                                    <input type={'time'} className={"text-sm border px-2"}
                                           defaultValue={campaignDetailEdit['registration_end_time']}
                                           name={"registration_end_time"}/>
                                    {!!createCampaignErrorMessage['registration_start_time'] && <ErrorMessageComponent
                                        message={createCampaignErrorMessage['registration_start_time']}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p className={"text-2xl text-gray-400 font-FuturaBT-Medium font-bold mt-10"}>Campaign
                        Duration</p>
                    <div className={"flex flex-row justify-between items-center mt-5"}>
                        <div>
                            <p className={"text-tiny font-FuturaBT-Medium"}>Campaign Date</p>
                            <div className={"flex flex-row mt-3"}>
                                <img className={"2xl:w-8 2xl:h-8 xl:w-6 xl:h-6 -ml-2 -mt-1"} src={CalendarRed}
                                     alt={'calendar'}/>
                                <div className={"flex flex-col space-y-1"}>
                                    <input type={'date'} className={"text-sm border px-2"} min={today}
                                           defaultValue={campaignDetailEdit['campaign_start_date']}
                                           name={"campaign_start_date"} onChange={(e) => {
                                        let date = moment(e.target.value).add(1, 'days').format("YYYY-MM-DD")
                                        setCampaignStartDate(date)
                                    }}/>
                                    {!!createCampaignErrorMessage['campaign_start_date'] && <ErrorMessageComponent
                                        message={createCampaignErrorMessage['campaign_start_date']}/>}
                                    <p className={"text-xs ml-2"}>until</p>
                                    <input type={'date'} className={"text-sm border px-2"} name={"campaign_end_date"}
                                           defaultValue={campaignDetailEdit['campaign_end_date']}
                                           min={campaignStartDate}/>
                                    {!!createCampaignErrorMessage['campaign_end_date'] && <ErrorMessageComponent
                                        message={createCampaignErrorMessage['campaign_end_date']}/>}
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className={"text-tiny font-FuturaBT-Medium"}>Campaign Time</p>
                            <div className={"flex flex-row mt-3"}>
                                <img className={"2xl:w-8 2xl:h-8 xl:w-6 xl:h-6 -ml-2 -mt-1"} src={CalendarRed}
                                     alt={'calendar'}/>
                                <div className={"flex flex-col space-y-1"}>
                                    <input type={'time'} className={"text-sm border px-2"}
                                           defaultValue={campaignDetailEdit['campaign_start_time']}
                                           name={"campaign_start_time"}/>
                                    {!!createCampaignErrorMessage['campaign_start_time'] && <ErrorMessageComponent
                                        message={createCampaignErrorMessage['campaign_start_time']}/>}
                                    <p className={"text-xs ml-2"}>until</p>
                                    <input type={'time'} className={"text-sm border px-2"} name={"campaign_end_time"}
                                           defaultValue={campaignDetailEdit['campaign_end_time']}
                                    />
                                    {!!createCampaignErrorMessage['campaign_end_time'] && <ErrorMessageComponent
                                        message={createCampaignErrorMessage['campaign_end_time']}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"mt-48"}>
                <div className={"relative w-2/3"}>
                    <img
                        src={!!imagePreview ? imagePreview : !!campaignDetailEdit['thumbnail'] ? campaignDetailEdit['thumbnail'] : NoImage}
                        className={"w-full h-72 object-cover"}/>
                    <label
                        className={"border border-milano-red absolute top-0 right-0 p-1 rounded-full m-2 bg-linen cursor-pointer"}>
                        <img src={Edit} className={" w-4 h-4 "}/>
                        <input type={"file"} className={"hidden absolute top-0 right-0"} name={"thumbnail"}
                               onChange={(e) => imageProcessing(e)}/>
                    </label>
                </div>
                <div className={"mt-5"}>
                    <p className={"text-tiny font-FuturaBT-Medium"}>Gambar Thumbnail</p>
                    <div className={"flex flex-row items-center space-x-2"}>
                        <img src={Folder} className={" w-6 h-6 "}/>
                        <p className={"text-xs text-gray-400"}>{!!thumbnail.name ? thumbnail.name : !!campaignDetailEdit['thumbnail'] ? campaignDetailEdit['thumbnail'] : 'No Image'}</p>
                    </div>
                    {!!createCampaignErrorMessage['thumbnail'] &&
                        <ErrorMessageComponent message={createCampaignErrorMessage['thumbnail']}/>}
                </div>
                <div>
                    <p className={"text-2xl text-gray-400 font-FuturaBT-Medium font-bold mt-10"}>Campaign
                        Setting</p>
                    <div className={"space-y-2 mt-3"}>
                        {
                            radioData.map((item, index) => (
                                <div className={"flex flex-row items-center space-x-2"} key={index.toString()}>
                                    <input type={"radio"} name="campaign_setting" value={item.value}
                                           checked={item.value === campaignSetting}
                                           onChange={() => radioButtonHandler(item.value)}
                                           className={"accent-milano-red"}/>
                                    <p className={"text-sm font-FuturaBT-Book"}>{item.label}</p>
                                </div>
                            ))
                        }
                        <div className={"mt-5 ml-5"}>
                            {
                                ((campaignSetting === 4) && (selectDataGroup.length > 0)) &&
                                <SelectMultipleComponent placeholder={'pilih group'} data={selectDataGroup}/>
                            }
                            {
                                ((campaignSetting === 3) && (selectDataEmail.length > 0)) &&
                                <SelectMultipleComponent placeholder={'pilih email'} data={selectDataEmail}/>
                            }
                            {!!createCampaignErrorMessage['campaign_setting'] &&
                                <ErrorMessageComponent message={createCampaignErrorMessage['campaign_setting']}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignDetailComponent

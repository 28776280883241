import React, {useEffect} from "react";
import GoddessGangUserListComponent from "../goddess-gang/goddess-gang-user-list.component";
import GroupDetailComponent from "./group-detail.component";
import {getUserListFromGroupApi} from "../../api/group.api";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import GroupDetailFilterComponent from "./group-detail-filter.component";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";
import {FLUSH_SORTING, TOOLS_REDUCER} from "../../reducers/tools.reducer";

const GroupDetailPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const {pageSize, currentPage, searchKey, sort, orderBy} = useSelector(state => state.toolsReducer)
    const {reload} = useSelector(state => state.groupReducer)
    const {
        ig_followers_min,
        ig_followers_max,
        ig_er_min,
        ig_er_max,
        ig_reach_min,
        ig_reach_max,
        tiktok_followers_min,
        tiktok_followers_max,
        tiktok_er_min,
        tiktok_er_max,
        tiktok_reach_min,
        tiktok_reach_max,
    } = useSelector(state => state.userRegisteredReducer)

    useEffect(() => {
        return () => {
            dispatch({type: FLUSH_SORTING})
        }
    }, [])

    useEffect(() => {
        let requestBody = {
            group_id: id,
            keyword: searchKey,
            status: '',
            min_correct: 0,
            max_correct: 0,
            ig_followers_min,
            ig_followers_max,
            ig_er_min,
            ig_er_max,
            ig_reach_min,
            ig_reach_max,
            tiktok_followers_min,
            tiktok_followers_max,
            tiktok_er_min,
            tiktok_er_max,
            tiktok_reach_min,
            tiktok_reach_max,
            start_date: "",
            end_date: "",
            social_media_id: null,
            interest_id: null,
            city_id: null
        }

        let params = {
            page: currentPage,
            limit: pageSize,
            orderby: orderBy,
            sort
        }

        getUserListFromGroupApi(requestBody, params)
            .then(response => {
                dispatch({type: USER_REGISTERED_REDUCER, payload: {userList: response.rows}})
                dispatch({type: TOOLS_REDUCER, payload: {totalData: response.total_data}})
            })
            .catch(err => {
                console.log('Error getGroupDetailApi', err.response)
            })
    }, [
        ig_followers_min,
        ig_followers_max,
        ig_er_min,
        ig_er_max,
        ig_reach_min,
        ig_reach_max,
        tiktok_followers_min,
        tiktok_followers_max,
        tiktok_er_min,
        tiktok_er_max,
        tiktok_reach_min,
        tiktok_reach_max,
        searchKey,
        sort,
        currentPage,
        pageSize,
        reload
    ])

    return (
        <>
            <GroupDetailComponent/>
            <GroupDetailFilterComponent/>
            <GoddessGangUserListComponent/>
        </>
    )
}

export default GroupDetailPage

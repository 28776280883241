import React from "react";
import SearchComponent from "../../components/search.component";
import SortComponent from "../../components/sort.component";
import PopoverFilterInstagramTiktokComponent from "../../components/popover-filter-instagram-tiktok.component";
import DatePickerComponent from "../../components/date-picker.component";
import PageSizeComponent from "../../components/page-size.component";
import ExportComponent from "../../components/export.component";
import {useSelector} from "react-redux";
import {formatFollower} from "../../ui-util/general-variable";
import FilterStatusComponent from "../../components/filter-status.component";

const FilterComponent = () => {
    const {influencerList} = useSelector(state => state.campaignReducer)

    const prepareDataForExport = () => {
        let dataNew = []
        influencerList.forEach(item =>{
            let a = {
                "Influencer": item.full_name,
                "Followers": formatFollower(!!item?.user_analityc ? item?.user_analityc?.follower : 0),
                "Status": item?.campaign_users[0]?.status,
                "Contact": item.email,
                "Cost": item.cost,
                "EMV": item.emv,
                "Rating": item.rating
            }
            dataNew.push(a)
        })

        return dataNew
    }

  return (
      <div className={"flex flex-row items-center justify-between mt-5"} id={"section-dont-print"}>
        <div className={"flex flex-row items-center space-x-2"}>
            <FilterStatusComponent />
            <SortComponent />
            <DatePickerComponent />
            <ExportComponent dataSource={prepareDataForExport()} fileName={'Campaign Detail - Influencers'} />
            <PageSizeComponent />
        </div>
        <SearchComponent placeholder={'Search'}/>
      </div>
  )
}

export default FilterComponent

import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {TOOLS_REDUCER} from "../../reducers/tools.reducer";
import {APPROVAL_CAMPAIGN_REDUCER} from "../../reducers/approval-campaign.reducer";

const CampaignTabComponent = () => {
    const dispatch = useDispatch()
    const {activeTab} = useSelector(state => state.approvalCampaignReducer)
    const [tabList] = useState([{
        label: 'List User',
        key: 'Waiting Approval'
    }, {
        label: 'Approve',
        key: 'Approve'
    }, {
        label: 'Kirim Product',
        key: 'Send Product'
    }, {
        label: 'Draft Konten',
        key: 'Draft Konten'
    }, {
        label: 'Publish Konten',
        key: 'Publish Konten'
    }])

    const changeTabHandler = (key) => {
        dispatch({type: TOOLS_REDUCER, payload: {currentPage: 1, pageSize: 5}})
        dispatch({type: APPROVAL_CAMPAIGN_REDUCER, payload: {activeTab: key}})
    }

    return (
        <div className={"flex flex-row items-center font-FuturaBT-Book"}>
            {
                tabList.map((item, index) => (
                    <button className={"border px-8 py-4"} onClick={() => changeTabHandler(item.key)} key={index}>
                        <p className={`text-2xl font-semibold ${activeTab === item.key ? 'text-milano-red' : 'text-gray-400'}`}>{item.label}</p>
                    </button>
                ))
            }
        </div>
    )
}

export default CampaignTabComponent

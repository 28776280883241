import React, {useEffect} from "react";
import UserDetailRedeemComponent from "./user-detail-redeem.component";
import {useParams} from "react-router-dom";
import {getRedeemListApi, getRedeemPointDetailApi} from "../../api/redeem.api";
import {useDispatch, useSelector} from "react-redux";
import {REDEEM_REDUCER} from "../../reducers/redeem.reducer";
import {TOOLS_REDUCER} from "../../reducers/tools.reducer";
import UserRedeemListComponent from "./user-redeem-list.component";

const RedeemDetailPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const {currentPage} = useSelector(state => state.toolsReducer)
    const {userDetail, redeemList} = useSelector(state => state.redeemReducer)

    useEffect(() =>{
        getRedeemPointDetailApi(id)
            .then(response => {
                dispatch({type: REDEEM_REDUCER, payload: {userDetail: response}})
            })
            .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        let requestBody = {
            status: '',
            start_date: '',
            end_date: '',
            keyword: '',
            user_id: id
        }

        let params = {
            page: currentPage,
            limit: 5,
            orderby: 'created_at',
            sort: 'desc'
        }

        getRedeemListApi(requestBody, params)
            .then(response => {
                dispatch({type: REDEEM_REDUCER, payload: {redeemList: response.rows}})
                dispatch({type: TOOLS_REDUCER, payload: {totalData: response.total_data}})
            })
            .catch(err => {
                console.log(err)
            })
    }, [currentPage])

  return (
      <div className={"flex flex-col space-y-10"}>
          {userDetail.photo && <UserDetailRedeemComponent/>}
          {redeemList.length > 0 && <UserRedeemListComponent />}
      </div>
  )
}

export default RedeemDetailPage

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CREATE_CAMPAIGN_REDUCER} from "../reducers/create-campaign.reducer";
import {filter, xorBy} from "lodash";

const SelectMultipleComponent = (props) => {
    const dispatch = useDispatch()
    const {placeholder, data} = props
    const [showSelectOption, setShowSelectOption] = useState(false)
    const [options, setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState([])
    const [search, setSearch] = useState('')
    const {campaignDetailEdit} = useSelector(state => state.createCampaignReducer)

    useEffect(() => {
        setOptions(data)

        if (!!campaignDetailEdit.title) {
            feedSelectionDataToEdit()
        }

    }, [campaignDetailEdit])

    const feedSelectionDataToEdit = () => {
        let selectedOptionEdit = []
        let dataOption = []

        if ((placeholder === 'pilih group') && (campaignDetailEdit['campaign_setting'] === 4)) {
            filter(campaignDetailEdit['campaign_invitations'], item => {
                let group = item.group
                group.full_name = group.name
                selectedOptionEdit.push(group)
            })
            dataOption = xorBy(selectedOptionEdit, data, 'id')
            setOptions(dataOption)
        } else if (placeholder === 'pilih email' && (campaignDetailEdit['campaign_setting'] === 3)) {
            filter(campaignDetailEdit['campaign_invitations'], item => {
                let user = item.user
                user.full_name = `${item.user.full_name} (${item.user.email})`
                user.user_id = user.id
                selectedOptionEdit.push(user)
            })

            const invitationEmail = campaignDetailEdit['campaign_invitations'].map(d => ({
                user_id: d.user_id,
                full_name: d.user.full_name
            }))
            dataOption = xorBy(invitationEmail, data, 'user_id')
            setOptions(dataOption)
        } else if (placeholder === 'pilih product' && (campaignDetailEdit['flag_send_product'] === 1)) {
            filter(campaignDetailEdit.campaign_products, item => {
                selectedOptionEdit.push(item.product)
            })
            dataOption = xorBy(selectedOptionEdit, data, 'id')
            setOptions(dataOption)
        }
        setSelectedOption(selectedOptionEdit)
    }

    const onSelectHandler = (item) => {
        let dataSelect = selectedOption
        dataSelect.push(item)
        saveDataToReducer(dataSelect)

        let dataOption = []

        if (placeholder === 'pilih email') {
            dataOption = options.filter((i) => i.user_id !== item.user_id)
        } else {
            dataOption = options.filter((i) => i.id !== item.id)
        }

        setOptions(dataOption)
        setSearch('')
        setShowSelectOption(false)
    }

    const removeFromSelectedValue = (item) => {
        let dataSelect = selectedOption.filter((i) => i.id !== item.id)
        saveDataToReducer(dataSelect)

        let dataOption = options
        dataOption.push(item)
        setOptions(dataOption)
    }

    const saveDataToReducer = (values) => {
        switch (placeholder) {
            case 'pilih product':
                dispatch({type: CREATE_CAMPAIGN_REDUCER, payload: {products: values}})
                break;
            case 'pilih group':
                dispatch({type: CREATE_CAMPAIGN_REDUCER, payload: {invitation_group: values}})
                break;
            case 'pilih email':
                dispatch({type: CREATE_CAMPAIGN_REDUCER, payload: {invitation_user: values}})
                break;
        }
        setSelectedOption(values)
    }

    const filterOptions = () => {
        const filteredList = options.filter((item) => item.full_name.toLowerCase().includes(search.toLowerCase()))

        return filteredList
    }

    return (
        <div className={"border p-5 mt-3 font-FuturaBT-Book"}>
            <div className={"border relative w-full flex flex-row px-2 items-center justify-between"}>
                <div className={"grid grid-flow-row-dense grid-cols-3 gap-1 py-2"}>
                    {
                        selectedOption.length > 0 &&
                        selectedOption.map((item, index) => (
                            <div key={index}
                                 className={"flex flex-row border justify-between items-center border-milano-red rounded-2xl text-sm text-milano-red px-1 bg-snow h-7"}>
                                <p title={item.full_name}
                                   className={"truncate overflow-ellipsis w-40"}>{item.full_name}</p>
                                <div
                                    onClick={() => removeFromSelectedValue(item)}
                                    className={"flex justify-center items-center border border-milano-red rounded-full w-3 h-3 "}>
                                    <p>x</p>
                                </div>
                            </div>
                        ))
                    }
                    <input className={"px-3 text-sm outline-0 "}
                           placeholder={placeholder}
                           value={search}
                           onChange={(e) => setSearch(e.target.value)}
                           onClick={() => setShowSelectOption(!showSelectOption)}/>
                </div>
                {selectedOption.length > 0 &&
                    <button className={"font-FuturaBT-Medium"}
                            onClick={() => setSelectedOption([])}>x</button>
                }
            </div>
            <ul className={`${showSelectOption ? 'visible' : 'invisible'} absolute font-FuturaBT-Book border flex-col p-2 mt-2 bg-white overflow-scroll max-h-52 w-565  z-10`}>
                {
                    filterOptions().map((item, index) => (
                        <li className={`p-1 hover:bg-gray-200 text-sm`} onClick={() => onSelectHandler(item)}
                            key={index.toString()}>{item.full_name}</li>
                    ))
                }
                {
                    filterOptions().length === 0 &&
                    <li className={"p-1 hover:bg-gray-200 text-sm"}>No data</li>
                }
            </ul>
        </div>
    )
}

export default SelectMultipleComponent

import React, {useEffect, useState} from "react";
import NoImage from "../../images/no-image.jpg";
import ArrowDown from "../../images/svg/arrow-down.svg";
import ArrowUp from "../../images/svg/arrow-up.svg";
import ArrowRight from "../../images/svg/arrow-right-black.svg";
import FacebookSolid from "../../images/svg/fb-solid.svg";
import InstagramSolid from "../../images/svg/instagram-solid.svg";
import YoutubeSolid from "../../images/svg/youtube-solid.svg";
import TikTokSolid from "../../images/svg/tiktok-solid.svg";
import {useLocation, useParams} from "react-router-dom";
import {getAnalyticsSocialMediaApi} from "../../api/user-registered.api";
import {useDispatch, useSelector} from "react-redux";
import {ANALYTICS_REDUCER} from "../../reducers/analytics.reducer";
import {formatFollower, getSocialMediaText} from "../../ui-util/general-variable";

const ProfileComponent = () => {
    const dispatch = useDispatch()
    const [isHide, setIsHide] = useState(false)
    const location = useLocation();
    const pathRaw = location.pathname.split('/')
    const {analyticsFacebookResult, limit_similliar_influencer} = useSelector(state => state.analyticsReducer)

    if (!!analyticsFacebookResult?.username) {
        return (
            <div className={"font-FuturaBT-Book"}>
                <div
                    onClick={() => setIsHide(!isHide)}
                    className={"border-b flex flex-row items-center justify-between text-milano-red text-sm font-FuturaBT-Medium mt-5 pb-2 hover:cursor-pointer"}>
                    <p>{getSocialMediaText(pathRaw[4])} Profile</p>
                    <div className={"flex flex-row items-center space-x-2"}>
                        <p className={"text-black"}>Hide</p>
                        <img src={isHide ? ArrowDown : ArrowUp} className={"w-2 h-2"}/>
                    </div>
                </div>
                {
                    !isHide &&
                    <div className={"grid grid-cols-5 mt-5 gap-5"}>
                        <div className={"col-span-2"}>
                            <div className={"border p-5"}>
                                <div
                                    className={" flex flex-row items-center justify-between font-bold border-b pb-4 border-gray-100"}>
                                    <div className={"basis-1/3"}>
                                        <img src={analyticsFacebookResult?.picture} className={"rounded-full w-16"}/>
                                    </div>
                                    <div className={"flex flex-col space-y-1 basis-1/3"}>
                                        <h1 className={"text-xl font-FuturaBT-Medium"}>{analyticsFacebookResult?.username}</h1>
                                        <p className={"text-sm"}>Followers</p>
                                        <p className={"text-milano-red text-tiny"}>{analyticsFacebookResult?.follower && formatFollower(analyticsFacebookResult?.follower)}</p>
                                        <p className={"text-sm"}>Email</p>
                                        <p className={"text-milano-red text-tiny"}>{analyticsFacebookResult?.email}</p>
                                    </div>
                                    <div className={"basis-1/3"}>
                                        <p className={"text-sm"}>Location</p>
                                        <p className={"text-milano-red text-tiny"}>{analyticsFacebookResult?.location}</p>
                                    </div>
                                </div>
                                <div className={"py-4 border-b border-gray-100"}>
                                    <p className={"text-xs"}>{analyticsFacebookResult?.biography}</p>
                                </div>
                                <div className={"py-4 border-b border-gray-100 flex flex-row space-x-2"}>
                                    {
                                        !!analyticsFacebookResult?.interest &&
                                        analyticsFacebookResult?.interest.map((item, index) => (
                                            <div
                                                className={"text-xs bg-linen text-milano-red w-1/4 py-2 font-bold text-center"}
                                                key={index}>{item}</div>
                                        ))
                                    }
                                </div>
                                <div className={"py-4 border-b border-gray-100 flex flex-row justify-around"}>
                                    {
                                        !!analyticsFacebookResult?.social_medias &&
                                        analyticsFacebookResult?.social_medias.map((item, index) => {
                                            switch (item.name) {
                                                case 'TikTok':
                                                    return (
                                                        <a href={item.url_link} target={'_blank'} key={index}>
                                                            <img src={TikTokSolid} className={"w-7 h-7"}/>
                                                        </a>
                                                    )
                                                    break;
                                                case 'Facebook':
                                                    return (
                                                        <a href={item.url_link} target={'_blank'} key={index}>
                                                            <img src={FacebookSolid} className={"w-7 h-7"}/>
                                                        </a>
                                                    )
                                                    break;
                                                case 'Youtube':
                                                    return (
                                                        <a href={item.url_link} target={'_blank'} key={index}>
                                                            <img src={YoutubeSolid} className={"w-7 h-7"}/>
                                                        </a>
                                                    )
                                                    break;
                                                case 'Instagram':
                                                    return (
                                                        <a href={item.url_link} target={'_blank'} key={index}>
                                                            <img src={InstagramSolid} className={"w-7 h-7"}/>
                                                        </a>
                                                    )
                                                    break;
                                            }
                                        })
                                    }
                                </div>
                            </div>
                            {
                                !!analyticsFacebookResult?.simillarInfluencers &&
                                <div className={"border p-5 mt-5"}>
                                    <p className={"text-sm font-bold"}>Similar Influencer</p>
                                    <div className={"flex flex-row space-x-10 mt-5 overflow-scroll"}>
                                        {
                                            analyticsFacebookResult?.simillarInfluencers.map((item, index) => (
                                                <div className={"flex flex-col space-y-2 items-center w-20"}
                                                     key={index}>
                                                    <img src={!!item.picture ? item.picture : NoImage}
                                                         className={"w-20 h-20 rounded-full"}/>
                                                    <p className={"text-sm font-semibold text-center w-20"}>{!!item.fullName ? item.fullName : item.name}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {
                                        analyticsFacebookResult?.simillarInfluencers.length === limit_similliar_influencer &&
                                        <div className={"flex flex-row-reverse mt-3"}>
                                            <button
                                                className={"text-sm font-semibold flex flex-row items-center space-x-1"}
                                                onClick={() => dispatch({
                                                    type: ANALYTICS_REDUCER,
                                                    payload: {limit_similliar_influencer: ""}
                                                })}
                                            >
                                                <p>View all</p>
                                                <img src={ArrowRight} className={"w-3 h-3"}/>
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        {
                            !!analyticsFacebookResult?.recent_upload_media &&
                            <div className={"col-span-3 border p-5 overflow-scroll h-500"}>
                                <div className={"flex flex-row items-center justify-between mb-3"}>
                                    <h1 className={"font-bold font-FuturaBT-Medium"}>Recent Upload Media</h1>
                                    <div className={"flex flex-col group relative"}>
                                        <button
                                            className="text-sm bg-gray-200 w-5 h-5 rounded-full text-gray-400 hover:text-black inline-block">?
                                        </button>
                                        <p className="invisible bg-gray-200 absolute z-100 text-xxs group-hover:visible border border-gray-300 p-3 rounded top-6 w-40 right-0">
                                            Recent uploaded media of influencer's engaged followers
                                        </p>
                                    </div>
                                </div>
                                <div className={"grid grid-cols-3 gap-5 p-5"}>
                                    {
                                        analyticsFacebookResult?.recent_upload_media.map((item, index) => (
                                            <div key={index}>
                                                {
                                                    !!item?.media?.urlThumbnail ?
                                                        <iframe
                                                            src={item.media.urlThumbnail.replace(`media/?size=t`, 'embed')}
                                                            width="200" height="500" frameBorder="0"/> :
                                                        <img src={item} className={"w-52"}/>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    } else {
        return (
            <div className={"pt-20 flex flex-row w-full justify-center font-FuturaBT-Medium"}>
                <p>No data user analytics, sorry</p>
            </div>
        )
    }
}

export default ProfileComponent

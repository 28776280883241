import React from "react";
import Export from "../../images/svg/exports.svg";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {useLocation} from "react-router-dom";
import {formatPercent, getSocialMediaText, totalCalculation} from "../../ui-util/general-variable";
import {useSelector} from "react-redux";

const ExportSocialMediaComponent = () => {
    const location = useLocation();
    const pathRaw = location.pathname.split('/')
    const {analyticsFacebookResult} = useSelector(state => state.analyticsReducer)

    const influencerBasicInformation = () => {
        const campaign = [{
            title: 'Username',
            key: 'username'
        }, {
            title: 'Follower Count',
            key: 'follower'
        }, {
            title: 'Location',
            key: 'location'
        }, {
            title: 'Email',
            key: 'email'
        }]
        return (
            <>
                <thead>
                <tr>
                    <th>Influencer Information</th>
                    <th>Value</th>
                </tr>
                </thead>

                <tbody>
                {
                    campaign.map((c, i) => (
                        <tr key={i}>
                            <td>{c.title}</td>
                            <td>{totalCalculation(c.key, analyticsFacebookResult[c.key])}</td>
                        </tr>
                    ))
                }
                <tr></tr>
                <tr></tr>
                </tbody>
            </>
        )
    }

    const interestList = () => {
        return (
            <>
                <thead>
                <tr>
                    <td>INTEREST</td>
                </tr>
                </thead>

                <thead>
                <tr>
                    <th>Name</th>
                </tr>
                </thead>
                <tbody>
                {
                    analyticsFacebookResult['interest'].map((interest, i) => (
                        <tr key={i}>
                            <td>{interest}</td>
                        </tr>
                    ))
                }
                <tr></tr>
                <tr></tr>
                </tbody>
            </>
        )
    }

    const similarInfluencer = () => {
        return (
            <>
                <thead>
                <tr>
                    <td>SIMILAR INFLUENCER</td>
                </tr>
                </thead>

                <thead>
                <tr>
                    <th>Username</th>
                    <th>Name</th>
                </tr>
                </thead>
                <tbody>
                {
                    analyticsFacebookResult['simillarInfluencers'].map((influencer, i) => (
                        <tr key={i}>
                            <td>{influencer.username}</td>
                            <td>{influencer.fullName}</td>
                        </tr>
                    ))
                }
                <tr></tr>
                <tr></tr>
                </tbody>
            </>
        )
    }

    const audienceAgeStats = (array) => {
        return (
            <>
                <thead>
                <tr>
                    <td>AUDIENCE AGE STATS</td>
                </tr>
                </thead>

                <thead>
                <tr>
                    <th>Age Range</th>
                    <th>Percentage</th>
                </tr>
                </thead>
                <tbody>
                {
                    array.map((s, i) => (
                        <tr key={i}>
                            <td>{s.x}</td>
                            <td>{s.y}%</td>
                        </tr>
                    ))
                }
                <tr></tr>
                <tr></tr>
                </tbody>
            </>
        )
    }

    const genderStats = () => {
        const gender = analyticsFacebookResult['gender_distribution']
        return (
            <>
                <thead>
                <tr>
                    <td>AUDIENCE GENDER STATS</td>
                </tr>
                </thead>

                <thead>
                <tr>
                    <th>Gender</th>
                    <th>Percentage</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Female</td>
                    <td>{formatPercent(gender[0], (gender[0] + gender[1]))}</td>
                </tr>
                <tr>
                    <td>Male</td>
                    <td>{formatPercent(gender[1], (gender[0] + gender[1]))}</td>
                </tr>
                <tr></tr>
                <tr></tr>
                </tbody>
            </>
        )
    }

    const audienceLoc = () => {
        const aggLoc = analyticsFacebookResult['audience_location_distribution']
        return (
            <>
                <thead>
                <tr>
                    <td>AUDIENCE LOCATION STATS</td>
                </tr>
                </thead>

                <thead>
                <tr>
                    <th>Country</th>
                    <th>Percentage</th>
                </tr>
                </thead>
                <tbody>
                {
                    aggLoc.map((loc, i) => (
                        <tr key={i}>
                            <td>{loc.x}</td>
                            <td>{loc.y}%</td>
                        </tr>
                    ))
                }
                <tr></tr>
                <tr></tr>
                </tbody>
            </>
        )
    }

    const influencerBrandAffinity = () => {
        const brandAffinity = analyticsFacebookResult['influencer_brand_affinity']
        return (
            <>
                <thead>
                <tr>
                    <td>AUDIENCE LOCATION STATS</td>
                </tr>
                </thead>

                <thead>
                <tr>
                    <th>Username</th>
                    <th>Name</th>
                    <th>Posts</th>
                    <th>Sponsored Posts</th>
                    <th>Last On</th>
                </tr>
                </thead>
                <tbody>
                {
                    brandAffinity.map((brand, i) => (
                        <tr key={i}>
                            <td>{brand.username}</td>
                            <td>{brand.fullName}</td>
                            <td>{brand.mediaItems.length}</td>
                            <td>{brand.mediaItems[0].sponsored}</td>
                            <td>{brand.mediaItems[0].takenAt}</td>
                        </tr>
                    ))
                }
                <tr></tr>
                <tr></tr>
                </tbody>
            </>
        )
    }

    return (
        <div>
            <div className={"btn height-resp p-2 cursor-pointer "}>
                <img className={"filter-icon-resp"} src={Export}/>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button font-size-resp"
                    table="table-to-xls"
                    filename={`${getSocialMediaText(pathRaw[4])} Report For ${analyticsFacebookResult?.username}`}
                    sheet="Instagram Campaign Report"
                    buttonText="Export Excel"
                />
            </div>
            {
                !!analyticsFacebookResult.username &&
                <table id="table-to-xls" className={"hidden"}>
                    <thead>
                    <tr>
                        <td className={"font-bold"}>
                            {getSocialMediaText(pathRaw[4])} Report For {analyticsFacebookResult?.username}
                        </td>
                    </tr>
                    </thead>
                    {influencerBasicInformation()}
                    <tbody>
                    <tr>
                        <td>Suspicious Followers count</td>
                    </tr>
                    <tr>
                        <td>Instagram URL</td>
                        <td>https://www.instagram.com/{analyticsFacebookResult.username}</td>
                    </tr>
                    <tr>
                        <td>Phone Number</td>
                    </tr>
                    <tr></tr>
                    <tr></tr>
                    </tbody>
                    {(pathRaw[4] === "1" || pathRaw[4] === "2") &&interestList()}
                    { pathRaw[4] === "1" && similarInfluencer()}
                    {audienceAgeStats(analyticsFacebookResult['age_distribution'])}
                    {genderStats()}
                    {pathRaw[4] !== "5" && audienceLoc()}
                    { pathRaw[4] === "1" &&  influencerBrandAffinity()}
                </table>
            }
        </div>
    )
}

export default ExportSocialMediaComponent

import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const getProductListApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/products/all`,
            {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json'
                }
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

export {
    getProductListApi
}

import React from "react";
import {useParams} from "react-router-dom";
import TabComponent from "./tab.component";
import CampaignDetailComponent from "./campaign-detail.component";
import FilterComponent from "./filter.component";
import InfluencerListComponent from "./influencer-list.component";
import DeleteConfirmationModal from "../../components/modal/delete-confirmation.modal";
import SendNotificationModal from "../../components/modal/send-notification.modal";
import ContentComponent from "./content.component";
import {useDispatch, useSelector} from "react-redux";
import {deleteInfluencerApi} from "../../api/campaign.api";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {CAMPAIGN_REDUCER} from "../../reducers/campaign.reducer";
import ReportCampaign from "../report-campaign";
import SuccessModal from "../../components/success.modal";

const CampaignDetailPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const {
        campaignDetailActiveTab,
        reloadInfluencerList,
        influencerDetail
    } = useSelector(state => state.campaignReducer)
    const {sendNotificationModalVisibility, userRegisteredText} = useSelector(state => state.modalReducer)

    const defineContent = () => {
        switch (campaignDetailActiveTab) {
            case 'influencer':
                return (
                    <div>
                        <CampaignDetailComponent/>
                        <FilterComponent/>
                        <InfluencerListComponent/>
                    </div>
                )
                break;
            case 'content':
                return (
                    <div>
                        <CampaignDetailComponent/>
                        <ContentComponent/>
                    </div>
                )
                break;
            case 'report':
                return <ReportCampaign/>
                break;
        }
    }

    const deleteInfluencerFromMission = () => {
        deleteInfluencerApi(id, influencerDetail.id)
            .then(response => {
                dispatch({type: MODAL_REDUCER,
                    payload: {
                        deleteGroupModalVisibility: 'hidden',
                        display: 'block',
                        userRegisteredText: 'Successfully deleted user',
                    }
                })
                dispatch({type: CAMPAIGN_REDUCER, payload: {reloadInfluencerList: !reloadInfluencerList}})
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div>
            <TabComponent/>
            {defineContent()}
            <DeleteConfirmationModal
                deleteHandler={deleteInfluencerFromMission}
                message={"Are you sure want to delete user from mission?"}
            />
            {sendNotificationModalVisibility === 'block' && <SendNotificationModal userIds={[influencerDetail?.id]}/>}
            <SuccessModal message={userRegisteredText}/>
        </div>
    )
}

export default CampaignDetailPage

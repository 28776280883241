import React, {useEffect, useState} from "react";
import Mission from "../../images/svg/ico-mission-red.svg"
import parse from 'html-react-parser';
import TikTokWhite from "../../images/svg/tiktok-white.svg";
import InstagramWhite from "../../images/svg/instagram-white.svg";
import FacebookWhite from "../../images/svg/facebook-white.svg";
import YoutubeWhite from "../../images/svg/youtube-white.svg";
import TikTok from "../../images/svg/tiktok-black.svg";
import Instagram from "../../images/svg/instagram-black.svg";
import Facebook from "../../images/svg/facebook-outline-black.svg";
import Youtube from "../../images/svg/youtube-black.svg";
import TikTokRed from "../../images/svg/tiktok-outline.svg";
import InstagramRed from "../../images/svg/insta-outline.svg";
import FacebookRed from "../../images/svg/facebook-outline.svg";
import YoutubeRed from "../../images/svg/youtube.svg";
import {getUserMissionApi} from "../../api/user-registered.api";
import {Link, useParams} from "react-router-dom";
import {formatDateToReadable} from "../../ui-util/general-variable";

const MissionListComponent = () => {
    const {id} = useParams();
    const [missionList, setMissionList] = useState([])
    const [missionDetail, setMissionDetail] = useState({})

    useEffect(() => {
        getUserMissionApi(id)
            .then(response => {
                setMissionList(response.rows)
                setMissionDetail(response.rows[0])
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <div className={"mt-14 font-FuturaBT-Book grid grid-cols-2 gap-10"}>
            <div>
                <div className={"flex flex-row items-center space-x-32"}>
                    <div className={"flex flex-row items-center space-x-2"}>
                        <img src={Mission} className={"w-10 h-10"}/>
                        <h1 className={"font-FuturaBT-Medium font-bold"}>Mission Followed</h1>
                    </div>
                    <p className={"text-sm italic"}>Total {missionList.length} Missions</p>
                </div>
                <div className={"mt-10 space-y-2"}>
                    {
                        missionList.map((item, index) => (
                            <div
                                className={`hover:border hover:border-milano-red hover:cursor-pointer flex flex-row items-center p-3 space-x-32 ${missionDetail.id === item.id && 'border border-milano-red cursor-pointer'}`}
                                key={index}
                                onClick={() => setMissionDetail(item)}
                            >
                                <img src={item.thumbnail} className={"border w-32 h-20 rounded-xl object-cover"}/>
                                <div>
                                    <p className={"font-bold text-tiny font-FuturaBT-Medium"}>{item?.title}</p>
                                    <div className={"flex flex-row items-center space-x-1"}>
                                        <p className={"text-sm font-bold"}>{item.point} Point</p>
                                        <div className={"w-1 h-1 bg-black rounded-full"}/>
                                        <div className={"flex flex-row items-center space-x-1"}>
                                            {!!item?.instagram_task?.content &&
                                                <img src={Instagram} className={"w-4 h-4"}/>}
                                            {!!item?.facebook_task?.content &&
                                                <img src={Facebook} className={"w-3 h-3"}/>}
                                            {!!item?.tiktok_task?.content &&
                                                <img src={TikTok} className={"w-4 h-4"}/>}
                                            {!!item?.youtube_task?.content &&
                                                <img src={Youtube} className={"w-4 h-4"}/>}
                                        </div>
                                    </div>
                                    <p className={"text-xxs"}>Valid
                                        until {formatDateToReadable(item.campaign_end_date)}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                !!missionDetail?.thumbnail &&
                <div>
                <div className={"text-white"} style={{background: `url(${missionDetail?.thumbnail})`}}>
                    <div className={"bg-gradient-to-r from-transparent to-black p-10 flex flex-col items-center "}>
                        <div className={"space-y-1"}>
                            <h1 className={"font-bold"}>{missionDetail?.title}</h1>
                            <div className={"flex flex-row items-center space-x-2"}>
                                <p className={"text-tiny font-bold"}>{missionDetail?.point} Point</p>
                                <p className={"text-xs"}>Valid
                                    until {formatDateToReadable(missionDetail?.campaign_end_date)}</p>
                                <div className={"flex flex-row space-x-2 items-center"}>
                                    {!!missionDetail?.instagram_task?.content &&
                                        <img src={InstagramWhite} className={"w-4 h-4"}/>}
                                    {!!missionDetail?.facebook_task?.content &&
                                        <img src={FacebookWhite} className={"w-4 h-4"}/>}
                                    {!!missionDetail?.tiktok_task?.content &&
                                        <img src={TikTokWhite} className={"w-5 h-5"}/>}
                                    {!!missionDetail?.youtube_task?.content &&
                                        <img src={YoutubeWhite} className={"w-5 h-5"}/>}
                                </div>
                            </div>
                            <Link to={`/campaign/list-campaign/detail-campaign/${missionDetail?.id}`}
                                  className={"hover:text-white"}>
                                <button className={"bg-black text-xs px-5 py-2"}>
                                    {!!missionDetail?.title && missionDetail?.campaign_users[0].status}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"text-black border h-96 p-5 space-y-2"}>
                    <h1 className={"font-bold font-FuturaBT-Medium"}>Campaign Description</h1>
                    <div className={"text-xs"}>{!!missionDetail?.description && parse(missionDetail?.description)}</div>
                    <h1 className={"font-bold font-FuturaBT-Medium pb-3"}>Tasks</h1>
                    <div className={"flex flex-col space-y-10"}>
                        {
                            !!missionDetail?.instagram_task?.content &&
                            <div className={"flex flex-row space-x-2"}>
                                <img src={InstagramRed} className={"w-5 h-5"}/>
                                <div>
                                    <p className={"text-sm font-bold"}>Instagram</p>
                                    <div className={"text-xs"}>{parse(missionDetail?.instagram_task?.content)}</div>
                                </div>
                            </div>
                        }
                        {
                            !!missionDetail?.facebook_task?.content &&
                            <div className={"flex flex-row space-x-2"}>
                                <img src={FacebookRed} className={"w-5 h-5"}/>
                                <div>
                                    <p className={"text-sm font-bold"}>Facebook</p>
                                    <div className={"text-xs"}>{parse(missionDetail?.facebook_task?.content)}</div>
                                </div>
                            </div>
                        }
                        {
                            !!missionDetail?.tiktok_task?.content &&
                            <div className={"flex flex-row space-x-2"}>
                                <img src={TikTokRed} className={"w-5 h-5"}/>
                                <div>
                                    <p className={"text-sm font-bold"}>TikTok</p>
                                    <div className={"text-xs"}>{parse(missionDetail?.tiktok_task?.content)}</div>
                                </div>
                            </div>
                        }
                        {
                            !!missionDetail?.youtube_task?.content &&
                            <div className={"flex flex-row space-x-2"}>
                                <img src={YoutubeRed} className={"w-5 h-5"}/>
                                <div>
                                    <p className={"text-sm font-bold"}>Youtube</p>
                                    <div className={"text-xs"}>{parse(missionDetail?.youtube_task?.content)}</div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default MissionListComponent

import React, {useState} from "react";
import sideBarBackground from '../images/sidebar-background/sidebar-background.png'
import LookeIcon from "../images/svg/looke.svg";
import MissionIconOpen from "../images/svg/sidebar-mission-white.svg";
import MissionIcon from "../images/svg/sidebar-mission.svg";
import GoddessGangIconOpen from "../images/svg/goddess-gang-white.svg"
import GoddessGangIcon from "../images/svg/goddess-gang.svg"
import CampaignIconOpen from "../images/svg/campaign-white.svg"
import CampaignIcon from "../images/svg/campaign.svg"
import RedeemPointIconOpen from "../images/svg/redeem-white.svg"
import RedeemPointIcon from "../images/svg/redeem.svg"
import SettingIconOpen from "../images/svg/settings-white.svg"
import SettingIcon from "../images/svg/settings.svg"
import SubMenuComponent from "./sub-menu.component";

const SidebarComponent = () => {
    const [sidebar] = useState([
        {
            menuLabel: 'Mission Registered',
            key: 'mission',
            iconOpen: MissionIconOpen,
            iconClose: MissionIcon,
            isExpand: false,
            child: [
                {
                    label: 'User Registered',
                    key: 'user-registered',
                    path: '/mission/user-registered'
                }, {
                    label: 'Rejected',
                    key: 'rejected'
                }]
        }, {
            menuLabel: 'Goddess Gang',
            key: 'goddess-gang',
            icon: GoddessGangIcon,
            iconOpen: GoddessGangIconOpen,
            iconClose: GoddessGangIcon,
            isExpand: false,
            child: [
                {
                    label: 'All Users',
                    key: 'all-users'
                }, {
                    label: 'Group',
                    key: 'group'
                }]
        }, {
            menuLabel: 'Campaign',
            key: 'campaign',
            icon: CampaignIcon,
            iconOpen: CampaignIconOpen,
            iconClose: CampaignIcon,
            isExpand: false,
            child: [
                {
                    label: 'List Campaign',
                    key: 'list-campaign'
                }, {
                    label: 'Create Campaign',
                    key: 'create-campaign'
                }, {
                    label: 'Approvement Campaign',
                    key: 'approvement-campaign'
                },
            ]
        }, {
            menuLabel: 'Redeem Point',
            key: 'redeem',
            icon: RedeemPointIcon,
            iconOpen: RedeemPointIconOpen,
            iconClose: RedeemPointIcon,
            isExpand: false,
            child: []
        }, {
            menuLabel: 'Setting',
            key: 'setting',
            icon: SettingIcon,
            iconOpen: SettingIconOpen,
            iconClose: SettingIcon,
            isExpand: false,
            child: [
                {
                    label: 'Banner Goddess Gang',
                    key: 'banner-goddess-gang'
                }, {
                    label: 'Create Questioner Form',
                    key: 'create-questioner-form'
                }, {
                    label: 'Create Timeline',
                    key: 'create-timeline'
                }, {
                    label: 'Log Affable',
                    key: 'log-affable'
                }
            ]
        }
    ])

    return (
        <div className={"bg-gradient-to-t from-linen border-r flex flex-col items-center w-300 bg-linen h-screen fixed"} >
            <div
                className={"bg-no-repeat bg-bottom h-screen w-full flex flex-col items-center overflow-auto"}
                style={{
                    backgroundImage: `url(${sideBarBackground})`,
                }}
            >
                <div className={"h-full flex flex-col items-center"}>
                    <div className={"flex flex-col items-center w-48 py-10"}>
                        <a href={'/'}>
                            <img src={LookeIcon} alt="looke icon"/>
                        </a>
                        <div className={"mt-8"}>
                            {
                                sidebar.map(menu => <SubMenuComponent menu={menu} key={menu.key}/>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SidebarComponent

import React from "react";
import Chart from "react-apexcharts";
import {customDateToReadable, getSocialMediaText} from "../ui-util/general-variable";

const LineChartComponent = (props) => {
    const {data, title, tooltip} = props

    return (
        <div className={"border p-3"}>
            <div className={"flex flex-row items-center justify-between mb-3"}>
                <p className={"font-FuturaBT-Medium text-sm ml-5 font-semibold"}>{title}</p>
                <div className={"flex flex-col group relative"}>
                    <p className="invisible bg-gray-200 absolute z-10 text-xxs group-hover:visible border border-gray-300 p-3 rounded bottom-6 w-40 right-0">
                        {tooltip}
                    </p>
                    <button
                        className="text-sm bg-gray-200 w-5 h-5 rounded-full text-gray-400 hover:text-black inline-block">?
                    </button>
                </div>
            </div>
            <Chart
                type="line"
                height={320}
                options={{
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                        toolbar: {
                            export: {
                                csv: {
                                    filename: 'Engagement Timeline ',
                                },
                                svg: {
                                    filename: 'Engagement Timeline ',
                                },
                                png: {
                                    filename: 'Engagement Timeline ',
                                }
                            }
                        }
                    },
                    yaxis: {
                        min: 0,
                        tickAmount: 5,
                        labels: {
                            offsetX: -12,
                            minWidth: 42,
                            style: {
                                colors: '#953232',
                                fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                fontSize: 10,
                            }
                        }
                    },
                    xaxis: {
                        labels: {
                            formatter: function (value) {return customDateToReadable(value, 'DD MMMM')},
                            style: {
                                colors: '#6f7070',
                                fontFamily: "'Belleza', Arial, Helvetica, sans-serif",
                                fontSize: 10,
                            }
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight',
                        width: 2
                    },
                    markers: {
                        size: 5,
                    },
                    colors: ['#953232']
                }}
                series={data}
            />
        </div>
    )
}

export default LineChartComponent

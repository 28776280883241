const INITIAL_STATE = {
    redeemList: [],
    reload: false,
    status: '',
    userDetail: {}
}

export const REDEEM_REDUCER = 'REDEEM_REDUCER';

export const redeemReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REDEEM_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}

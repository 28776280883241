import React from "react";
import {useDispatch, useSelector} from "react-redux";
import TimelineItemComponent from "./timeline-item.component";
import {updateTimelineApi} from "../../api/timeline.api";
import {TIMELINE_REDUCER} from "../../reducers/timeline.reducer";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";

const TimelineListComponent = () => {
    const formRef = React.createRef()
    const dispatch = useDispatch()
    let {timelineList, reload} = useSelector(state => state.timelineReducer)

    const submitHandler = () => {
        const x = document.getElementById("timeline").elements
        timelineList.map(item => {
            item.text = x[item.title].value
            return item
        })

        updateTimelineApi({labels: timelineList})
            .then(() => {
                dispatch({type: TIMELINE_REDUCER, payload: {reload: !reload}})
                dispatch({type: MODAL_REDUCER, payload: {display: 'block'}})
            })
            .catch(err => console.log(err))
    }

    return (
        <div className={"flex flex-col space-y-5 font-FuturaBT-Medium text-sm"}>
            <form id="timeline" ref={formRef}>
                {
                    timelineList.map((item, index) => <TimelineItemComponent item={item} index={index} key={index}/>)
                }
            </form>
            <div className={"flex flex-row-reverse pt-10"}>
                <button className={"bg-milano-red text-white px-8 h-8"} onClick={() => submitHandler()}>Submit</button>
            </div>
        </div>
    )
}

export default TimelineListComponent

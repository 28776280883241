import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {TOOLS_REDUCER} from "../../reducers/tools.reducer";
import {CAMPAIGN_REDUCER} from "../../reducers/campaign.reducer";

const TabComponent = () => {
  const dispatch = useDispatch()
  const {campaignDetailActiveTab} = useSelector(state => state.campaignReducer)
    const [tabList] = useState([{
        label: 'Influencer',
        key: 'influencer'
    }, {
        label: 'Content',
        key: 'content'
    }, {
        label: 'Report',
        key: 'report'
    }])

  const changeTabHandler = (key) => {
    dispatch({type: CAMPAIGN_REDUCER, payload: {campaignDetailActiveTab: key}})
  }
    return (
        <div className={"flex flex-row items-center font-FuturaBT-Book"} id={"section-dont-print"}>
          {
            tabList.map((item, index) => (
                <button className={"border px-8 py-4"} onClick={() => changeTabHandler(item.key)} key={index}>
                  <p className={`text-2xl font-semibold ${campaignDetailActiveTab === item.key ? 'text-milano-red' : 'text-gray-400'}`}>{item.label}</p>
                </button>
            ))
          }
        </div>
    )
}

export default TabComponent

import React, {useEffect} from "react";
import {getTimelineListApi} from "../../api/timeline.api";
import {useDispatch, useSelector} from "react-redux";
import {TIMELINE_REDUCER} from "../../reducers/timeline.reducer";
import TimelineListComponent from "./timeline-list.component";
import SuccessModal from "../../components/success.modal";

const TimelinePage = () => {
    const dispatch = useDispatch()
    const {reload} = useSelector(state => state.timelineReducer)

    useEffect(() => {
        getTimelineListApi()
            .then(response => {
                dispatch({type: TIMELINE_REDUCER, payload: {timelineList: response}})
            })
            .catch(err => {
                console.log(err)
            })
    }, [reload])

    return (
        <div>
            <TimelineListComponent />
            <SuccessModal message={"Timeline has been successfully updated!"}/>
        </div>
    )
}

export default TimelinePage

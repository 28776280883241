import React, {useState} from "react";
import IgIcon from "../../images/svg/insta-outline.svg";
import Tiktok from "../../images/svg/tiktok-outline.svg";
import CheckRed from "../../images/svg/ico-check-red.svg";
import TableComponent from "../../components/table.component";
import Approve from "../../images/svg/approve-green.svg";
import Reject from "../../images/svg/reject-red.svg";
import SeeData from "../../images/svg/ico-file.svg";
import ArrowRight from "../../images/svg/arrow-right.svg";
import PotentialInfluencer from "../../images/svg/potential-influencer.svg";
import {useDispatch, useSelector} from "react-redux";
import {formatDateToReadable, formatFollower} from "../../ui-util/general-variable";
import {Link, useLocation} from "react-router-dom";
import {setUserStatusApi} from "../../api/user-registered.api";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";

const UserListComponent = () => {
    const location = useLocation();
    const pathRaw = location.pathname.split('/')
    const dispatch = useDispatch()
    const {userList, reload} = useSelector(state => state.userRegisteredReducer)

    const [columns] = useState([
        {
            type: 'checkbox',
            isRightBorderAvailable: true,
        },
        {
            name: 'User',
            arrow: true,
            dataIndex: 'full_name',
            isRightBorderAvailable: true,
            render: (item) => {
                return (
                    <Link to={pathRaw[2] === 'rejected' ? `/mission/rejected/user-detail/${item.id}` : `/mission/user-registered/user-detail/${item.id}`}
                          className={"flex flex-row items-center hover:text-milano-red cursor-pointer"}>
                        <img className={"mr-2 w-14 h-14 rounded-full"} src={item.photo}/>
                        <div>
                            <div className={"flex flex-row items-center"}>
                                <p className={"font-large-resp font-FuturaBT-Medium"}>{item.full_name}</p>
                                {item.is_potential && <img className={"h-5 w-5 ml-1"} src={PotentialInfluencer}/>}
                            </div>
                            <p className={"font-base-resp"}>{formatDateToReadable(item.birth_date)}</p>
                            <p className={"font-base-resp"}>{item.email}</p>
                        </div>
                    </Link>
                )
            }
        }, {
            name: 'Username',
            icon: IgIcon,
            dataIndex: 'username_instagram',
            render: (record, username_instagram) => (
                <p className={"font-base-resp"}>{!!username_instagram ? `@${username_instagram}` : ''}</p>
            )
        }, {
            name: 'Followers',
            arrow: true,
            dataIndex: 'follower_instagram',
            render: (record, follower_instagram) => (
                <p className={"font-base-resp"}>{formatFollower(follower_instagram)}</p>
            )
        }, {
            name: 'ER',
            arrow: true,
            dataIndex: 'er_instagram',
            render: (record, er_instagram) => (
                <p className={"font-base-resp"}>{er_instagram}</p>
            )
        }, {
            name: 'Reach',
            isRightBorderAvailable: true,
            dataIndex: 'reach_instagram',
            render: (record, reach_instagram) => (
                <p className={"font-base-resp"}>{formatFollower(reach_instagram)}</p>
            )
        }, {
            name: 'Username',
            icon: Tiktok,
            dataIndex: 'username_tiktok',
            render: (record, username_tiktok) => (
                <p className={"font-base-resp"}>{!!username_tiktok ? `@${username_tiktok}` : ''}</p>
            )
        }, {
            name: 'Followers',
            arrow: true,
            dataIndex: 'follower_tiktok',
            render: (record, follower_tiktok) => (
                <p className={"font-base-resp"}>{formatFollower(follower_tiktok)}</p>
            )
        }, {
            name: 'ER',
            arrow: true,
            dataIndex: 'er_tiktok',
            render: (record, er_tiktok) => (
                <p className={"font-base-resp"}>{er_tiktok}</p>
            )
        }, {
            name: 'Reach',
            isRightBorderAvailable: true,
            dataIndex: 'reach_tiktok',
            render: (record, reach_tiktok) => (
                <p className={"font-base-resp"}>{formatFollower(reach_tiktok)}</p>
            )
        }, {
            name: 'Social Media',
            isRightBorderAvailable: true,
            dataIndex: 'social_media',
            render: (item) => {
                return (
                    <div className={"flex flex-row items-center justify-center space-x-1"}>
                        {
                            item.social_medias.map((item, index) => {
                                if(item.id !== 7){
                                    return (
                                        <a href={item.user_social_medias.value} target={'_blank'} key={index}>
                                            <img className={"w-5 h-5"} src={item.logo}/>
                                        </a>
                                    )
                                }
                            })
                        }
                    </div>
                )
            }
        }, {
            name: 'Reg. Ses',
            isRightBorderAvailable: true,
            dataIndex: 'goddes_gang_session',
            render: (item, register_session) => <p className={"text-center"}>{register_session}x</p>
        }, {
            type: 'answer',
            icon: CheckRed,
            isRightBorderAvailable: true,
            render: (item) =>
                <div className={"flex flex-row items-center justify-center"}>
                    <p className={"text-milano-red text-2xl"}>{item.total_questioner_correct_answer}</p>
                    <p>/{item.total_questioner_answer}</p>
                </div>
        }, {
            name: 'Status',
            dataIndex: 'status',
            render: (item) => {
                if (item.goddes_gang_status === 'approve') {
                    return (
                        <a href={'/'} className={"flex justify-center items-center"}>
                            <p className={"text-milano-red"}>Detail</p>
                            <img className={"w-4 h-4 ml-1"} src={ArrowRight}/>
                        </a>
                    )
                } else if (item.goddes_gang_status === 'reject') {
                    return (
                        <div className={"flex justify-center"}>
                            <button className={"p-2 border font-FuturaBT-Medium bg-gray-200"}>Rejected</button>
                        </div>
                    )
                } else {
                    return (
                        <div className={"flex items-center justify-center"}>
                            <div className={"flex-col"}>
                                <button className={"flex flex-row items-center"}
                                        onClick={() => setUserStatus(item, 'approve')}
                                >
                                    <img className={"w-10 h-10"} src={Approve}/>
                                    <p>Approve</p>
                                </button>
                                <button className={"flex flex-row items-center"}
                                        onClick={() => setUserStatus(item, 'reject')}
                                >
                                    <img className={"w-10 h-10"} src={Reject}/>
                                    <p>Reject</p>
                                </button>
                                <button className={"flex flex-row items-center"}
                                        onClick={() => {
                                            dispatch({
                                                type: MODAL_REDUCER,
                                                payload: {questionerResultModalVisibility: 'block'}
                                            })
                                            dispatch({
                                                type: USER_REGISTERED_REDUCER,
                                                payload: {userDetail: item}
                                            })
                                        }}

                                >
                                    <img className={"w-4 h-4 m-3"} src={SeeData}/>
                                    <p>See Data</p>
                                </button>
                            </div>
                        </div>
                    )
                }
            }
        }])

    const setUserStatus = (userDetail, status) => {
        let requestBody = {status}
        setUserStatusApi(userDetail.id, requestBody)
            .then(result => {
                dispatch({
                    type: MODAL_REDUCER,
                    payload: {userRegisteredText: 'Berhasil mengubah status user', display: 'block'}
                })
                dispatch({type: USER_REGISTERED_REDUCER, payload: {reload: !reload}})
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <TableComponent
            columns={columns}
            dataSource={userList}
        />
    )
}
export default UserListComponent

import React from "react";
import GreenCheckLargeIcon from "../images/svg/approve-green-large.svg";
import ModalComponent from "./modal.component";

const SuccessModal = (props) => {
    const {message} = props
    return (
        <ModalComponent>
            <img className={"animate-bounce"} src={GreenCheckLargeIcon}/>
            <p className={"text-3xl font-FuturaBT-Medium"}>Done!</p>
            <p className={"font-FuturaBT-Book mt-5 text-center"}>{message}</p>
        </ModalComponent>
    )
}

export default SuccessModal

import React from "react";
import {formatFollower, totalCalculation} from "../../ui-util/general-variable";

const InfluencerItemComponent = (props) => {
    const {item} = props
    const influencerItem = [{
        title: 'Name',
        key: 'fullName',
        object: 'creator'
    }, {
        title: 'Username',
        key: 'username',
        object: 'creator'
    }, {
        title: 'Follower Count',
        key: 'followerCount',
        object: 'creator'
    }, {
        title: 'Content',
        key: 'urlLarge',
        object: 'media'
    }, {
        title: 'Content Type',
        key: 'mediaType',
        object: 'media'
    }, {
        title: 'Caption',
        key: 'caption',
        object: 'media'
    }, {
        title: 'Date Posted',
        key: 'takenAt',
        object: 'media'
    }, {
        title: 'Total View',
        key: 'viewCount',
        object: 'media'
    }, {
        title: 'Total Like',
        key: 'likeCount',
        object: 'media'
    }, {
        title: 'Total Comment',
        key: 'commentCount',
        object: 'media'
    }]
    return (
        <>
            <thead>
            <tr>
                <td>INFLUENCER BASIC INFORMATION</td>
            </tr>
            </thead>

            <thead>
            <tr>
                <th>Influencer Information</th>
                <th>Value</th>
            </tr>
            </thead>
            <tbody>
            {
                influencerItem.map((k, i) => (
                    <tr key={i}>
                        <td>{k.title}</td>
                        <td>{totalCalculation(k.key, item[k.object][k.key])}</td>
                    </tr>
                ))
            }
            <tr></tr>
            <tr></tr>
            </tbody>

            <thead>
            <tr>
                <td>AUDIENCE AGE STATS</td>
            </tr>
            </thead>

            <thead>
            <tr>
                <th>Age Range</th>
                <th>Percentage</th>
            </tr>
            </thead>
            <tbody>
            {
                item.age.buckets.map((s, i) => (
                    <tr key={i}>
                        <td>{s.label}</td>
                        <td>{s.percent}</td>
                    </tr>
                ))
            }
            <tr></tr>
            <tr></tr>
            </tbody>

            <thead>
            <tr>
                <td>AUDIENCE GENDER STATS</td>
            </tr>
            </thead>

            <thead>
            <tr>
                <th>Gender</th>
                <th>Percentage</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Female</td>
                <td>{item.gender.female}</td>
            </tr>
            <tr>
                <td>Male</td>
                <td>{item.gender.male}</td>
            </tr>
            <tr></tr>
            <tr></tr>
            </tbody>

            <thead>
            <tr>
                <td>ENGAGEMENT STATS</td>
            </tr>
            </thead>

            <thead>
            <tr>
                <th>Influencer Information</th>
                <th>Percentage</th>
                <th>Total</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Average Comment Ratio</td>
                <td>{(item.creator.engagement.avgCommentsRatio * 100).toFixed(2)}</td>
            </tr>
            <tr>
                <td>Average Likes Ratio</td>
                <td>{(item.creator.engagement.avgLikesRatio * 100).toFixed(2)}</td>
            </tr>
            <tr>
                <td>Total Est. Reach</td>
                <td></td>
                <td>{formatFollower(item.estimatedReach)}</td>
            </tr>
            <tr>
                <td>Total Est. Impressions</td>
                <td></td>
                <td>{formatFollower(item.estimatedImpressions)}</td>
            </tr>
            <tr>
                <td>Total Est. Media</td>
                <td></td>
                <td>{formatFollower(item.estimatedMediaValue)}</td>
            </tr>
            <tr></tr>
            <tr></tr>
            </tbody>
        </>
    )
}

export default InfluencerItemComponent

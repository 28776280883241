import React from "react";
import Search from "../../images/svg/ico-search.svg";
import SelectComponent from "../../components/select.component";
import PopoverCorrectAnsComponent from "../../components/popover-correct-ans.component";
import PopoverFilterInstagramTiktokComponent from "../../components/popover-filter-instagram-tiktok.component";
import {useDispatch, useSelector} from "react-redux";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import DatePickerComponent from "../../components/date-picker.component";
import SearchComponent from "../../components/search.component";
import PageSizeComponent from "../../components/page-size.component";
import {setBulkUserStatusApi, updateUserDataWithAffableApi} from "../../api/user-registered.api";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";

const UserFilterComponent = () => {
    const dispatch = useDispatch()
    const {statusToUpdate, selectedRowKeys, reload} = useSelector(state => state.userRegisteredReducer)

    const syncUserDataWithAffable = () => {
        let requestBody = {
            user_ids: selectedRowKeys
        }
        updateUserDataWithAffableApi(requestBody)
            .then(response => {
                dispatch({type: USER_REGISTERED_REDUCER, payload: {reload: !reload, selectedRowKeys: [], selectedRows: []}})
                dispatch({
                    type: MODAL_REDUCER,
                    payload: {display: 'block', userRegisteredText: 'Successfully update user profile'}
                })
            })
            .catch(err => console.log(err))
    }

    const setUserStatusBulk = () => {
        let requestBody = {
            status: statusToUpdate,
            user_ids: selectedRowKeys
        }
        setBulkUserStatusApi(requestBody)
            .then(response => {
                dispatch({type: MODAL_REDUCER, payload: {userRegisteredText: 'Berhasil mengubah status user', display: 'block'}})
                dispatch({type: USER_REGISTERED_REDUCER, payload: {reload: !reload}})
            })
            .catch(err => console.log(err))
    }

    return (
        <div className={"flex flex-row w-full justify-between font-size-resp"}>
            <div className={"flex flex-row justify-between space-x-2"}>
                <SelectComponent title={'Status'}/>
                <button className={`btn bg-milano-red height-resp px-2 disabled:opacity-60`}
                        onClick={() => setUserStatusBulk()}
                        disabled={!(selectedRowKeys.length > 0 && (statusToUpdate.length > 0 ? true : false))}
                >
                    <p className={"text-white font-FuturaBT-Medium text-white uppercase"}>Submit</p>
                </button>
                <button className={"height-resp xl:w-24 2xl:w-28 bg-milano-red disabled:opacity-60"}
                        onClick={syncUserDataWithAffable}
                        disabled={selectedRowKeys.length === 0}
                >
                    <p className={"text-white font-FuturaBT-Medium uppercase"}>Update User</p>
                </button>
                <PageSizeComponent/>
            </div>
            <div className={"flex flex-row justify-end space-x-2"}>
                <PopoverCorrectAnsComponent/>
                <PopoverFilterInstagramTiktokComponent/>
                <DatePickerComponent/>

                <SelectComponent title={'All Sosmed'}/>
                <SelectComponent title={'City'}/>

                <SearchComponent placeholder={'Search'}/>
            </div>
        </div>
    )
}
export default UserFilterComponent

import React, {useEffect, useState} from "react";
import SortAscending from "../images/svg/ascending-filter.svg";
import SortDescending from "../images/svg/descending-filter.svg";
import {useDispatch, useSelector} from "react-redux";
import {TOOLS_REDUCER} from "../reducers/tools.reducer";

const SortComponent = () => {
    const dispatch = useDispatch()
    const [descending, setDescending] = useState(true)
    const {sort} = useSelector(state => state.toolsReducer)

    useEffect(() => {
        setDescending(sort === 'desc' ? true : false)
    }, [])

    const onClickButtonSortHandler = () => {
        setDescending(!descending)
        dispatch({type: TOOLS_REDUCER, payload: {sort: !descending ? 'desc' : 'asc'}})
    }

    return (
        <button className={"btn p-2 height-resp"}
                onClick={() => onClickButtonSortHandler()}>
            <img className={"img-3-responsive"} src={descending ? SortAscending : SortDescending } alt={'sort'}/>
        </button>
    )
}

export default SortComponent

import React, {useEffect} from "react";
import CreateMissionPage from "../create-mission";
import {useParams} from "react-router-dom";
import {getCampaignDetailApi} from "../../api/campaign.api";
import {CREATE_CAMPAIGN_REDUCER} from "../../reducers/create-campaign.reducer";
import {useDispatch, useSelector} from "react-redux";
import SuccessModal from "../../components/success.modal";
import {filter} from "lodash";

const EditMissionPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const {successModalText} = useSelector(state => state.modalReducer)

    useEffect(() => {
        if (!!id) {
            getCampaignDetailApi(id)
                .then(response => {
                    let payload = {}
                    dispatch({
                        type: CREATE_CAMPAIGN_REDUCER, payload: {
                            campaignDetailEdit: response,
                            thumbnail: {name: response.thumbnail},
                            description: response.description,
                            shipment_instruction: response.shipment_intructions,
                        }
                    })
                    if(response.campaign_setting === 4 || response.campaign_setting === 3){
                        if(response.campaign_setting === 4){
                            let groups = []
                            filter(response.campaign_invitations, item => {
                                groups.push(item.group)
                            })
                            payload.invitation_group = groups
                        } else {
                            payload.invitation_user = response.campaign_invitations
                        }
                    }
                    if(response.campaign_products.length > 0){
                        let products = []
                        filter(response.campaign_products, item => {
                            products.push(item.product)
                        })
                        payload.products = products
                    }
                    payload.tiktok_task_content = response.tiktok_task.content
                    payload.tiktok_task_hashtag = response.tiktok_task.hashtag
                    payload.tiktok_task_mention = response.tiktok_task.mention
                    payload.facebook_task_content = response.facebook_task.content
                    payload.facebook_task_hashtag = response.facebook_task.hashtag
                    payload.facebook_task_mention = response.facebook_task.mention
                    payload.instagram_task_content = response.instagram_task.content
                    payload.instagram_task_hashtag = response.instagram_task.hashtag
                    payload.instagram_task_mention = response.instagram_task.mention
                    payload.youtube_task_content = response.youtube_task.content
                    payload.youtube_task_hashtag = response.youtube_task.hashtag
                    payload.youtube_task_mention = response.youtube_task.mention
                    dispatch({type: CREATE_CAMPAIGN_REDUCER, payload})
                })
                .catch(err => {
                    console.log(err)
                })
        }

        return () => {
            window.location.reload()
            dispatch({type: CREATE_CAMPAIGN_REDUCER, payload: {campaignDetailEdit: {}}})
        }
    }, [id])

    return (
        <>
            <CreateMissionPage/>
            <SuccessModal message={successModalText}/>
        </>
    )
}

export default EditMissionPage

import React from "react";
import {useSelector} from "react-redux";

const UserBankComponent = () => {
  const {userDetail} = useSelector(state => state.redeemReducer)
  return (
      <div>
        {
          userDetail.user_banks.map((item, index) => (
              <div className={"border grid grid-cols-3 p-4"} key={index}>
                <div className={"flex flex-col space-y-3 items-center"}>
                  <p>Bank Tujuan</p>
                  <p className={"text-center font-bold"}>{item?.bank?.bank_name}</p>
                </div>
                <div className={"flex flex-col space-y-3 items-center"}>
                  <p>Nomor Rekening</p>
                  <p className={"text-center font-bold"}>{item?.account_number}</p>
                </div>
                <div className={"flex flex-col space-y-3 items-center"}>
                  <p>Nama Pemilik</p>
                  <p className={"text-center font-bold"}>{item?.account_name}</p>
                </div>
              </div>
          ))
        }
      </div>
  )
}

export default UserBankComponent

import React, {useEffect, useState} from "react";
import TableComponent from "../../components/table.component";
import PotentialInfluencer from "../../images/svg/potential-influencer.svg";
import {
    customDateToReadable,
    doConvertNumberToRupiahFormat,
    formatDateToReadable
} from "../../ui-util/general-variable";
import {getRedeemListApi, redeemApprovalApi} from "../../api/redeem.api";
import {useDispatch, useSelector} from "react-redux";
import {REDEEM_REDUCER} from "../../reducers/redeem.reducer";
import {TOOLS_REDUCER} from "../../reducers/tools.reducer";
import Approve from "../../images/svg/approve-green.svg";
import Reject from "../../images/svg/reject-red.svg";
import {Link} from "react-router-dom";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";

const RedeemListComponent = () => {
    const dispatch = useDispatch()
    const {status, redeemList, reload} = useSelector(state => state.redeemReducer)
    const {startDate, endDate, searchKey, currentPage, pageSize, orderBy, sort, reloadDatePicker} = useSelector(state => state.toolsReducer)
    const [columns] = useState([
        {
            type: 'checkbox',
            isRightBorderAvailable: true,
        },
        {
            name: 'User',
            dataIndex: 'user',
            isRightBorderAvailable: true,
            render: (item, user) => {
                return (
                    <Link to={`/redeem/redeem-point/redeem-detail/${item.user_id}`}
                        className={"flex flex-row items-center hover:text-milano-red cursor-pointer"}>
                        <img className={"mr-2 w-14 h-14 rounded-full"} src={user.photo}/>
                        <div>
                            <div className={"flex flex-row items-center"}>
                                <p className={"font-large-resp font-FuturaBT-Medium"}>{user.full_name}</p>
                                {user.is_potential && <img className={"h-5 w-5 ml-1"} src={PotentialInfluencer}/>}
                            </div>
                            <p className={"text-sm"}>{formatDateToReadable(user.birth_date)}</p>
                            <p className={"text-tiny"}>{user.email}</p>
                        </div>
                    </Link>
                )
            }
        }, {
            name: 'Total Point',
            arrow: true,
            dataIndex: 'total_point',
            isRightBorderAvailable: true,
            render: (record, total_point) => (
                <p className={"font-base-resp text-center"}>{total_point} Point</p>
            )
        }, {
            name: 'Point yang di Redeem',
            isRightBorderAvailable: true,
            dataIndex: 'point_withdraw',
            render: (record, point_withdraw) => (
                <p className={"font-base-resp text-center"}>{point_withdraw} Point</p>
            )
        }, {
            name: 'Jumlah Konversi(Rp)',
            isRightBorderAvailable: true,
            dataIndex: 'total_withdraw',
            render: (record, total_withdraw) => (
                <p className={"font-base-resp text-center"}>{doConvertNumberToRupiahFormat(total_withdraw)}</p>
            )
        }, {
            name: 'Waktu Redeem',
            isRightBorderAvailable: true,
            dataIndex: 'created_at',
            render: (record, created_at) => (
                <p className={"font-base-resp text-center"}>{formatDateToReadable(created_at)}</p>
            )
        }, {
            name: 'Status',
            dataIndex: 'status',
            render: (record, status) => {
                if (status === 'Waiting Approval') {
                    return (
                        <div className={"flex flex-col items-center"}>
                            <div>
                                <button className={"flex flex-row items-center"}
                                        onClick={() => approvalHandler(record.id, 'Approve')}
                                >
                                    <img className={"w-10 h-10"} src={Approve}/>
                                    <p>Approve</p>
                                </button>
                                <button className={"flex flex-row items-center"}
                                        onClick={() => approvalHandler(record.id, 'Reject')}
                                >
                                    <img className={"w-10 h-10"} src={Reject}/>
                                    <p>Reject</p>
                                </button>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <p className={`font-base-resp text-center font-bold ${status === 'Approve' ? 'text-green-leaf' : 'text-milano-red'}`}>{status}</p>
                    )
                }
            }
        }])

    useEffect(() => {
        let requestBody = {
            status,
            start_date: !!startDate ? customDateToReadable(startDate, 'YYYY-MM-DD') : '',
            end_date: !!endDate ? customDateToReadable(endDate, 'YYYY-MM-DD') : '',
            keyword: searchKey,
            user_id: ''
        }

        let params = {
            page: currentPage,
            limit: pageSize,
            orderby: orderBy,
            sort
        }

        getRedeemListApi(requestBody, params)
            .then(response => {
                dispatch({type: REDEEM_REDUCER, payload: {redeemList: response.rows}})
                dispatch({type: TOOLS_REDUCER, payload: {totalData: response.total_data}})
            })
            .catch(err => {
                console.log(err)
            })
    }, [pageSize, currentPage, searchKey, status, reloadDatePicker, orderBy, sort, reload])

    const approvalHandler = (userId, statusText) => {
        let requestBody = {
            status: statusText,
            withdraw_ids: [userId]
        }
        redeemApprovalApi(requestBody)
            .then(response => {
                dispatch({type: MODAL_REDUCER, payload: {display: 'block'}})
                dispatch({type: REDEEM_REDUCER, payload: {reload: !reload}})
            })
            .catch(err => console.log(err))
    }

    return (
        <TableComponent
            columns={columns}
            dataSource={redeemList}
        />
    )
}

export default RedeemListComponent

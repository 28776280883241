const QuestionerDetailObject = {
    title: '',
    image: '',
    description: '',
    details: []
}

const QuestionerItemObject = {
    question: '',
    image: null,
    type: 'radio',
    sequence: null,
    is_published: false,
    options: []
}

const OptionItemObject = {
    type: '',
    label: '',
    is_correct: false
}

export {
    QuestionerDetailObject,
    QuestionerItemObject,
    OptionItemObject
}



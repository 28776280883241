const INITIAL_STATE = {
    bannerCoverList: [],
    bannerOtherList: [],
    reload: false,
    bannerDetail: {},
    activateValue: false
}

export const BANNER_REDUCER = 'BANNER_REDUCER';

export const bannerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BANNER_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}

import React from "react";
import {useSelector} from "react-redux";
import {doConvertNumberToRupiahFormat, formatDateToReadable} from "../../ui-util/general-variable";
import PotentialInfluencer from "../../images/svg/potential-influencer.svg";
import RedeemWhite from "../../images/svg/redeem-all-white.svg";
import UserBankComponent from "./user-bank.component";

const UserDetailRedeemComponent = () => {
    const {userDetail} = useSelector(state => state.redeemReducer)

    return (
        <div className={"grid grid-cols-3 gap-10"}>
            <div className={"flex flex-row items-center justify-between"}>
                <img src={userDetail?.photo} className={"rounded-full w-32"}/>
                <div className={"flex flex-col space-y-1"}>
                    <p className={"text-3xl font-bold"}>{userDetail?.full_name}</p>
                    <p>{formatDateToReadable(userDetail?.birth_date)}</p>
                    <p>{userDetail?.phone}</p>
                </div>
                <div>
                    <p>{userDetail?.email}</p>
                    <div className={"flex flex-row items-center"}>
                        <img src={PotentialInfluencer} className={"h-3 w-3"}/>
                        <p className={"font-bold text-milano-red ml-1"}>Potential Influencer</p>
                    </div>
                </div>
            </div>

            <div className={"font-FuturaBT-Medium space-y-5"}>
                <p className={"font-bold"}>Detail Rekening</p>
                <UserBankComponent/>
            </div>

            <div className={"flex flex-col justify-center"}>
                <div className={"grid grid-cols-2 gap-5 justify-center"}>
                    <div className={"space-y-4"}>
                        <p className={"font-bold"}>Detail Redeem</p>
                        <div className={"font-FuturaBT-Medium font-bold border p-3 bg-no-repeat bg-right-bottom bg-70% bg-campaign-detail-background h-24 relative"}>
                            <p>Total Point</p>
                            <p className={"text-2xl"}>{userDetail?.total_point} Point</p>
                            <img src={RedeemWhite} className={"w-10 h-10 absolute bottom-0 right-0"}/>
                        </div>
                    </div>
                    <div className={"space-y-4"}>
                        <p className={"font-bold"}>Dana yang di Redeem</p>
                        <div className={"font-FuturaBT-Medium font-bold border p-3 bg-no-repeat bg-right-bottom bg-70% bg-campaign-detail-background h-24 relative"}>
                            <p>Total Point</p>
                            <p className={"text-2xl"}>{doConvertNumberToRupiahFormat(userDetail?.total_dana_withdraw)}</p>
                            <img src={RedeemWhite} className={"w-10 h-10 absolute bottom-0 right-0"}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDetailRedeemComponent

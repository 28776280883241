const INITIAL_STATE = {
    userList: []
}

export const MISSION_REDUCER = 'MISSION_REDUCER';

export const missionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MISSION_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}

export const campaignValidation = [
    {
        key: 'title',
        message: 'Title campaign wajib diisi'
    }, {
        key: 'point',
        message: 'Campaign point wajib diisi'
    }, {
        key: 'registration_start_date',
        message: 'Registration start date wajib diisi'
    }, {
        key: 'registration_end_date',
        message: 'Registration end date wajib diisi'
    }, {
        key: 'registration_start_time',
        message: 'Registration start time wajib diisi'
    }, {
        key: 'registration_end_time',
        message: 'Registration end time wajib diisi'
    }, {
        key: 'campaign_start_date',
        message: 'Registration start date wajib diisi'
    }, {
        key: 'campaign_end_date',
        message: 'Registration end date wajib diisi'
    }, {
        key: 'campaign_start_time',
        message: 'Registration start time wajib diisi'
    }, {
        key: 'campaign_end_time',
        message: 'Registration end time wajib diisi'
    }, {
        key: 'thumbnail',
        message: 'Gambar wajib diisi'
    }, {
        key: 'description',
        message: 'Description wajib diisi'
    }, {
        key: 'campaign_setting',
        message: 'Campaign setting wajib diisi'
    }, {
        key: 'flag_send_product',
        message: 'Send product wajib diisi'
    }, {
        key: 'task',
        message: 'Task wajib diisi'
    }, {
        key: 'tiktok_task_content',
        message: 'Tiktok task wajib diisi'
    }, {
        key: 'youtube_task_content',
        message: 'Youtube task wajib diisi'
    }, {
        key: 'facebook_task_content',
        message: 'Facebook task wajib diisi'
    }, {
        key: 'instagram_task_content',
        message: 'Tiktok task wajib diisi'
    }
]

import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {TOOLS_REDUCER} from "../reducers/tools.reducer";

const SubMenuComponent = (props) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const {menu} = props
    const pathRaw = location.pathname.split('/')
    const pathName = pathRaw[1]
    const [dropdown, setDropdown] = useState(false)
    const {sideBarSelectedKey} = useSelector(state => state.toolsReducer)

    useEffect(() => {
        if (menu.key === pathName) {
            setDropdown(true)
            dispatch({type: TOOLS_REDUCER, payload: {sideBarSelectedKey: pathRaw[2]}})
        }
    }, [pathName])

    const changeDropDown = () => {
        setDropdown(!dropdown)
    }

    return (
        <div className={"mb-2.5"} key={menu.key}>
            {
                menu.child.length > 0 ?
                    <div onClick={!!menu.child && changeDropDown } className={"cursor-pointer"}>
                        <div
                            className={`flex flex-row items-center xl:h-12 2xl:h-14 xl:w-48 2xl:w-56 ${dropdown && 'bg-milano-red'}`}>
                            <div className={"flex w-1/4 items-center justify-center "}>
                                <img className={""}
                                     src={dropdown ? menu.iconOpen : menu.iconClose} alt="mission icon"/>
                            </div>
                            <p className={`w-3/4 capitalize ${dropdown ? 'text-white' : 'text-black'} font-FuturaBT-Medium text-tiny`}>{menu.menuLabel}</p>
                        </div>
                    </div> :
                    <Link to={`${menu.key}/redeem-point`}>
                        <div
                            className={`flex flex-row items-center xl:h-12 2xl:h-14 xl:w-48 2xl:w-56 ${dropdown && 'bg-milano-red'}`}>
                            <div className={"flex w-1/4 items-center justify-center "}>
                                <img className={""}
                                     src={dropdown ? menu.iconOpen : menu.iconClose} alt="mission icon"/>
                            </div>
                            <p className={`w-3/4 capitalize ${dropdown ? 'text-white' : 'text-black'} font-FuturaBT-Medium text-tiny`}>{menu.menuLabel}</p>
                        </div>
                    </Link>
            }
            {
                dropdown && menu.child.length > 0 &&
                <div className={"flex flex-col border p-4 space-y-2.5 bg-white"}>
                    {
                        menu.child.map((child) =>
                            <div key={child.key} className={"flex flex-col justify-center items-center"}
                                 onClick={() => dispatch({
                                     type: TOOLS_REDUCER,
                                     payload: {sideBarSelectedKey: child.key}
                                 })}>
                                <Link to={`${menu.key}/${child.key}`} className={"sidebar text-center"}>
                                    {child.label}
                                </Link>
                                {sideBarSelectedKey === child.key &&
                                    <div className={"w-16 h-0.5 bg-milano-red text-center"}/>}
                            </div>
                        )
                    }
                </div>
            }
        </div>
    )
}

export default SubMenuComponent

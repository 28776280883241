import React from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Export from "../../images/svg/exports.svg"
import {useSelector} from "react-redux";
import {formatPercent, totalCalculation} from "../../ui-util/general-variable";
import InfluencerItemComponent from "./influencer-item.component";

const ExportVerticalComponent = (props) => {
    const {socialMedia} = props
    const {campaignDetail, contentCampaign} = useSelector(state => state.campaignReducer)

    const campaignDetailTable = () => {
        const campaign = [{
            title: 'Campaign Name',
            key: 'title'
        }, {
            title: 'Campaign Description',
            key: 'description'
        }, {
            title: 'Start Date',
            key: 'campaign_start_date'
        }, {
            title: 'End Date',
            key: 'campaign_end_date'
        }]
        return (
            <>
                <thead>
                <tr>
                    <th>Information</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {
                    campaign.map((c, i) => (
                        <tr key={i}>
                            <td>{c.title}</td>
                            <td>{campaignDetail.data[c.key]}</td>
                        </tr>
                    ))
                }
                <tr></tr>
                <tr></tr>
                </tbody>
            </>
        )
    }

    const statReport = () => {
        const statisticReport = [{
            title: 'Total Influencer',
            key: 'influencer'
        }, {
            title: 'Total Post',
            key: 'posts'
        }, {
            title: 'Total Stories',
            key: 'stories'
        }, {
            title: 'Total Like',
            key: 'likes'
        }, {
            title: 'Total Comment',
            key: 'comments'
        }, {
            title: 'Total Engagement',
            key: 'engagement'
        }, {
            title: 'Total Est. Reach',
            key: 'esr'
        }, {
            title: 'Total Media Value',
            key: 'media_value'
        }]
        return (
            <>
                <thead>
                <tr>
                    <td>STATISTIC REPORT</td>
                </tr>
                </thead>

                <thead>
                <tr>
                    <th>Information</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {
                    statisticReport.map((s, i) => (
                        <tr key={i}>
                            <td>{s.title}</td>
                            <td>{totalCalculation(s.key, campaignDetail.calculation[s.key].total)}</td>
                        </tr>
                    ))
                }
                <tr></tr>
                <tr></tr>
                </tbody>
            </>
        )
    }

    const audienceAgeStats = (array) => {
        return (
            <>
                <thead>
                <tr>
                    <td>AUDIENCE AGE STATS</td>
                </tr>
                </thead>

                <thead>
                <tr>
                    <th>Age Range</th>
                    <th>Percentage</th>
                </tr>
                </thead>
                <tbody>
                {
                    array.map((s, i) => (
                        <tr key={i}>
                            <td>{s.x}</td>
                            <td>{s.y}%</td>
                        </tr>
                    ))
                }
                <tr></tr>
                <tr></tr>
                </tbody>
            </>
        )
    }

    const genderStats = () => {
        const gender = contentCampaign[socialMedia]?.aggregation.gender
        return (
            <>
                <thead>
                <tr>
                    <td>AUDIENCE GENDER STATS</td>
                </tr>
                </thead>

                <thead>
                <tr>
                    <th>Gender</th>
                    <th>Percentage</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Female</td>
                    <td>{formatPercent(gender[0], (gender[0] + gender[1]))}</td>
                </tr>
                <tr>
                    <td>Male</td>
                    <td>{formatPercent(gender[1], (gender[0] + gender[1]))}</td>
                </tr>
                <tr></tr>
                <tr></tr>
                </tbody>
            </>
        )
    }

    const audienceLoc = () => {
        const aggLoc = contentCampaign[socialMedia]?.aggLocations
        return (
            <>
                <thead>
                <tr>
                    <td>AUDIENCE LOCATION STATS</td>
                </tr>
                </thead>

                <thead>
                <tr>
                    <th>Country</th>
                    <th>Percentage</th>
                </tr>
                </thead>
                <tbody>
                {
                    aggLoc.map((loc, i) => (
                        <tr key={i}>
                            <td>{loc.x}</td>
                            <td>{loc.y}%</td>
                        </tr>
                    ))
                }
                <tr></tr>
                <tr></tr>
                </tbody>
            </>
        )
    }

    return (
        <div>
            <div className={"btn p-2 cursor-pointer "}>
                <img className={"img-4-responsive"} src={Export}/>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button font-size-resp"
                    table="table-to-xls"
                    filename="Instagram Campaign Report"
                    sheet="Instagram Campaign Report"
                    buttonText="Export Excel"
                />
            </div>
            <table id="table-to-xls" className={"hidden"}>
                <thead>
                <tr>
                    <td className={"font-bold"}>
                        CAMPAIGN REPORT INSTAGRAM - {campaignDetail?.data?.title}
                    </td>
                </tr>
                </thead>
                {campaignDetailTable()}
                {statReport()}
                {!!contentCampaign[socialMedia] && audienceAgeStats(contentCampaign[socialMedia]?.aggregation?.age)}
                {genderStats()}
                {audienceLoc()}
                {
                    contentCampaign[socialMedia].mediaReports.map(item => <InfluencerItemComponent item={item}/>)
                }
            </table>

        </div>
    )
}
export default ExportVerticalComponent

import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {addUserToCampaignApi, getCampaignListApi} from "../../api/campaign.api";
import {CAMPAIGN_REDUCER} from "../../reducers/campaign.reducer";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";

const AddToCampaignModal = () => {
    const dispatch = useDispatch()
    const {addToCampaignModalVisibility} = useSelector(state => state.modalReducer)
    const {selectedRowKeys} = useSelector(state => state.userRegisteredReducer)
    const {campaignList} = useSelector(state => state.campaignReducer)

    useEffect(() => {
        let requestBody = {
            start_date: '',
            end_date: '',
            keyword: '',
            timeline: 'approvement',
            status: 'Publish'
        }

        let params = {
            orderby: 'id',
            sort: 'desc',
            page: 1,
            size: 20
        }

        getCampaignListApi(requestBody, params)
            .then(response => {
                dispatch({type: CAMPAIGN_REDUCER, payload: {campaignList: response.rows}})
            })
            .catch(err => console.log(err.response))
    }, [])

    const closeModal = () => {
        dispatch({type: MODAL_REDUCER, payload: {addToCampaignModalVisibility: 'hidden'}})
    }

    const inviteUserToCampaign = (id) => {
        let requestBody = {
            user_ids: selectedRowKeys,
            campaign_ids: [id]
        }
        addUserToCampaignApi(requestBody)
            .then(response => {
                dispatch({
                    type: MODAL_REDUCER,
                    payload: {
                        addToCampaignModalVisibility: 'hidden',
                        display: 'block',
                        userRegisteredText: 'Successfully saved user',
                    }
                })
                dispatch({type: USER_REGISTERED_REDUCER, payload: {selectedRowKeys: [], selectedRows: []}})
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div
            className={`${addToCampaignModalVisibility} fixed flex z-50 left-0 top-0 w-full h-full justify-center items-center block bg-gray-900 bg-opacity-40`}>
            <div className="flex flex-col w-1/6 bg-white p-5 border h-1/2 overflow-scroll font-FuturaBT-Book">
                <button className={"flex justify-end"} onClick={() => closeModal()}>X</button>
                <div className={"flex flex-col justify-center items-center w-full space-y-5"}>
                    <p className={"font-bold text-lg"}>List Campaign</p>
                    {
                        campaignList.length > 0 ?
                            campaignList.map((item, index) => (
                                <button
                                    key={index}
                                    className={"flex flex-col items-center space-y-3 p-3 border-b border-gray-400 w-full"}
                                    onClick={() => inviteUserToCampaign(item.id)}
                                >
                                    <img src={item.thumbnail} className={"w-40 h-40"}/>
                                    <p>{item.title}</p>
                                </button>
                            )) :
                            <p className={"text-center text-sm"}>No data campaign, sorry</p>
                    }

                </div>
            </div>
        </div>
    )
}

export default AddToCampaignModal

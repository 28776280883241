import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {getContentResultCampaignApi} from "../../api/campaign.api";
import {CAMPAIGN_REDUCER} from "../../reducers/campaign.reducer";
import ReportCampaignDetailComponent from "./report-campaign-detail.component";
import ReportSocialMediaComponent from "./report-social-media.component";

const ReportCampaign = () => {
    const {id} = useParams();
    const dispatch = useDispatch()

    useEffect(() => {
        getContentResultCampaignApi(id)
            .then(response => {
                dispatch({type: CAMPAIGN_REDUCER, payload: {contentCampaign: response}})
            })
            .catch(err => console.log(err.response))
    }, [])

    return (
        <div className={"space-y-20-responsive"}>
            <ReportCampaignDetailComponent />
            <ReportSocialMediaComponent />
        </div>
    );
}

export default ReportCampaign

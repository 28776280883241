import React, {useState} from "react";
import Heart from "../../images/svg/icon-heart.svg";
import Comment from "../../images/svg/icon-comment.svg";
import Plane from "../../images/svg/icon-plane.svg";
import Bookmark from "../../images/svg/icon-bookmark.svg";
import Play from "../../images/svg/icon-play.svg";
import {useSelector} from "react-redux";
import {formatFollower, getSocialMediaText} from "../../ui-util/general-variable";
import {useLocation} from "react-router-dom";

const EngagementComponent = () => {
    const location = useLocation();
    const pathRaw = location.pathname.split('/')
    const {analyticsFacebookResult} = useSelector(state => state.analyticsReducer)
    const [engagementDetail] = useState([
            {
                label: 'Avg Like Ratio',
                key: 'avgReactionsRatio',
                followerFormat: false,
                icon: Heart
            }, {
                label: 'Avg Like Ratio',
                key: 'avgLikesRatio',
                followerFormat: false,
                icon: Heart
            }, {
                label: 'Avg Dislike Ratio',
                key: 'avgDislikesRatio',
                followerFormat: false,
                icon: Heart
            }, {
                label: 'Avg Comment Ratio',
                key: 'avgCommentsRatio',
                followerFormat: false,
                icon: Comment
            }, {
                label: 'Avg Views Ratio',
                key: 'avgViewsRatio',
                followerFormat: true,
                icon: Plane
            }, {
                label: 'Avg Shares Ratio',
                key: 'avgSharesRatio',
                followerFormat: false,
                icon: Plane
            }, {
                label: 'Total Like',
                key: 'totalLike',
                followerFormat: true,
                icon: Heart
            }, {
                label: 'Total View',
                key: 'totalView',
                followerFormat: true,
                icon: Heart
            }, {
                label: 'Total Comment',
                key: 'totalComment',
                followerFormat: true,
                icon: Comment
            }, {
                label: 'Total Share',
                key: 'totalShare',
                followerFormat: true,
                icon: Plane
            }, {
                label: 'Est. Reach',
                key: 'estimatedReach',
                followerFormat: true,
                icon: Bookmark
            }, {
                label: 'Est. Media Value',
                key: 'estimatedMediaValue',
                followerFormat: true,
                icon: Play
            }, {
                label: 'Est. Impression',
                key: 'estimatedImpressions',
                followerFormat: true,
                icon: Bookmark
            }
        ]
    )
    return (
        <div className={"border p-3 bg-no-repeat bg-right-bottom bg-70% bg-engagement-background"}>
            <div className={"flex flex-row items-center justify-between mb-3"}>
                <p className={"font-FuturaBT-Medium text-sm ml-5 font-semibold"}>Engagement</p>
                <div className={"flex flex-col group relative"}>
                    <p className="invisible bg-gray-200 absolute z-10 text-xxs group-hover:visible border border-gray-300 p-3 rounded bottom-6 w-40 right-0">
                        Metric about influencer's reach on {getSocialMediaText(pathRaw[4])}
                    </p>
                    <button
                        className="text-sm bg-gray-200 w-5 h-5 rounded-full text-gray-400 hover:text-black inline-block">?
                    </button>
                </div>
            </div>
            <div className={"flex flex-col justify-center item-center h-full"}>
                <div className={"grid grid-cols-4 gap-5 p-5 justify-center"}>
                    {
                        !!analyticsFacebookResult?.engagement &&
                        engagementDetail.map((item, index) => {
                            if (!!analyticsFacebookResult?.engagement[item.key]) {
                                return (
                                    <div className={"flex flex-col items-center space-y-1"} key={index}>
                                        <img src={item.icon} className={"w-10 h-10"}/>
                                        <p className={"text-xl"}>{item.followerFormat ? formatFollower(Math.round(analyticsFacebookResult?.engagement[item.key])) : analyticsFacebookResult?.engagement[item.key]}</p>
                                        <p className={"text-gray-400 text-center "}>{item.label}</p>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default EngagementComponent

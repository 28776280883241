import React, {useEffect} from "react";
import CampaignTabComponent from "./campaign-tab.component";
import CampaignFilterComponent from "./campaign-filter.component";
import CampaignListComponent from "./campaign-list.component";
import PaginationComponent from "../../components/pagination.component";
import {deleteCampaignApi, getCampaignListApi} from "../../api/campaign.api";
import {useDispatch, useSelector} from "react-redux";
import {CAMPAIGN_REDUCER} from "../../reducers/campaign.reducer";
import {FLUSH_SORTING, TOOLS_REDUCER} from "../../reducers/tools.reducer";
import DeleteConfirmationModal from "../../components/modal/delete-confirmation.modal";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import SuccessModal from "../../components/success.modal";
import {customDateToReadable} from "../../ui-util/general-variable";

const CampaignPage = () => {
    const dispatch = useDispatch()
    const {successModalText} = useSelector(state => state.modalReducer)
    const {activeTab, campaignIdToDelete, reload} = useSelector(state => state.campaignReducer)
    const {pageSize, currentPage, searchKey, sort, startDate, endDate, reloadDatePicker} = useSelector(state => state.toolsReducer)

    useEffect(() => {
        return () => {
            dispatch({type: FLUSH_SORTING})
            dispatch({type: CAMPAIGN_REDUCER, payload: {activeTab: 'upcoming'}})
        }
    }, [])

    useEffect(() => {
        let requestBody = {
            start_date: startDate && customDateToReadable(startDate, 'YYYY-MM-DD'),
            end_date: endDate && customDateToReadable(endDate, 'YYYY-MM-DD'),
            keyword: searchKey,
            timeline: activeTab,
            status: activeTab === 'draft' ? 'Draft' : 'Publish'
        }

        let params = {
            orderby: 'id',
            sort,
            page: currentPage,
            size: pageSize
        }

        getCampaignListApi(requestBody, params)
            .then(response => {
                dispatch({type: CAMPAIGN_REDUCER, payload: {campaignList: response.rows}})
                dispatch({type: TOOLS_REDUCER, payload: {totalData: response.total_data}})
            })
            .catch(err => console.log(err.response))
    }, [activeTab, sort, searchKey, pageSize, currentPage, reloadDatePicker, reload])

    const getCampaign = () => {
        let requestBody = {
            start_date: startDate && customDateToReadable(startDate, 'YYYY-MM-DD'),
            end_date: endDate && customDateToReadable(endDate, 'YYYY-MM-DD'),
            keyword: searchKey,
            timeline: activeTab,
            status: activeTab === 'draft' ? 'Draft' : 'Publish'
        }

        let params = {
            orderby: 'id',
            sort,
            page: currentPage,
            size: pageSize
        }

        getCampaignListApi(requestBody, params)
            .then(response => {
                dispatch({type: CAMPAIGN_REDUCER, payload: {campaignList: response.rows}})
            })
            .catch(err => console.log(err.response))
    }

    const deleteHandler = () => {
        deleteCampaignApi(campaignIdToDelete)
            .then(response => {
                getCampaign()
                dispatch({type: MODAL_REDUCER,
                    payload: {
                        deleteGroupModalVisibility: 'hidden',
                        display: 'block',
                        successModalText: "Campaign has been delete successfully!"
                    }
                })
            })
            .catch(err => console.log(err))
    }

    return (
        <>
            <CampaignTabComponent/>
            <CampaignFilterComponent/>
            <CampaignListComponent/>
            <PaginationComponent/>
            <DeleteConfirmationModal message={"Are you sure want to delete this campaign?"}
                                     deleteHandler={() => deleteHandler()}/>
            <SuccessModal message={successModalText}/>
        </>
    )
}

export default CampaignPage

import React, {useEffect} from "react";
import GroupFilterComponent from "./group-filter.component";
import GroupListComponent from "./group-list.component";
import PaginationComponent from "../../components/pagination.component";
import {deleteGroupApi, getGroupListApi} from "../../api/group.api";
import {useDispatch, useSelector} from "react-redux";
import {GROUP_REDUCER} from "../../reducers/group.reducer";
import {FLUSH_SORTING, TOOLS_REDUCER} from "../../reducers/tools.reducer";
import DeleteConfirmationModal from "../../components/modal/delete-confirmation.modal";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import SuccessModal from "../../components/success.modal";
import {customDateToReadable} from "../../ui-util/general-variable";

const GroupPage = () => {
    const dispatch = useDispatch()
    const {currentPage, pageSize, searchKey, sort, startDate, endDate, reloadDatePicker} = useSelector(state => state.toolsReducer)
    const {groupDetail, reload} = useSelector(state => state.groupReducer)

    useEffect(() => {
        return () => {
            dispatch({type: FLUSH_SORTING})
        }
    }, [])

    useEffect(() => {
        let requestBody = {
            keyword: searchKey,
            start_date: startDate && customDateToReadable(startDate, 'YYYY-MM-DD'),
            end_date: endDate && customDateToReadable(endDate, 'YYYY-MM-DD'),
        }
        let params = {
            page: currentPage,
            limit: pageSize,
            orderby: 'created_at',
            sort
        }
        getGroupListApi(requestBody, params)
            .then(response => {
                dispatch({type: GROUP_REDUCER, payload: {groupList: response.rows}})
                dispatch({type: TOOLS_REDUCER, payload: {totalData: response.total_data}})
            })
            .catch(err => console.log('Error getGroupListApi', err.response))
    }, [searchKey, sort, pageSize, currentPage, reload, reloadDatePicker])

    const deleteGroupHandler = () => {
        deleteGroupApi(groupDetail?.id)
            .then(response => {
                dispatch({type: GROUP_REDUCER, payload: {reload: !reload}})
                dispatch({type: MODAL_REDUCER, payload: {deleteGroupModalVisibility: 'hidden', display: 'block'}})
            })
            .catch(err => console.log('Error deleteGroupApi', err))
    }

    return (
        <>
            <GroupFilterComponent/>
            <GroupListComponent/>
            <PaginationComponent/>
            <DeleteConfirmationModal
                deleteHandler={deleteGroupHandler}
                message={"Are you sure want to delete this group?"}
            />
            <SuccessModal message={"Group has been deleted successfully"}/>
        </>
    )
}

export default GroupPage

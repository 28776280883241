const INITIAL_STATE = {
    is_potential_ig: null,
    userList: [],
    activeTab: 'Waiting Approval',
    orderby: 'id',
    reload: false,
    alertMessage: '',
    userDetailDraftContent: {},
    campaignDetail: {},
    contentDetail: {}
}

export const APPROVAL_CAMPAIGN_REDUCER = 'APPROVAL_CAMPAIGN_REDUCER';

export const approvalCampaignReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case APPROVAL_CAMPAIGN_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}

import React from "react";
import UserProfileComponent from "./user-profile.component";
import SocialMediaComponent from "./social-media.component";
import SuccessModal from "../../components/success.modal";
import {useSelector} from "react-redux";

const UserDetailPage = () => {
    const {userRegisteredText} = useSelector(state => state.modalReducer)

    return (
        <div className={"flex flex-col items-center"}>
            <div>
                <UserProfileComponent/>
                <SocialMediaComponent/>
            </div>
            <SuccessModal message={userRegisteredText}/>
        </div>
    )
}

export default UserDetailPage

import React from "react";
import Chart from "react-apexcharts";

const CircleChartComponent = (props) => {
    const {title, data, plotOptions, labels, type, distributeData, tooltip} = props
  return (
      <div className={"border p-3"}>
          <div className={"flex flex-row items-center justify-between mb-3"}>
              <p className={"font-FuturaBT-Medium text-sm ml-5 font-semibold"}>{title}</p>
              <div className={"flex flex-col group relative"}>
                  <p className="invisible bg-gray-200 absolute z-10 text-xxs group-hover:visible border border-gray-300 p-3 rounded bottom-6 w-40 right-0">
                      {tooltip}
                  </p>
                  <button
                      className="text-sm bg-gray-200 w-5 h-5 rounded-full text-gray-400 hover:text-black inline-block">?
                  </button>
              </div>
          </div>
          <div className={"flex flex-col items-center justify-center mt-5"}>
              <Chart
                  options={{
                      chart: {
                          type,
                      },
                      colors: ['#893930', '#E2E2E2'],
                      legend: {
                          show: false,
                      },
                      plotOptions,
                      stroke: {
                          width: 0,
                      },
                      dataLabels: {
                          formatter: (val) => `${Math.round(val)}%`,
                          style: {
                              fontFamily: "'Nunito Sans', sans-serif",
                              fontSize: '14px',
                              colors: ['#E2E2E2', '#953232'],
                          },
                          dropShadow: {
                              enabled: false,
                          }
                      },
                      tooltip: {
                          enabled: false,
                      },
                      labels
                  }}
                  className={"w-1/2"}
                  type={type}
                  series={data}
                  height={150}
                  width={120}
              />
              {distributeData}
          </div>
      </div>
  )
}

export default CircleChartComponent

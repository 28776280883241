import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import Reject from "../../images/svg/reject-white.svg";
import Approve from "../../images/svg/approve-white.svg";
import {getQuestionerResultApi} from "../../api/user-registered.api";

const QuestionerResultModal = () => {
    const dispatch = useDispatch()
    const {questionerResultModalVisibility} = useSelector(state => state.modalReducer)
    const {userDetail} = useSelector(state => state.userRegisteredReducer)
    const [questionerResultList, setQuestionerResultList] = useState([])
    const [correctAnswer, setCorrectAnswer] = useState(0)
    const [totalAnswer, setTotalAnswer] = useState(0)

    useEffect(() => {
        getQuestionerResultApi(userDetail.id)
            .then(response => {
                setQuestionerResultList(response.data)
                setTotalAnswer(response.total_answer)
                setCorrectAnswer(response.correct_answer)
            })
            .catch(err => {
                console.log(err)
            })
    }, [userDetail.id])

    const closeModal = () => {
        dispatch({type: MODAL_REDUCER, payload: {questionerResultModalVisibility: 'hidden'}})
    }

    return (
        <div
            className={`${questionerResultModalVisibility} fixed flex left-0 top-0 w-full h-full justify-center items-center block bg-gray-900 bg-opacity-40 py-10 z-50`}>
            <div
                className=" w-1/5 flex flex-col bg-white py-5 px-8 border font-FuturaBT-Book max-h-full overflow-scroll">
                <button className={"flex justify-end text-xl text-gray-400"} onClick={() => closeModal()}>x</button>
                <div className={"flex flex-col w-full h-full "}>
                    <p className={"text-milano-red text-2xl font-FuturaBT-Medium"}>Submitted Data</p>
                    <div
                        className={"bg-mintcream border border-green-leaf px-3 flex flex-row justify-between text-tiny mt-5 text-green-leaf items-center"}>
                        <p className={""}>Correct Answer</p>
                        <div className={"flex flex-row items-center"}>
                            <p className={"text-3xl"}>{correctAnswer}</p>
                            <p>/{totalAnswer}</p>
                        </div>
                    </div>
                    {
                        questionerResultList.map((item, index) => (
                            <div className={"flex flex-row mt-10 text-sm"} key={index}>
                                <div className={"mr-5"}>
                                    <div
                                        className={"bg-milano-red text-white w-5 h-5 rounded-full flex flex-row items-center justify-center"}>
                                        <p>{index + 1}</p>
                                    </div>
                                </div>
                                <div className={"flex flex-col space-y-3"}>
                                    <p className={"font-FuturaBT-Medium"}>{item.question}</p>
                                    <img src={item.image}/>
                                    <div>
                                        <p className={"text-gray-400"}>Answer :</p>
                                        {
                                            item?.answer?.type === 'image' ?
                                                <img src={item?.answer?.label} className={"w-14"}/> :
                                                <p>{item?.answer?.label}</p>
                                        }
                                    </div>
                                    {
                                        item?.answer?.is_correct === 0 ?
                                            <div className={"flex flex-row items-center space-x-2"}>
                                                <div className={"bg-milano-red rounded-full w-4 h-4"}>
                                                    <img src={Reject}/>
                                                </div>
                                                <p className={"text-xs text-milano-red"}>Incorrect</p>
                                            </div> :
                                            <div className={"flex flex-row items-center space-x-2"}>
                                                <div className={"bg-green-leaf rounded-full w-4 h-4"}>
                                                    <img src={Approve}/>
                                                </div>
                                                <p className={"text-xs text-green-leaf"}>Correct</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default QuestionerResultModal

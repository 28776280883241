import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const getBannerListApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v4/admin/banner-goddest`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const updateBannerApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/banner-goddest`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const updateStatusBannerApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/banner-goddest/status`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const deleteBannerApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/banner-goddest/delete`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

export {
    getBannerListApi,
    updateBannerApi,
    updateStatusBannerApi,
    deleteBannerApi
}

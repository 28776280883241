import React, {useEffect, useState} from "react";
import HeaderComponent from "./header.component";
import SidebarComponent from "./sidebar.component";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getDetailAdminApi} from "../api/user.api";
import {TOOLS_REDUCER} from "../reducers/tools.reducer";
import LandingPage from "../pages/landing";

const DashboardComponent = (props) => {
    const {content} = props
    const dispatch = useDispatch()
    const location = useLocation();
    const pathRaw = location.pathname.split('/')
    const [subMenu, setSubMenu] = useState('')
    const {adminDetail} = useSelector(state => state.toolsReducer)

    useEffect(() => {
        setSubMenu(pathRaw[3])
    }, [pathRaw])

    useEffect(() => {
        getDetailAdminApi()
            .then(result => {
                dispatch({type: TOOLS_REDUCER, payload: {adminDetail: result}})
            })
            .catch(err => {
                console.log('router', err)
                if (localStorage.getItem('TOKEN_AUTH')) {
                    dispatch({type: TOOLS_REDUCER, payload: {adminDetail: {}}})
                    localStorage.removeItem('TOKEN_AUTH')
                    window.location.reload()
                    alert('Token expired, mohon login ulang!')
                }
            })
    }, [])

    const hideSidebar = () => {
      switch (subMenu) {
          case 'user-detail':
          case 'detail-campaign':
          case 'redeem-detail':
              return true
              break;
          default:
              return false
      }
    }

    return (
        <div className={"flex flex-row"}>
            {
                !!adminDetail?.full_name ?
                    <>
                        {!hideSidebar() && <SidebarComponent/>}
                        <div className={`flex-1 flex-col h-full ${!hideSidebar() && 'ml-300px'}`}>
                            <div className={"w-full flex-1 flex-row items-center sticky top-0 z-30"}>
                                <HeaderComponent/>
                            </div>
                            <div className={"flex-1 flex-col px-14 overflow-auto pt-5"}>
                                {content}
                            </div>
                        </div>
                    </> :
                    <LandingPage />
            }
        </div>
    )
}

export default DashboardComponent

import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {MODAL_REDUCER} from "../reducers/modal.reducer";

const ModalComponent = (props) => {
    const dispatch = useDispatch()
    const {display} = useSelector(state => state.modalReducer)

    const closeModal = () => {
        dispatch({type: MODAL_REDUCER, payload: {display: 'hidden'}})
    }

    return (
        <div
            className={`${display} fixed flex left-0 top-0 w-full h-full justify-center items-center block bg-gray-900 bg-opacity-40 z-50`}>
            <div className="flex flex-col w-2/6 bg-white p-5 border ">
                <button className={"flex justify-end"} onClick={() => closeModal()}>X</button>
                <div className={"flex flex-col justify-center items-center w-full h-full"}>
                    {props.children}
                </div>
            </div>

        </div>
    )
}

export default ModalComponent

const INITIAL_STATE = {
    timelineList: [],
    reload: false
}

export const TIMELINE_REDUCER = 'TIMELINE_REDUCER';

export const timelineReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TIMELINE_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}

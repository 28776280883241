import React, {useState} from "react";
import ArrowDown from "../../images/svg/arrow-down.svg";
import ArrowUp from "../../images/svg/arrow-up.svg";
import LineChartComponent from "../../components/line-chart.component";
import {useSelector} from "react-redux";

const TimelineComponent = () => {
    const {analyticsFacebookResult} = useSelector(state => state.analyticsReducer)
    const [isHide, setIsHide] = useState(false)

    return (
        <div className={"font-FuturaBT-Book"}>
            <div
                onClick={() => setIsHide(!isHide)}
                className={"border-b flex flex-row items-center justify-between text-milano-red text-sm font-FuturaBT-Medium mt-5 pb-2 hover:cursor-pointer"}>
                <p>Timeline</p>
                <div className={"flex flex-row items-center space-x-2"}>
                    <p className={"text-black"}>Hide</p>
                    <img src={isHide ? ArrowDown : ArrowUp} className={"w-2 h-2"}/>
                </div>
            </div>
            {
                !isHide &&
                <div className={"grid grid-cols-2 mt-5 gap-5"}>
                    {
                        !!analyticsFacebookResult?.followers_timeseries &&
                        <LineChartComponent
                            data={[analyticsFacebookResult?.followers_timeseries]}
                            title={'Followers Timeline'}
                            tooltip={'Subscriber timeline of influencer\'s engaged followers'}
                        />
                    }
                    {
                        !!analyticsFacebookResult?.engagement_timeseries &&
                        <LineChartComponent
                            data={analyticsFacebookResult?.engagement_timeseries}
                            title={'Engagement Timeline'}
                            tooltip={'Engagement timeline of influencer\'s engaged followers'}
                        />

                    }
                </div>
            }
        </div>
    )
}

export default TimelineComponent

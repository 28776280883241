import React, {useEffect, useState} from "react";
import TableComponent from "../../components/table.component";
import {approvalUserToEnterCampaignApi, userListCampaignApi} from "../../api/campaign.api";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {APPROVAL_CAMPAIGN_REDUCER} from "../../reducers/approval-campaign.reducer";
import PotentialInfluencer from "../../images/svg/potential-influencer.svg";
import Minus from "../../images/svg/ico-minus-grey.svg";
import InstagramGrey from "../../images/svg/insta-outline-grey.svg";
import InstagramRed from "../../images/svg/insta-outline.svg";
import FacebookGrey from "../../images/svg/facebook-outline-grey.svg";
import FacebookRed from "../../images/svg/facebook-outline.svg";
import TiktokGrey from "../../images/svg/tiktok-outline-grey.svg";
import TiktokRed from "../../images/svg/tiktok-outline.svg";
import YoutubeGrey from "../../images/svg/youtube-grey.svg";
import YoutubeRed from "../../images/svg/youtube.svg";
import {formatDateToReadable} from "../../ui-util/general-variable";
import Approve from "../../images/svg/approve-green.svg";
import Reject from "../../images/svg/reject-red.svg";
import Home from "../../images/svg/ico-home.svg";
import HomeBlack from "../../images/svg/ico-home-black.svg";
import SendProduct from "../../images/svg/ico-send-product-red.svg";
import DraftContent from "../../images/svg/ico-draft.svg";
import PublishContent from "../../images/svg/sidebar-mission.svg";
import {TOOLS_REDUCER} from "../../reducers/tools.reducer";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";

const UserListComponent = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const {
        activeTab,
        is_potential_ig,
        userList,
        orderby,
        reload
    } = useSelector(state => state.approvalCampaignReducer)
    const {pageSize, currentPage, sort, searchKey} = useSelector(state => state.toolsReducer)
    const [socialMediaList] = useState([
        {
            key: 'instagram_task',
            iconRed: InstagramRed,
            iconGrey: InstagramGrey
        }, {
            key: 'facebook_task',
            iconGrey: FacebookGrey,
            iconRed: FacebookRed
        }, {
            key: 'tiktok_task',
            iconGrey: TiktokGrey,
            iconRed: TiktokRed
        }, {
            key: 'youtube_task',
            iconGrey: YoutubeGrey,
            iconRed: YoutubeRed
        }
    ])

    const [columnsSendProduct] = useState([
        {
            type: 'checkbox',
            isRightBorderAvailable: true,
        }, {
            name: 'User',
            render: (item) => profileUser(item)
        }, {
            name: 'Nama Product',
            render: (record) => (
                <ul className={"text-center"}>
                    {
                        record?.campaign_users[0]?.campaign_products?.length > 0 &&
                        record?.campaign_users[0]?.campaign_products.map((item, index) => (
                            <li key={index} className={"text-sm"}>{item?.product.name}</li>
                        ))
                    }
                </ul>
            )
        }, {
            name: 'No. Telp',
            dataIndex: 'phone',
            render: (record, phone) => <p className={"font-base-resp text-center"}>{phone}</p>
        },
        {
            name: 'Total Point',
            dataIndex: 'point',
            render: (record, point) => <p className={"font-base-resp text-center"}>{point} Point</p>
        }, {
            name: 'Tgl Join Campaign',
            dataIndex: 'created_at',
            render: (record, created_at) => <p
                className={"font-base-resp text-center"}>{formatDateToReadable(created_at)}</p>
        }, {
            name: 'Detail Alamat',
            render: (record) => {
                if (!!record.campaign_user_addresses) {
                    return (
                        <div className={"flex items-center justify-center"}>
                            <div className={"flex flex-row items-center space-x-2 w-44"}>
                                <img src={HomeBlack} className={"w-4 h-4"}/>
                                <p className={"text-tiny"}>{record?.campaign_user_addresses[0].address}, {record?.campaign_user_addresses[0].district}, {record?.campaign_user_addresses[0].city}, {record?.campaign_user_addresses[0].province}, {record?.campaign_user_addresses[0].post_code}.
                                    Notes : {record?.campaign_user_addresses[0].description}</p>
                            </div>
                        </div>
                    )
                }

            }
        }, {
            name: 'Status',
            render: (record) => (
                <div className={"flex flex-row items-center space-x-2 justify-center"}>
                    <img className={"w-6 h-6"} src={SendProduct}/>
                    <p className={"font-FuturaBT-Medium text-milano-red text-tiny"}>{record?.campaign_users[0].status}</p>
                </div>
            )
        },
    ])
    const [columns] = useState([
        {
            type: 'checkbox',
            isRightBorderAvailable: true,
        }, {
            name: 'User',
            dataIndex: 'full_name',
            render: (item) => profileUser(item)
        }, {
            name: 'Total Point',
            dataIndex: 'point',
            render: (record, point) => (
                <p className={"font-base-resp text-center"}>{point} Point</p>
            )
        }, {
            name: 'Potential Influencer',
            dataIndex: 'is_potential',
            render: (record, is_potential) => (
                <div className={"flex flex-row items-center justify-center space-x-4"}>
                    {
                        socialMediaList.map((item, index) => (
                            <div className={"space-y-3"} key={index}>
                                <img className={"w-6 h-6"}
                                     src={(item.key === 'instagram_task' && is_potential) ? item.iconRed : item.iconGrey}/>
                                <img className={"w-6 h-6"}
                                     src={(item.key === 'instagram_task' && is_potential) ? PotentialInfluencer : Minus}/>
                            </div>
                        ))
                    }
                </div>
            )
        }, {
            name: 'Tgl Join Campaign',
            dataIndex: 'created_at',
            render: (record, created_at) => <p
                className={"font-base-resp text-center"}>{formatDateToReadable(created_at)}</p>
        }, {
            name: 'Status',
            render: (record) => statusActionButton(record)
        },
    ])

    const [columnsDraftContent] = useState([
        {
            type: 'checkbox',
            isRightBorderAvailable: true,
        }, {
            name: 'User',
            render: (item) => profileUser(item)
        }, {
            name: 'Total Point',
            dataIndex: 'point',
            render: (record, point) => <p className={"font-base-resp text-center"}>{point} Point</p>
        }, {
            name: 'Submitted Content',
            render: (record) => submittedContent(record)
        }, {
            name: 'Tgl Join Campaign',
            dataIndex: 'created_at',
            render: (record, created_at) => <p
                className={"font-base-resp text-center"}>{formatDateToReadable(created_at)}</p>
        }, {
            name: 'Status',
            render: (record) => {
                return (
                    <div className={"flex flex-row items-center space-x-2 justify-center"}>
                        <img className={"w-6 h-6"} src={DraftContent}/>
                        <p className={"font-FuturaBT-Medium text-milano-red text-tiny"}>List Draft Content</p>
                    </div>
                )
            }
        },
    ])

    const [columnsPublishContent] = useState([
        {
            type: 'checkbox',
            isRightBorderAvailable: true,
        }, {
            name: 'User',
            render: (item) => profileUser(item)
        }, {
            name: 'Total Point',
            dataIndex: 'point',
            render: (record, point) => <p className={"font-base-resp text-center"}>{point} Point</p>
        }, {
            name: 'Published Content',
            render: (record) => submittedContent(record)
        }, {
            name: 'Tgl Join Campaign',
            dataIndex: 'created_at',
            render: (record, created_at) => <p
                className={"font-base-resp text-center"}>{formatDateToReadable(created_at)}</p>
        }, {
            name: 'Status',
            render: (record) => {
                return (
                    <div className={"flex flex-row items-center space-x-2 justify-center"}>
                        <img className={"w-6 h-6"} src={PublishContent}/>
                        <p className={"font-FuturaBT-Medium text-tiny"}>Detail Content</p>
                    </div>
                )
            }
        },
    ])

    const submittedContent = (record) => {
        if (!!record?.campaign_user_submits) {
            return (
                <div className={"flex flex-row items-center justify-center space-x-4"}>
                    {
                        record?.campaign_user_submits.map((i, idx) => {
                            return (
                                <div key={idx}>
                                    {
                                        socialMediaList.map((item, index) => {
                                            if (item.key === i.task_name) {
                                                return (
                                                    <button className={"space-y-3"} key={index}
                                                            onClick={() => {
                                                                dispatch({
                                                                    type: MODAL_REDUCER,
                                                                    payload: {draftContentModalVisibility: 'block'}
                                                                })
                                                                dispatch({
                                                                    type: APPROVAL_CAMPAIGN_REDUCER,
                                                                    payload: {
                                                                        userDetailDraftContent: record,
                                                                        contentDetail: i
                                                                    }
                                                                })
                                                            }}
                                                    >
                                                        <img className={"w-6 h-6"}
                                                             src={item.iconRed}/>
                                                        <img className={"w-6 h-6"}
                                                             src={PotentialInfluencer}/>
                                                    </button>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
    }

    const profileUser = (item) => {
        return (
            <Link className={"flex flex-row items-center hover:text-milano-red"}
                  to={`/goddess-gang/gg/user-detail/${item.id}`}>
                <img className={"mx-1 w-14 h-14 rounded-full"} src={item.photo}/>
                <div>
                    <div className={"flex flex-row items-center"}>
                        <p className={"font-large-resp font-FuturaBT-Medium"}>{item.full_name}</p>
                        {item.is_potential && <img className={"h-5 w-5 ml-1"} src={PotentialInfluencer}/>}
                    </div>
                    <p className={"text-sm"}>{formatDateToReadable(item.birth_date)}</p>
                    <p className={"text-tiny"}>{item.email}</p>
                </div>
            </Link>
        )
    }

    const approvalUserList = (status, item) => {
        let requestBody = {
            status,
            user_ids: [item.id]
        }

        approvalUserToEnterCampaignApi(id, requestBody)
            .then(response => {
                let message = status === 'Reject' ? 'Successfully reject user' : 'Successfully approve user'
                dispatch({type: APPROVAL_CAMPAIGN_REDUCER, payload: {reload: !reload, alertMessage: message}})
                dispatch({type: MODAL_REDUCER, payload: {display: 'block'}})
                dispatch({type: USER_REGISTERED_REDUCER, payload: {selectedRowKeys: [], selectedRows: []}})
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const statusActionButton = (item) => {
        switch (item.campaign_users[0].status) {
            case 'Approve':
                return (
                    <div className={"flex flex-row items-center space-x-2 justify-center"}>
                        <img src={Home} className={"w-5 h-5"}/>
                        <p className={"font-FuturaBT-Medium text-gray-500"}>Approve</p>
                    </div>
                )
                break;
            default:
                return (
                    <div className={"flex items-center justify-center"}>
                        <div className={"flex-col"}>
                            <button className={"flex flex-row items-center"}
                                    onClick={() => approvalUserList('Approve', item)}>
                                <img className={"w-10 h-10"} src={Approve}/>
                                <p>Approve</p>
                            </button>
                            <button className={"flex flex-row items-center"}
                                    onClick={() => approvalUserList('Reject', item)}>
                                <img className={"w-10 h-10"} src={Reject}/>
                                <p>Reject</p>
                            </button>
                        </div>
                    </div>
                )
                break;
        }
    }

    useEffect(() => {
        let requestBody = {
            status: activeTab,
            keyword: searchKey,
            start_date: '',
            is_potential_ig
        }
        let params = {
            orderby,
            sort,
            page: currentPage,
            limit: pageSize
        }

        userListCampaignApi(id, requestBody, params)
            .then(response => {
                dispatch({type: APPROVAL_CAMPAIGN_REDUCER, payload: {userList: response.rows}})
                dispatch({type: TOOLS_REDUCER, payload: {totalData: response.total_data}})
            })
            .catch(err => {
                console.log(err.response)
            })
    }, [activeTab, is_potential_ig, searchKey, currentPage, pageSize, reload])

    const defineColumns = () => {
        switch (activeTab) {
            case 'Send Product':
                return columnsSendProduct
                break;
            case 'Draft Konten':
                return columnsDraftContent
                break;
            case 'Publish Konten':
                return columnsPublishContent
                break;
            default:
                return columns
        }
    }

    return (
        <TableComponent
            columns={defineColumns()}
            dataSource={userList}
        />
    )
}

export default UserListComponent

import React from "react";
import Chart from "react-apexcharts";
import {useSelector} from "react-redux";

const BarChartHorizontalComponent = () => {
    const {analyticsFacebookResult} = useSelector(state => state.analyticsReducer)

    return (
        <div className={"border p-3 w-full"}>
            <div className={"flex flex-row items-center justify-between mb-3"}>
                <p className={"font-FuturaBT-Medium text-sm ml-3 font-semibold"}>Audience Interest Distribution</p>
                <div className={"flex flex-col group relative"}>
                    <p className="invisible bg-gray-200 absolute z-10 text-xxs group-hover:visible border border-gray-300 p-3 rounded bottom-6 w-40 right-0">
                        Top interests of influencer's followers
                    </p>
                    <button
                        className="text-sm bg-gray-200 w-5 h-5 rounded-full text-gray-400 hover:text-black inline-block">?
                    </button>
                </div>
            </div>
            <p className={"text-xxs ml-3"}>Primary Influencer Related Interest</p>
            <div className={"py-4 border-b border-gray-100 flex flex-row space-x-2 ml-3"}>
                {
                    !!analyticsFacebookResult?.interest &&
                    analyticsFacebookResult?.interest.map((item, index) => (
                        <div className={"text-xxs bg-linen text-milano-red px-5 py-2 font-bold"}
                             key={index}>{item}</div>
                    ))
                }
            </div>
            <Chart
                type="bar"
                options={{
                    chart: {
                        toolbar: {
                            export: {
                                csv: {
                                    filename: 'Age Distribution - ' + analyticsFacebookResult?.username,
                                },
                                svg: {
                                    filename: 'Age Distribution - ' + analyticsFacebookResult?.username,
                                },
                                png: {
                                    filename: 'Age Distribution - ' + analyticsFacebookResult?.username,
                                }
                            }
                        }
                    },
                    tooltip: {
                        y: {show: false}
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '40%',
                            horizontal: true
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        labels: {
                            style: {
                                colors: '#626262',
                                fontFamily: "'FuturaBT-Book'",
                                fontSize: 10,
                            },
                            formatter: function (value) {
                                return (Math.ceil(value)).toFixed(0) + "%";
                            },
                        }
                    },
                    yaxis: {
                        labels: {
                            style: {
                                colors: '#626262',
                                fontFamily: "'FuturaBT-Book'",
                                fontSize: 10,
                            }
                        }
                    },
                }}

                series={[
                    {
                        name: "Jumlah",
                        data: analyticsFacebookResult?.audience_interest_distribution
                    }
                ]}
            />
        </div>
    )
}

export default BarChartHorizontalComponent

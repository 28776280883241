import React, {useState} from "react";
import Filter from "../../images/svg/ico-filter.svg";
import Reset from "../../images/svg/reset.svg";
import CheckGrey from "../../images/svg/ico-check-grey.svg";
import CheckRed from "../../images/svg/ico-check-red.svg";
import {useDispatch, useSelector} from "react-redux";
import {REDEEM_REDUCER} from "../../reducers/redeem.reducer";

const StatusFilterComponent = () => {
    const dispatch = useDispatch()
    const {status} = useSelector(state => state.redeemReducer)
    const [isVisible, setIsVisible] = useState(false)
    const [statusList] = useState([
        {
            label: 'Waiting Approval',
            key: 'Waiting Approval'
        }, {
            label: 'Success',
            key: 'Approve'
        }, {
            label: 'Reject',
            key: 'Reject'
        }])

    const statusClickedHandler = (statusText) => {
        if(status.length > 0){
            dispatch({type: REDEEM_REDUCER, payload: {status: ''}})
        } else {
            dispatch({type: REDEEM_REDUCER, payload: {status: statusText}})
        }
        setIsVisible(false)
    }

    return (
        <div className={"font-FuturaBT-Book"}>
            <button className={"border h-8 w-8 flex items-center justify-center"} onClick={() => setIsVisible(!isVisible)}>
                <img className={"w-5 h-5"} src={Filter} alt={'filter'}/>
            </button>
            <div className={` ${isVisible ? 'visible' : 'invisible'} absolute border mt-2 p-2 space-y-5 bg-white`}>
                <div className={"flex flex-row justify-between"}>
                    <p className={"text-sm "}>Status</p>
                    <div className={"flex flex-row items-center hover:cursor-pointer"}
                         onClick={() => dispatch({type: REDEEM_REDUCER, payload: {status: ''}}) }>
                        <img className={"w-3 h-3"} src={Reset} id={"reset"}/>
                        <label className={"text-sm text-gray-400 hover:cursor-pointer"} htmlFor={"reset"}>Reset</label>
                    </div>
                </div>
                <div className={"flex flex-row items-center space-x-2 text-sm text-gray-400"}>
                {
                    statusList.map((item, index) => (
                        <button key={index}
                            className={`rounded-full border px-1 flex flex-row items-center justify-between space-x-2`}
                            onClick={() => statusClickedHandler(item.key)}>
                            <p className={`text-sm ${status === item.key ? 'text-milano-red' : 'text-gray-400'}`}>
                                {item.label}
                            </p>
                            <img className={"w-3 h-3"} src={status === item.key ? CheckRed : CheckGrey}/>
                        </button>
                    ))
                }
                </div>
            </div>
        </div>
    )
}

export default StatusFilterComponent

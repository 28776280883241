import React, {useEffect, useState} from "react";
import DatePickerComponent from "../../components/date-picker.component";
import ExportComponent from "../../components/export.component";
import Instagram from "../../images/svg/insta-outline.svg"
import Facebook from "../../images/svg/facebook-outline.svg"
import TikTok from "../../images/svg/tiktok-outline.svg"
import {getContentFromCampaignApi} from "../../api/campaign.api";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CAMPAIGN_REDUCER} from "../../reducers/campaign.reducer";
import {customDateToReadable} from "../../ui-util/general-variable";
import InputTagComponent from "../../components/input-tag.component";
import Export from "../../images/svg/exports.svg";

const ContentComponent = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const {contentCampaign, mention, hashtag} = useSelector(state => state.campaignReducer)
    const {startDate, endDate, reloadDatePicker} = useSelector(state => state.toolsReducer)
    const [contentKey, setContentKey] = useState('instagram')
    const [contentList] = useState([
        {
            key: 'instagram',
            label: 'Instagram',
            logo: Instagram
        }, {
            key: 'facebook',
            label: 'Facebook',
            logo: Facebook
        }, {
            key: 'tiktok',
            label: 'TikTok',
            logo: TikTok
        }
    ])

    useEffect(() => {
        let requestBody = {
            start_date: !!startDate ? customDateToReadable(startDate, 'YYYY-MM-DD') : "",
            end_date: !!endDate ? customDateToReadable(endDate, 'YYYY-MM-DD') : "",
            hashtag,
            mention
        }

        getContentFromCampaignApi(id, requestBody)
            .then(response => {
                dispatch({type: CAMPAIGN_REDUCER, payload: {contentCampaign: response}})
            })
            .catch(err => console.log(err.response))

    }, [mention, hashtag, reloadDatePicker])

    return (
        <div className={"mt-5 font-FuturaBT-Book py-10"}>
            <div className={"flex flex-row justify-between text-sm items-end"}>
                <div>
                    <p className={"font-bold mb-1"}>Start Date - End Date</p>
                    <DatePickerComponent/>
                </div>
                <button onClick={() => window.print()} className={"btn height-resp p-2 cursor-pointer "}>
                    <img className={"filter-icon-resp"} src={Export}/>
                    <p>Export Pdf</p>
                </button>
            </div>
            <div className={"border p-10 mt-10"}>
                <div className={"flex flex-row items-center justify-center space-x-10"}>
                    {
                        contentList.map((item, index) => (
                            <button key={index}
                                 className={`flex flex-row items-center space-x-2 ${item.key === contentKey && 'border-milano-red border-b-2'} pb-3`}
                                    onClick={() => setContentKey(item.key)}
                            >
                                <img src={item.logo} className={"w-6 h-6"}/>
                                <p className={"text-lg"}>{item.label}</p>
                            </button>
                        ))
                    }
                </div>
                <div className={"flex flex-row-reverse"}>
                    <div className={"flex flex-row space-x-5"}>
                    <InputTagComponent type={"mention"}/>
                    <InputTagComponent type={"hashtag"}/>
                    </div>
                </div>
                <div className={"py-10 grid grid-cols-5 gap-10"}>
                    {
                        !!contentCampaign[contentKey]?.mediaReports &&
                        contentCampaign[contentKey]?.mediaReports.map((item, index) => (
                                <iframe key={index} src={item.media.urlThumbnail.replace(`media/?size=t`, 'embed')}
                                        width="224" height="500" frameBorder="0"/>
                            ))
                    }
                </div>
                {
                    !!(contentCampaign[contentKey]?.mediaReports?.length === 0) &&
                    <p className={"text-center text-milano-red"}>No data</p>
                }
                {!contentCampaign[contentKey]?.mediaReports && <p className={"text-center text-gray-400"}>No Data</p>}
            </div>
        </div>
    )
}

export default ContentComponent

import React from "react";
import Search from "../images/svg/ico-search.svg";
import {useDispatch} from "react-redux";
import {TOOLS_REDUCER} from "../reducers/tools.reducer";

const SearchComponent = (props) => {
    const {placeholder} = props
    const dispatch = useDispatch()

    const onChangeInput = (e) => {
      dispatch({type: TOOLS_REDUCER, payload: {searchKey: e.target.value}})
    }

    return (
        <div
            className={"flex flex-row items-center justify-between border border-b-gray-200 height-resp px-1 xl:w-24 2xl:w-26"}>
            <img className={"filter-icon-resp"} src={Search} alt={'search'}/>
            <input placeholder={placeholder} onChange={onChangeInput} className={"border-0 outline-0 font-size-resp xl:w-16 2xl:w-20"}/>
        </div>
    )
}

export default SearchComponent

const INITIAL_STATE = {
    display: 'hidden',
    draftContentModalVisibility: 'hidden',
    sendNotificationModalVisibility: 'hidden',
    userRegisteredText: '',
    questionerResultModalVisibility: 'hidden',
    deleteGroupModalVisibility: 'hidden',
    addToCampaignModalVisibility: 'hidden',
    standardModalVisibility: 'hidden',
    messageDeleteBanner: '',
    successModalText: ''
}

export const MODAL_REDUCER = 'MODAL_REDUCER';

export const modalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODAL_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}

import moment from "moment";

const idLocale = require('moment/locale/id');

const cloudFunctionBaseUrl = () => {
    return process.env.REACT_APP_BASE_URL
}

const formatFollower = (value) => {
    const COUNT_FORMATS =
        [
            { // 0 - 999
                letter: '',
                limit: 1e3
            },
            { // 1,000 - 999,999
                letter: 'K',
                limit: 1e6
            },
            { // 1,000,000 - 999,999,999
                letter: 'M',
                limit: 1e9
            },
            { // 1,000,000,000 - 999,999,999,999
                letter: 'B',
                limit: 1e12
            },
            { // 1,000,000,000,000 - 999,999,999,999,999
                letter: 'T',
                limit: 1e18
            }
        ];
    const format = COUNT_FORMATS.find(format => {
        return value < format.limit
    });

    value = (1000 * value / format.limit);
    value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

    return (value + format.letter);
}

const formatDateToReadable = (date) => {
    moment().locale()
    return moment(date).format("DD MMMM YYYY")
}

const customDateToReadable = (date, format) => {
    moment.locale('id', idLocale);
    return moment(date).format(format)
}

const formatAgeAndGender = (text) => {
    let newText = text.split(',')
    let data = newText[0]
    let description = newText[1]
    return {data, description}
}

const formatPercent = (value, total) => {
    let percent = `${Math.round((value / total) * 100)} %`

    return percent
}

const totalCalculation = (key, value) => {
    switch (key) {
        case 'engagement':
            return `${(value * 100).toFixed(2)}%`
            break;
        case 'media_value':
            return `${doConvertNumberToRupiahFormat(value)}`
            break;
        case 'likes':
        case 'comments':
        case 'esr':
        case 'followerCount':
        case 'follower':
            return `${formatFollower(value)}`
            break;
        case 'takenAt':
            return `${customDateToReadable(value, 'LLLL')}`
        break;
        default:
            return value
    }
}

const doConvertNumberToRupiahFormat = (numberToFormat) => {
    let separator = null;
    numberToFormat = Number(numberToFormat);
    const numberStartWith = numberToFormat.toString().split('', 1);
    if (numberStartWith == '-') {
        numberToFormat = numberToFormat.toString().slice(1);
    }
    let number_string = numberToFormat.toString(),
        split = number_string.split(','),
        remainder = split[0].length % 3,
        rupiah = split[0].substr(0, remainder),
        thousands = split[0].substr(remainder).match(/\d{1,3}/gi);
    if (thousands) {
        separator = remainder ? '.' : '';
        rupiah += separator + thousands.join('.');
    }
    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;

    if (numberStartWith == '-') {
        return 'Rp-' + rupiah;
    }
    return 'Rp' + rupiah;
};

const getSocialMediaText = (socialMedia) => {
    switch (socialMedia) {
        case "1":
            return 'Instagram';
            break;
        case "2":
            return 'Facebook'
            break;
        case "5":
            return 'Youtube'
            break;
        case "4":
            return 'Tiktok'
            break;
    }
}

const changeSequenceList = (arrayList) => {
    arrayList.map((item, index) => {
        item.sequence = index + 1
        return item
    })
    return arrayList
}

export {
    cloudFunctionBaseUrl,
    formatFollower,
    formatDateToReadable,
    formatAgeAndGender,
    customDateToReadable,
    formatPercent,
    totalCalculation,
    doConvertNumberToRupiahFormat,
    getSocialMediaText,
    changeSequenceList
}

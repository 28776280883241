import React from "react";
import ReactQuill from "react-quill";

const RtfComponent = (props) => {
    const {value, onChange} = props
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
        ],
    }

    return (
        <ReactQuill
            theme="snow"
            modules={modules}
            value={value}
            className={"w-3/4 h-32"}
            onChange={onChange}/>
    )
}

export default RtfComponent

import React, {useEffect} from "react";
import {deleteBannerApi, getBannerListApi, updateStatusBannerApi} from "../../api/banner.api";
import BannerSliderComponent from "./banner-slider.component";
import {useDispatch, useSelector} from "react-redux";
import {BANNER_REDUCER} from "../../reducers/banner.reducer";
import BannerItemOtherComponent from "./banner-item-other.component";
import DeleteConfirmationModal from "../../components/modal/delete-confirmation.modal";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";

const BannerGoddessGangPage = () => {
    const dispatch = useDispatch()
    const {reload, bannerDetail, activateValue} = useSelector(state => state.bannerReducer)
    const {messageDeleteBanner} = useSelector(state => state.modalReducer)

    useEffect(() => {
        getBannerListApi()
            .then(response => {
                dispatch({
                    type: BANNER_REDUCER, payload: {
                        bannerList: response.cover,
                        bannerOtherList: response.other
                    }
                })
            })
            .catch(err => console.log(err))
    }, [reload])

    const deleteBannerHandler = () => {
        let requestBody = {
            banner_id: bannerDetail?.id
        }
        deleteBannerApi(requestBody)
            .then(response => {
                dispatch({type: MODAL_REDUCER, payload: {deleteGroupModalVisibility: 'hidden'}})
                dispatch({type: BANNER_REDUCER, payload: {reload: !reload}})
            })
            .catch(err => {
                console.log(err)
            })
    }

    const changeActiveBanner = () => {
        let requestBody = {
            banner_id: bannerDetail?.id,
            is_active: activateValue === false ? 0 : 1
        }
        updateStatusBannerApi(requestBody)
            .then(response => {
                dispatch({type: BANNER_REDUCER, payload: {reload: !reload}})
                dispatch({type: MODAL_REDUCER, payload: {deleteGroupModalVisibility: 'hidden'}})
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div className={"font-FuturaBT-Medium"}>
            <div className={" w-full"}>
                <p className={"font-bold text-gray-400 capitalize"}>Banner Goddess Gang</p>
                <div className={"mt-5 border rounded-lg h-500 p-10 flex flex-col items-center"}>
                    <BannerSliderComponent/>
                </div>
            </div>
            <BannerItemOtherComponent/>
            <DeleteConfirmationModal
                message={messageDeleteBanner}
                deleteHandler={messageDeleteBanner.includes('delete') ? deleteBannerHandler : changeActiveBanner }
            />
        </div>
    )
}

export default BannerGoddessGangPage

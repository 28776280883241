import React from "react";
import Warning from "../../images/svg/warning.svg";
import {useDispatch, useSelector} from "react-redux";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";

const DeleteConfirmationModal = (props) => {
    const dispatch = useDispatch()
    const {deleteHandler, message} = props
    const {deleteGroupModalVisibility} = useSelector(state => state.modalReducer)

    const closeModal = () => {
        dispatch({type: MODAL_REDUCER, payload: {deleteGroupModalVisibility: 'hidden'}})
    }
    return (
        <div
            className={`${deleteGroupModalVisibility} fixed flex left-0 top-0 w-full h-full justify-center items-center block bg-gray-900 bg-opacity-40 z-50`}>
            <div className="flex flex-col w-2/6 bg-white p-5 border">
                <button className={"flex justify-end"} onClick={() => closeModal()}>X</button>
                <div className={"flex flex-col justify-center items-center w-full h-full"}>
                    <img className={"animate-bounce font-FuturaBT-Book"} src={Warning}/>
                    <p className={"text-3xl font-FuturaBT-Medium"}>Confirmation!</p>
                    <p className={"font-FuturaBT-Book mt-5"}>{message}</p>
                    <div className={"mt-10 mb-5 space-x-2"}>
                        <button className={"border w-24 p-2 rounded-md bg-gray-300 font-FuturaBT-Book"}
                                onClick={closeModal}>Cancel
                        </button>
                        <button className={"border w-24 p-2 rounded-md bg-milano-red text-white font-FuturaBT-Book"}
                                onClick={deleteHandler}>OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteConfirmationModal

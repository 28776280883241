import React from "react";
import {DatePicker} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {TOOLS_REDUCER} from "../reducers/tools.reducer";

const {RangePicker} = DatePicker;

const DatePickerComponent = () => {
    const dispatch = useDispatch()
    const {startDate, endDate, reloadDatePicker} = useSelector(state => state.toolsReducer)

    const extraFooter = () => {
        return (
            <div className={"flex flex-row justify-end space-x-3 py-1 font-FuturaBT-Medium"}>
                <button className={"border-none px-3"}
                        onClick={() => dispatch({type: TOOLS_REDUCER, payload: {startDate: '', endDate: '', reloadDatePicker: !reloadDatePicker}})}>Clear
                </button>
                <button className={"bg-milano-red px-3 text-white"}
                        onClick={() => dispatch({type: TOOLS_REDUCER, payload: {reloadDatePicker: !reloadDatePicker}})}
                >Apply</button>
            </div>
        )
    }

    return (
        <RangePicker
            value={[startDate, endDate]}
            renderExtraFooter={() => extraFooter()}
            onChange={(date) =>
                dispatch({
                    type: TOOLS_REDUCER,
                    payload: {
                        currentPage: 1,
                        startDate: !!date ? date[0] : '',
                        endDate: !!date ? date[1] : ''
                    }
                })
            }
        />
    )
}

export default DatePickerComponent

import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const getRedeemListApi = (requestBody, params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/redeem-point`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const getRedeemPointDetailApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v4/admin/redeem-point/detail/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const redeemApprovalApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/redeem-point/approval`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

export {
    getRedeemListApi,
    getRedeemPointDetailApi,
    redeemApprovalApi
}

import React from "react";
import Export from "../images/svg/exports.svg";
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'

const ExportComponent = (props) => {
    const {dataSource, fileName} = props

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = () => {
        const ws = XLSX.utils.json_to_sheet(dataSource);
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <button className={"btn height-resp p-2 disabled:bg-gray-200 disabled:cursor-not-allowed"} onClick={exportToCSV} disabled={dataSource.length === 0} id={"section-dont-print"}>
            <img className={"filter-icon-resp"} src={Export}/>
            <p className={"font-size-resp"}>Export</p>
        </button>
    )
}
export default ExportComponent

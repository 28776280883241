import React from "react";
import DatePickerComponent from "../../components/date-picker.component";
import ExportComponent from "../../components/export.component";
import PageSizeComponent from "../../components/page-size.component";
import SearchComponent from "../../components/search.component";
import {Link} from "react-router-dom";
import SortComponent from "../../components/sort.component";
import {formatDateToReadable} from "../../ui-util/general-variable";
import {useSelector} from "react-redux";

const GroupFilterComponent = () => {
    const {groupList} = useSelector(state => state.groupReducer)

    const prepareDataForExport = () => {
        let dataNew = []
        groupList.forEach(item =>{
            let a = {
                "Group Name": item.name,
                "Date": formatDateToReadable(item.created_at),
                "Total User": `${item.total_user} Orang`
            }
            dataNew.push(a)
        })

        return dataNew
    }



    return (
        <div className={"flex flex-row items-center justify-between"}>
            <Link className={"bg-milano-red text-white btn p-2"} to={'/goddess-gang/group/create'}>
                <p className={"font-size-resp"}>+ Create Group</p>
            </Link>
            <div className={"flex flex-row items-center justify-between space-x-2"}>
                <SortComponent/>
                <DatePickerComponent/>
                <ExportComponent dataSource={prepareDataForExport()} fileName={"Group"}/>
                <PageSizeComponent/>
            </div>
            <SearchComponent placeholder={'Search'}/>
        </div>
    )
}

export default GroupFilterComponent

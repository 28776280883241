import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {TOOLS_REDUCER} from "../reducers/tools.reducer";

const PaginationComponent = () => {
    const dispatch = useDispatch()
    const {currentPage, pageSize, totalData} = useSelector(state => state.toolsReducer)
    const [pagination, setPagination] = useState([])
    const [totalPage, setTotalPage] = useState(0)

    useEffect(() => {
        if (pageSize > 0) {
            let total = Math.ceil(totalData / pageSize)
            let j = total > 5 ? 5 : total
            let pag = []

            for (let i = 1; i <= j; i++) {
                pag.push(i)
            }

            setTotalPage(total)
            setPagination(pag)
        }
    }, [totalData, pageSize])

    const valueCheck = (value) => {
        switch (value) {
            case 1:
            case totalPage:
                return true;
                break;
            default:
                return false
        }
    }

    const onChangePagination = (value, index) => {
        if ((value === 2 || value === 3) && index !== 0) {
            dispatch({type: TOOLS_REDUCER, payload: {currentPage: value}})
        } else if ((valueCheck(value) && pagination.includes(value))) {
            dispatch({type: TOOLS_REDUCER, payload: {currentPage: value}})
        } else {
            generatePagination(value)
        }
    }

    const generatePagination = (value) => {
        if (value === totalPage) {
            generatePaginationMin(value)
        } else {
            if (value === 2 || value === 1) {
                setPagination([1, 2, 3, 4, 5])
            } else {
                let baris = []
                let a = value === (totalPage - 1) ? value - 3 : value - 2
                let b = value === (totalPage - 1) ? totalPage + 1 : value + 3
                for (let i = a; i < b; i++) {
                    baris.push(i)
                }
                setPagination(baris)
            }
            dispatch({type: TOOLS_REDUCER, payload: {currentPage: value}})
        }
    }

    const generatePaginationMin = (value) => {
        let baris = []

        dispatch({type: TOOLS_REDUCER, payload: {currentPage: value}})
        for (let i = value; i > value - 5; i--) {
            baris.push(i)
        }
        baris.reverse()
        setPagination(baris)
    }

    return (
        <div className={"my-10 flex flex-row items-center justify-center space-x-2"}>
            {currentPage !== 1 && <button onClick={() => onChangePagination(currentPage - 1)}>Prev</button>}
            {totalData > 0 && !pagination.includes(1) && <button onClick={() => onChangePagination(1)}
                                                className={"w-10 h-10 flex justify-center items-center rounded-3xl active:bg-milano-red active:text-white"}>1
            </button>}
            {totalData > 0 && !pagination.includes(1) && <div>...</div>}
            {
                pagination.map((item, index) =>
                    <button key={item} onClick={() => onChangePagination(item, index)}
                            className={`${(currentPage === item) && 'bg-milano-red text-white'} w-10 h-10 flex justify-center items-center rounded-3xl`}>{item}
                    </button>
                )
            }
            {totalData > 0 && !pagination.includes(totalPage) && <div>...</div>}
            {totalData > 0 && !pagination.includes(totalPage) && <button onClick={() => onChangePagination(totalPage)}
                                                                         className={"w-10 h-10 flex justify-center items-center rounded-3xl active:bg-milano-red active:text-white"}>{totalPage}
            </button>}
            {totalData > 0 && currentPage !== totalPage &&
                <button onClick={() => onChangePagination(currentPage + 1)}>Next</button>}
        </div>
    )
}

export default PaginationComponent

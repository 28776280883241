import React from "react";
import SelectComponent from "../../components/select.component";
import PopoverFilterInstagramTiktokComponent from "../../components/popover-filter-instagram-tiktok.component";
import SearchComponent from "../../components/search.component";
import ExportComponent from "../../components/export.component";
import Campaign from "../../images/svg/campaign.svg";
import Notification from "../../images/svg/notification.svg";
import PageSizeComponent from "../../components/page-size.component";
import SendEmailComponent from "../../components/send-email.component";
import {useDispatch, useSelector} from "react-redux";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";
import {addUserToGroupApi} from "../../api/user.api";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {formatDateToReadable} from "../../ui-util/general-variable";
import DatePickerComponent from "../../components/date-picker.component";

const GoddessGangFilterComponent = () => {
    const dispatch = useDispatch()
    const {
        reload,
        groupDetail,
        groupToUpdate,
        selectedRowKeys,
        selectedRows
    } = useSelector(state => state.userRegisteredReducer)

    const addUserToGroupHandler = () => {
        let requestBody = {
            group_id: groupToUpdate,
            user_ids: selectedRowKeys
        }
        addUserToGroupApi(requestBody)
            .then(response => {
                dispatch({
                    type: MODAL_REDUCER,
                    payload: {display: 'block', userRegisteredText: 'Successfully saved user'}
                })
                setTimeout(() => {
                    dispatch({type: MODAL_REDUCER, payload: {display: 'hidden'}})
                }, 2000);
                setTimeout(() => {
                    window.location.href = '/goddess-gang/group'
                }, 1000);
            })
            .catch(err => {
                console.log('Error addUserToGroupApi', err.response)
            })
    }

    const prepareDataForExport = () => {
        let dataNew = []
        selectedRows.forEach(item => {
            let a = {
                "Name": item.full_name,
                "Birthdate": formatDateToReadable(item.birth_date),
                "Email": item.email,
                "Username Instagram": item.username_instagram,
                "Follower Instagram": item.follower_instagram,
                "ER Instagram": item.er_instagram,
                "Reach Instagram": item.reach_instagram,
                "Username Tiktok": item.username_tiktok,
                "Follower Tiktok": item.follower_tiktok,
                "ER Tiktok": item.er_tiktok,
                "Reach Tiktok": item.reach_tiktok,
                "Register Session": item.goddes_gang_session
            }
            dataNew.push(a)
        })

        return dataNew
    }

    return (
        <div>
            <div className={"flex flex-row justify-between font-size-resp"}>
                <div className={"flex flex-row items-center space-x-2"}>
                    <SelectComponent title={!!groupDetail.name ? groupDetail.name : 'Group'}/>
                    <button className={"btn bg-milano-red px-2 height-resp disabled:bg-opacity-60"}
                            onClick={addUserToGroupHandler}
                            disabled={!((selectedRowKeys.length > 0) && (groupToUpdate.length > 0))}>
                        <p className={"text-white uppercase"}>Submit</p>
                    </button>
                    <DatePickerComponent/>
                    <PageSizeComponent/>
                </div>
                <div className={"flex flex-row items-center space-x-2"}>
                    <PopoverFilterInstagramTiktokComponent/>
                    <SearchComponent placeholder={'Search'}/>
                    <ExportComponent dataSource={prepareDataForExport()} fileName={'Goddess Gang'}/>
                    <button className={"btn height-resp p-2 space-x-1 disabled:bg-gray-200 disabled:cursor-not-allowed"}
                            disabled={selectedRowKeys.length === 0}
                            onClick={() => dispatch({
                                type: MODAL_REDUCER,
                                payload: {addToCampaignModalVisibility: 'block'}
                            })}
                    >
                        <img className={"filter-icon-resp"} src={Campaign}/>
                        <p>Add to Campaign</p>
                    </button>
                    <SendEmailComponent/>
                    <button className={"btn height-resp p-2 space-x-1 disabled:cursor-not-allowed disabled:bg-gray-200"}
                            disabled={selectedRowKeys.length === 0}
                            onClick={() => {
                                dispatch({type: MODAL_REDUCER, payload: {sendNotificationModalVisibility: 'block'}})
                            }}
                    >
                        <img className={"filter-icon-resp"} src={Notification}/>
                        <p>Send to Notification</p>
                    </button>
                </div>
            </div>
            <div className={"flex flex-row font-size-resp justify-end mt-3"}>
                <div className={"flex flex-row space-x-3"}>
                    <SelectComponent title={'All Sosmed'}/>
                    <SelectComponent title={'City'}/>
                    <button className={"btn bg-milano-red height-resp px-2"}
                            onClick={() => dispatch({type: USER_REGISTERED_REDUCER, payload: {reload: !reload}})}
                    >
                        <p className={"text-white uppercase"}>Search</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default GoddessGangFilterComponent

import React from "react";
import {useSelector} from "react-redux";
import AddNewQuestionComponent from "./add-new-question.component";
import QuestionerItemComponent from "./questioner-item.component";

const UnpublishedComponent = () => {
    const {questionerFormDetail} = useSelector(state => state.questionerReducer)

    return (
        <div className={"font-FuturaBT-Medium text-xs"}>
            <p className={"text-2xl font-bold border-b pb-1 border-milano-red"}>Unpublished Questionnaire</p>
            <AddNewQuestionComponent/>
            <div className={"flex flex-col space-y-5 mt-5"}>
                {
                    !!questionerFormDetail?.detail_unpublished &&
                    questionerFormDetail?.detail_unpublished.map((item, index) => <QuestionerItemComponent item={item} index={index} key={index}/>)
                }
            </div>
        </div>
    )
}

export default UnpublishedComponent

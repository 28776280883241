import React from "react";
import Slider from "react-slick";
import Folder from "../../images/svg/ico-folder.svg";
import Trash from "../../images/svg/trash-red.svg";
import {updateBannerApi, updateStatusBannerApi} from "../../api/banner.api";
import {useDispatch, useSelector} from "react-redux";
import {BANNER_REDUCER} from "../../reducers/banner.reducer";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";

const BannerSliderComponent = () => {
    const dispatch = useDispatch()
    const {bannerList, reload} = useSelector(state => state.bannerReducer)

    function SampleNextArrow(props) {
        const {className, style, onClick} = props;
        return (
            <div
                className={className}
                style={{...style, display: "block", background: "black", borderRadius: 25}}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const {className, style, onClick} = props;
        return (
            <div
                className={className}
                style={{...style, display: "block", background: "black", borderRadius: 25}}
                onClick={onClick}
            />
        );
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        swipeToSlide: true,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>
    };

    const changeActiveBanner = (bannerDetail, value) => {
        dispatch({
            type: MODAL_REDUCER,
            payload: {deleteGroupModalVisibility: 'block', messageDeleteBanner: 'Are you sure to activate this banner?'}
        })
        dispatch({
            type: BANNER_REDUCER,
            payload: {activateValue: value, bannerDetail}
        })
    }

    const imageProcessing = (event, bannerDetail) => {
        const thumbnail = event.target.files[0]
        if (thumbnail.type.includes('jpg') || thumbnail.type.includes('jpeg') || thumbnail.type.includes('png')) {
            uploadImage(bannerDetail.title, thumbnail)
        } else {
            alert('Mohon masukkan image file jpg, jpeg atau png')
        }
    }

    const uploadImage = (title, image) => {
        const form = new FormData();
        form.append('title', title)
        form.append('image', image)
        form.append('is_active', '1')

        updateBannerApi(form)
            .then(response => {
                dispatch({type: BANNER_REDUCER, payload: {reload: !reload}})
            })
            .catch(err => console.log(err))
    }

    return (
        <div>
            <Slider {...settings} className={"w-565 flex flex-col items-center justify-center h-full"}>
                {
                    bannerList?.length > 0 &&
                    bannerList.map((item, index) => {
                        return (
                            <div key={index} className={"flex flex-col items-center justify-between h-full py-10 "}>
                                <div className={"h-3/4 p-5"}>
                                    <div className={"h-full flex flex-row justify-between "}>
                                        <div className={""}>
                                            <label htmlFor={index}
                                                   className="flex items-center cursor-pointer relative">
                                                <input type="checkbox" id={index} className="sr-only accent-milano-red"
                                                       checked={item.is_active}
                                                       onChange={e => changeActiveBanner(item, e.target.checked)}/>
                                                <div
                                                    className="toggle-bg bg-gray-400 border-2 border-gray-200 h-6 w-11 rounded-full"></div>
                                            </label>
                                        </div>
                                        <div>
                                            <img src={item.image} className={"w-80 h-36 object-contain"}/>
                                        </div>
                                        <div>
                                            {
                                             bannerList.length > 1 &&
                                                <button onClick={() => {
                                                    dispatch({
                                                        type: MODAL_REDUCER,
                                                        payload: {
                                                            deleteGroupModalVisibility: 'block',
                                                            messageDeleteBanner: 'Are you sure want to delete this banner?'
                                                        }
                                                    })
                                                    dispatch({type: BANNER_REDUCER, payload: {bannerDetail: item}})
                                                }}
                                                        className={"border border-milano-red bg-soft-linen rounded outline-none"}>
                                                    <img src={Trash} className={"bg-none w-5 h-5"}/>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={"h-1/4 flex flex-col justify-center items-center text-xs"}>
                                    <p className={"mt-5 text-gray-400"}>Upload Gambar</p>
                                    <p className={"text-gray-400"}>Ukuran 382px X 115px</p>

                                    <div className={"mt-2"}>
                                        <label>
                                            <div
                                                className={"flex flex-row items-center space-x-2 bg-red-50 border-milano-red border py-1 px-3 cursor-pointer"}>
                                                <img src={Folder} className={" w-6 h-6 "}/>
                                                <p>Choose File</p>
                                            </div>
                                            <input type={"file"} className={"hidden"}
                                                   name={"thumbnail"}
                                                   onChange={(e) => imageProcessing(e, item)}/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
        </div>
    )
}

export default BannerSliderComponent

import React, {useEffect, useState} from "react";
import TableComponent from "../../components/table.component";
import PotentialInfluencer from "../../images/svg/potential-influencer.svg"
import Email from "../../images/svg/email.svg"
import Rating from "../../images/svg/ico-star.svg"
import Edit from "../../images/svg/ico-edited.svg"
import Delete from "../../images/svg/trash-red.svg"
import SendNotification from "../../images/svg/plane-outline.svg"
import {Link, useParams} from "react-router-dom";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {useDispatch, useSelector} from "react-redux";
import {getInfluencerListApi} from "../../api/campaign.api";
import {CAMPAIGN_REDUCER} from "../../reducers/campaign.reducer";
import {customDateToReadable, formatFollower} from "../../ui-util/general-variable";
import {TOOLS_REDUCER} from "../../reducers/tools.reducer";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";

const InfluencerListComponent = () => {
    const dispatch = useDispatch()
    const {id} = useParams();
    const {pageSize, searchKey, currentPage, sort, startDate, endDate, reloadDatePicker} = useSelector(state => state.toolsReducer)
    const {influencerList, reloadInfluencerList, status} = useSelector(state => state.campaignReducer)
    const [columns] = useState([
        {
            type: 'checkbox',
            isRightBorderAvailable: true,
        },
        {
            name: 'Influencer',
            render: (record) => influencer(record)
        }, {
            name: 'Followers',
            render: (record) => <p
                className={"text-center text-sm"}>{formatFollower(!!record?.user_analityc ? record?.user_analityc?.follower : 0)}</p>
        }, {
            name: 'Status',
            render: (record) =>
                <div className={"flex flex-row justify-center"}>
                    <button
                        className={"bg-gray-200 text-xs font-semibold w-32 h-8"}>{record?.campaign_users[0]?.status}</button>
                </div>
        }, {
            name: 'Contact',
            render: (record) =>
                <div className={"flex flex-row justify-center items-center space-x-1 text-sm"}>
                    <img src={Email} className={"w-4 h-4"}/>
                    <p>{record?.email}</p>
                </div>
        }, {
            name: 'Cost',
            render: (record) => <p className={"text-sm italic text-center"}>{record.cost}</p>
        }, {
            name: 'EMV',
            render: () => <p className={"text-sm text-center"}>-</p>
        }, {
            name: 'Rating',
            render: () =>
                <div className={"flex flex-row justify-center"}>
                    <img src={Rating} className={"h-5"}/>
                </div>
        }, {
            name: 'Actions',
            render: (record) =>
                <div className={"flex flex-row justify-center"}>
                    <Link to={`/goddess-gang/gg/user-detail/${record.id}`}>
                        <img src={Edit} className={"h-5"}/>
                    </Link>
                    <img src={Delete} className={"h-5 hover:cursor-pointer"} onClick={() => {
                        dispatch({type: MODAL_REDUCER, payload: {deleteGroupModalVisibility: 'block'}})
                        dispatch({type: CAMPAIGN_REDUCER, payload: {influencerDetail: record}})
                    }}/>
                    <img src={SendNotification} className={"h-5 hover:cursor-pointer"} onClick={() => {
                        dispatch({type: CAMPAIGN_REDUCER, payload: {influencerDetail: record}})
                        dispatch({type: MODAL_REDUCER, payload: {sendNotificationModalVisibility: 'block'}})
                    }}/>
                </div>
        },
    ])

    useEffect(() => {
        return () => {
            dispatch({type: USER_REGISTERED_REDUCER, payload: {selectedRowKeys: [], selectedRows: []}})
        }
    }, [])

    useEffect(() => {
        let requestBody = {
            keyword: searchKey,
            status,
            start_date: startDate && customDateToReadable(startDate, 'YYYY-MM-DD'),
            end_date: endDate && customDateToReadable(endDate, 'YYYY-MM-DD'),
        }

        let params = {
            page: currentPage,
            limit: pageSize,
            orderby: 'id',
            sort
        }
        getInfluencerListApi(id, requestBody, params)
            .then(response => {
                dispatch({type: CAMPAIGN_REDUCER, payload: {influencerList: response.rows}})
                dispatch({type: TOOLS_REDUCER, payload: {totalData: response.total_data}})
            })
            .catch(err => console.log(err))
    }, [pageSize, searchKey, currentPage, sort, reloadInfluencerList, status, reloadDatePicker])

    const influencer = (record) => {
        return (
            <div className={"flex flex-row items-center space-x-2 text-sm"}>
                <img src={record?.photo} className={"w-14 h-14 rounded-full"}/>
                <div>
                    <div className={"flex flex-row items-center"}>
                        <p className={"capitalize"}>{record?.full_name}</p>
                        <img src={PotentialInfluencer} className={"w-4 h-4"}/>
                    </div>
                    <p className={"text-gray-700 text-xs"}>@{record?.user_analityc?.username}</p>
                </div>
            </div>
        )
    }
    return (
        <TableComponent
            columns={columns}
            dataSource={influencerList}
        />
    )
}

export default InfluencerListComponent

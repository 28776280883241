import React, {useEffect} from "react";
import ReactQuill from "react-quill";
import {useDispatch, useSelector} from "react-redux";
import {CREATE_CAMPAIGN_REDUCER} from "../../reducers/create-campaign.reducer";
import ErrorMessageComponent from "../../components/error-message.component";

const TaskDetailComponent = (props) => {
    const dispatch = useDispatch()
    const {createCampaignReducer} = useSelector(state => state)
    const {campaignDetailEdit, createCampaignErrorMessage} = createCampaignReducer
    const {item, index} = props

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
        ],
    }

    useEffect(() => {
        if (!!campaignDetailEdit[item.key]?.content) {
            dispatch({
                type: CREATE_CAMPAIGN_REDUCER, payload: {
                    [item.content]: campaignDetailEdit[item.key].content
                }
            })
            item.setTask(true)
        }
    }, [campaignDetailEdit])

    return (
        <div>
            <div key={index} className={"border border-gray-300 bg-gray-100 p-3 mr-10"}>
                <div
                    className={"flex flex-row justify-between items-center"}>
                    <div className={"flex flex-row items-center space-x-2"}>
                        <img className={"w-4 h-4"} src={item.icon}
                             alt={'calendar'}/>
                        <p className={"font-FuturaBT-Medium font-bold text-milano-red text-tiny"}>{item.label} Tasks</p>
                    </div>
                    <label htmlFor={item.label} className="flex items-center cursor-pointer relative">
                        <input type="checkbox" id={item.label}
                               name={"task"}
                               className="sr-only accent-milano-red"
                               checked={item.taskKey}
                               onChange={e => {
                                   item.setTask(e.target.checked)
                                   dispatch({
                                       type: CREATE_CAMPAIGN_REDUCER,
                                       payload: {[item.hashtag]: '', [item.content]: '', [item.mention]: ''}
                                   })
                               }}/>
                        <div
                            className="toggle-bg bg-gray-400 border-2 border-gray-200 h-6 w-11 rounded-full"></div>
                    </label>
                </div>
                {
                    item.taskKey &&
                    <div>
                        <div className={"h-138 ml-2 mt-2 bg-white"}>
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                value={createCampaignReducer[item.content]}
                                className={"h-24"}
                                onChange={(value) => dispatch({
                                    type: CREATE_CAMPAIGN_REDUCER,
                                    payload: {[item.content]: value}
                                })}/>

                        </div>
                        <div
                            className={"flex flex-row items-center justify-around mt-5 font-FuturaBT-Book text-tiny"}>
                            <div className={"flex flex-row items-center space-x-3"}>
                                <p>Mention</p>
                                <input className={"border outline-0 px-2"} placeholder={"for mention"}
                                       name={item.mention}
                                       defaultValue={!!campaignDetailEdit[item.key]?.c_id ? campaignDetailEdit[item.key]?.mention : ""}
                                       onChange={(e) => dispatch({
                                           type: CREATE_CAMPAIGN_REDUCER,
                                           payload: {[item.mention]: e.target.value}
                                       })}
                                />
                            </div>
                            <div className={"flex flex-row items-center space-x-3"}>
                                <p>Hashtag</p>
                                <input className={"border outline-0 px-2"} placeholder={"for hashtag"}
                                       name={item.hashtag}
                                       defaultValue={!!campaignDetailEdit[item.key]?.c_id ? campaignDetailEdit[item.key]?.hashtag : ""}
                                       onChange={(e) => dispatch({
                                           type: CREATE_CAMPAIGN_REDUCER,
                                           payload: {[item.hashtag]: e.target.value}
                                       })}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
            {!!createCampaignErrorMessage[item.content] &&
                <ErrorMessageComponent message={createCampaignErrorMessage[item.content]}/>}
        </div>
    )
}

export default TaskDetailComponent

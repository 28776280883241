import React, {useEffect, useState} from "react";
import ArrowRight from '../images/svg/chev-right-red.svg';
import {Link, useLocation} from "react-router-dom";
import LogoutComponent from "./logout.component";

const HeaderComponent = () => {
    const location = useLocation();
    const pathRaw = location.pathname.split('/')
    const [menu, setMenu] = useState('')
    const [subMenu, setSubMenu] = useState('')
    const [subSubMenu, setSubSubMenu] = useState('')
    const [id, setId] = useState('')

    useEffect(() => {
        setMenu(pathRaw[2])
        setSubMenu(pathRaw[3])
        setSubSubMenu(pathRaw[4])
        setId(pathRaw[pathRaw.length - 1])
    }, [pathRaw])

    const getPageTitle = () => {
        let pageTitle = ''
        let route = ''
        switch (menu) {
            case 'user-registered':
                route = 'mission/user-registered'
                pageTitle = 'User Register'
                break;
            case 'rejected':
                route = '/mission/rejected'
                pageTitle = 'Rejected'
                break;
            case 'all-users':
                route = '/goddess-gang/all-users'
                pageTitle = 'List Goddess Gang User'
                break;
            case 'group':
                route = '/goddess-gang/group'
                pageTitle = 'Group'
                break;
            case 'list-campaign':
                route = '/campaign/list-campaign'
                pageTitle = 'List Campaign'
                break;
            case 'create-campaign':
                route = '/campaign/create-campaign'
                pageTitle = 'Create Campaign'
                break;
            case 'approvement-campaign':
                route = '/campaign/approvement-campaign'
                pageTitle = 'Approvement Campaign'
                break;
            case 'approval-campaign':
                route = '/campaign/approvement-campaign'
                pageTitle = 'Approval Campaign'
                break;
            case 'gg':
                route = '/goddess-gang/all-users'
                pageTitle = 'Goddess Gang'
                break;
            case 'create-questioner-form':
                route = '/setting/create-questioner-form'
                pageTitle = 'Create Questioner Form'
                break;
            case 'log-affable':
                route = '/setting/log-affable'
                pageTitle = 'Log Affable Report'
                break;
            case 'create-timeline':
                route = '/setting/create-timeline'
                pageTitle = 'Create Timeline'
                break;
            case 'redeem-point':
                route = '/redeem/redeem-point'
                pageTitle = 'List User Redeem point'
                break;
            default:
                pageTitle = 'Looke Dashboard'
        }
        return {pageTitle, route}
    }

    const getSubMenuTitle = () => {
        let subMenuTitle = null
        let route = ''
        switch (subMenu) {
            case 'user-detail':
                route = `/${pathRaw[1]}/${pathRaw[2]}/user-detail/${id}`
                subMenuTitle = 'User Detail'
                break;
            case 'detail-campaign':
                subMenuTitle = 'Detail Campaign'
                break;
            case 'edit-campaign':
                subMenuTitle = 'Edit Campaign'
                break;
            case 'group-detail':
                subMenuTitle = 'Group Detail'
                break;
            case 'redeem-detail':
                subMenuTitle = 'Redeem Point Detail'
                break;
            default:
                subMenuTitle = null
        }
        return {subMenuTitle, route}
    }

    const getSubSubMenuTitle = () => {
        let subSubMenuTitle = null
        switch (subSubMenu) {
            case '2':
                subSubMenuTitle = 'Facebook Analytics'
                break;
            case '1':
                subSubMenuTitle = 'Instagram Analytics'
                break;
            case '4':
                subSubMenuTitle = 'TikTok Analytics'
                break;
            case '5':
                subSubMenuTitle = 'Youtube Analytics'
                break;
            default:
                subSubMenuTitle = null
        }
        return subSubMenuTitle
    }

    return (
        <div className={"flex flex-row items-center bg-white border-b border-b-gray-200 px-14 w-full py-5"} id={"section-dont-print"}>
            <div className={"flex items-center w-full space-x-4-responsive"}>
                <Link to={getPageTitle().route}>
                    <p className={"capitalize xl:text-3xl 2xl:text-4xl font-CaviarDreams text-milano-red"}>
                        {getPageTitle().pageTitle}
                    </p>
                </Link>
                {
                    !!getSubMenuTitle().subMenuTitle &&
                    <>
                        <img className={"print:w-3 print:h-3 xl:h-7 2xl:h-8 xl:w-7 2xl:w-8 xl:mx-3.5 2xl:mx-4"}
                             src={ArrowRight} alt="arrow right icon"/>
                        <Link to={getSubMenuTitle().route}>
                            <p className={"capitalize xl:text-3xl 2xl:text-4xl font-CaviarDreams text-milano-red"}>
                                {getSubMenuTitle().subMenuTitle}
                            </p>
                        </Link>
                        {
                            !!getSubSubMenuTitle() &&
                            <>
                                <img className={"xl:h-7 2xl:h-8 xl:w-7 2xl:w-8 xl:mx-3.5 2xl:mx-4"}
                                     src={ArrowRight} alt="arrow right icon"/>
                                <p className={"capitalize xl:text-3xl 2xl:text-4xl font-CaviarDreams text-milano-red"}>
                                    {getSubSubMenuTitle()}
                                </p>
                            </>
                        }
                    </>
                }
            </div>
            <LogoutComponent />
        </div>

    )
}

export default HeaderComponent;

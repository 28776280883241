import React from "react";
import CheckedGreen from "../../images/svg/approve-green.svg";
import RejectRed from "../../images/svg/reject-red.svg";
import StatusFilterComponent from "./status-filter.component";
import DatePickerComponent from "../../components/date-picker.component";
import PageSizeComponent from "../../components/page-size.component";
import SearchComponent from "../../components/search.component";
import ExportComponent from "../../components/export.component";
import {useDispatch, useSelector} from "react-redux";
import {redeemApprovalApi} from "../../api/redeem.api";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";
import {REDEEM_REDUCER} from "../../reducers/redeem.reducer";
import {doConvertNumberToRupiahFormat, formatDateToReadable} from "../../ui-util/general-variable";

const RedeemFilterComponent = () => {
    const dispatch = useDispatch()
    const {selectedRowKeys} = useSelector(state => state.userRegisteredReducer)
    const {reload, redeemList} = useSelector(state => state.redeemReducer)

    const approvalHandler = (statusText) => {
        let requestBody = {
            status: statusText,
            withdraw_ids: selectedRowKeys
        }
        redeemApprovalApi(requestBody)
            .then(response => {
                dispatch({type: MODAL_REDUCER, payload: {display: 'block'}})
                dispatch({type: USER_REGISTERED_REDUCER, payload: {selectedRowKeys: [], selectedRows: []}})
                dispatch({type: REDEEM_REDUCER, payload: {reload: !reload}})
            })
            .catch(err => console.log(err))
    }

    const prepareDataForExport = () => {
        let dataNew = []
        redeemList.forEach(item =>{
            let a = {
                "Name": item.user.full_name,
                "Email": item.user.email,
                "Date Of Birth": formatDateToReadable(item.user.birth_date),
                "Total Point": `${item.total_point} Point`,
                "Point yang di Redeem": `${item.point_withdraw} Point`,
                "Jumlah Konversi(Rp)": doConvertNumberToRupiahFormat(item.total_withdraw),
                "Waktu Redeem": formatDateToReadable(item.created_at),
                "Status": item.status,
            }
            dataNew.push(a)
        })

        return dataNew
    }

    return (
        <div className={"flex flex-row justify-between"}>
            <div className={"flex flex-row items-center space-x-4"}>
                <div className={"flex flex-row items-center space-x-1"}>
                    <button className={"border disabled:opacity-40 w-8 h-8"} disabled={selectedRowKeys.length === 0}
                            onClick={() => approvalHandler('Approve')}>
                        <img src={CheckedGreen} className={"w-7 h-7"}/>
                    </button>
                    <button className={"border disabled:opacity-40 w-8 h-8"} disabled={selectedRowKeys.length === 0}
                            onClick={() => approvalHandler('Reject')}>
                        <img src={RejectRed} className={"w-7 h-7"}/>
                    </button>
                    <StatusFilterComponent/>
                    <DatePickerComponent/>
                </div>
                <PageSizeComponent/>
            </div>
            <div className={"flex flex-row items-center space-x-2"}>
                <SearchComponent/>
                <ExportComponent dataSource={prepareDataForExport()} fileName={"Redeem Point"}/>
            </div>
        </div>
    )
}

export default RedeemFilterComponent

import React, {useEffect, useState} from "react";
import AccountIcon from "../images/svg/icon-account.svg";
import {useDispatch, useSelector} from "react-redux";
import {TOOLS_REDUCER} from "../reducers/tools.reducer";

const LogoutComponent = () => {
    const dispatch = useDispatch()
    const [isLogout, setIsLogout] = useState(false)
    const {adminDetail} = useSelector(state => state.toolsReducer)

    useEffect(() => {
        setIsLogout(false)
    }, [])

    const logoutHandler = () => {
        dispatch({type: TOOLS_REDUCER, payload: {adminDetail: {}}})
        localStorage.removeItem('TOKEN_AUTH')
        window.location.reload()
    }

    return (
        <div className={"relative group flex items-center w-1/2 justify-end"}>
            <div className={"flex flex-row items-center border p-2 cursor-pointer hover:bg-milano-red hover:text-white space-x-4-responsive"}
                    onClick={() => setIsLogout(!isLogout)}
            >
                <img className={"print:w-5 print:h-5 xl:h-7 2xl:h-8 xl:w-7 2xl:w-8"}
                     src={AccountIcon} alt="account icon"/>
                <div className={"flex flex-col"}>
                    <p className={"text-tiny-responsive xl:text-sm font-bold font-FuturaBT-Medium"}>
                        {adminDetail?.full_name}
                    </p>
                    <p className={"text-sm-responsive xl:text-xs font-FuturaBT-Book "}>
                        Admin
                    </p>
                </div>
            </div>
            <div className={`absolute ${isLogout ? 'block' : 'hidden'} mt-36 border bg-white w-64 flex items-center justify-center z-30`}>
                <button className={"bg-milano-red text-white px-5 py-1 rounded-full my-5"}
                        onClick={() => logoutHandler()}
                >Logout</button>
            </div>
        </div>
    )
}

export default LogoutComponent

import React, {useState} from "react";
import TrashIcon from "../../images/svg/ico-trashcan.svg";
import EditIcon from "../../images/svg/ico-edited-black.svg";
import PlaneIcon from "../../images/svg/icon-plane-black.svg";
import {partition} from "lodash";
import {QUESTIONER_REDUCER} from "../../reducers/questioner.reducer";
import {useDispatch, useSelector} from "react-redux";
import EditQuestionerComponent from "./edit-questioner.component";

const QuestionerItemComponent = (props) => {
    const dispatch = useDispatch()
    const {item, index} = props
    const [isEdit, setIsEdit] = useState(false)
    const {questionerFormDetail} = useSelector(state => state.questionerReducer)

    const moveToPublishedList = (questionerDetail) => {
        questionerDetail.is_published = 1
        let unPublishedListCopy = questionerFormDetail?.detail_unpublished
        let partitionList = partition(unPublishedListCopy, ['sequence', questionerDetail.sequence])
        partitionList[1].map((item, index) => {
            item.sequence = index + 1
            return item
        })
        partitionList[0][0].sequence = questionerFormDetail.detail_published.length + 1
        questionerFormDetail.detail_unpublished = partitionList[1]
        questionerFormDetail.detail_published[questionerFormDetail.detail_published.length] = partitionList[0][0]
        dispatch({type: QUESTIONER_REDUCER, payload: {questionerFormDetail}})
    }

    const deleteQuestioner = (questionerDetail) => {
        let unPublishedListCopy = questionerFormDetail?.detail_unpublished
        let partitionList = partition(unPublishedListCopy, ['sequence', questionerDetail.sequence])
        partitionList[1].map((item, index) => {
            item.sequence = index + 1
            return item
        })
        questionerFormDetail.detail_unpublished = partitionList[1]
        dispatch({type: QUESTIONER_REDUCER, payload: {questionerFormDetail}})
    }

    if (isEdit) {
        return (
            <EditQuestionerComponent item={item} index={index}
                                         saveDataEdit={() => setIsEdit(false)}
        />
        )
    } else {
        return (
            <div className={"flex flex-row border p-3"}>
                <div className={"w-7"}>
                    <p
                        className={"w-5 h-5 bg-milano-red text-sm font-bold rounded-full text-white flex flex-col items-center justify-center"}>
                        {item.sequence}
                    </p>
                </div>
                <div className={"flex flex-col w-full space-y-3"}>
                    <p>{item.question}</p>
                    {
                        !!item.image &&
                        <img src={item.image} className={"w-24"}/>
                    }
                    <div className={"flex flex-col space-y-3"}>
                        {
                            item.options.map((option, idx) => (
                                <div className={"flex flex-row items-center space-x-2"} key={idx}>
                                    <input type={"radio"} checked={option.is_correct && 'checked'} name={item.question}
                                           className={"accent-milano-red"} onChange={() => null}/>
                                    {
                                        option.type === 'image' ?
                                            <img src={option.label} className={"w-24"}/> :
                                            <label htmlFor="age1">{option.label}</label>

                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div className={"flex flex-row-reverse"}>
                        <div className={"flex flex-row space-x-5 text-sm"}>
                            <button className={"flex flex-row space-x-1 items-center bg-gray-100 px-2 h-8"}
                                    onClick={() => deleteQuestioner(item)}
                            >
                                <img src={TrashIcon} className={"w-6 h-6"}/>
                                <p>Delete</p>
                            </button>
                            <button className={"flex flex-row space-x-1 items-center bg-gray-100 px-2 h-8"}
                                    onClick={() => setIsEdit(true)}
                            >
                                <img src={EditIcon} className={"w-6 h-6"}/>
                                <p>Edit</p>
                            </button>
                            <button className={"flex flex-row space-x-1 items-center bg-gray-100 px-2 h-8"}
                                    onClick={() => moveToPublishedList(item)}
                            >
                                <img src={PlaneIcon} className={"w-3 h-3"}/>
                                <p>Move To Published List</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default QuestionerItemComponent

import React, {useEffect, useState} from "react";
import Search from "../../images/svg/ico-search.svg";
import SortComponent from "../../components/sort.component";
import {useSelector} from "react-redux";

const InfluencerBrandAffinityComponent = () => {
    const {analyticsFacebookResult} = useSelector(state => state.analyticsReducer)
    const [searchKey, setSearchKey] = useState('')
    const [dataList, setDataList] = useState([])
    const {sort} = useSelector(state => state.toolsReducer)

    useEffect(() => {
        let dataOption = analyticsFacebookResult?.influencer_brand_affinity.filter((i) => i.fullName.toLowerCase().includes(searchKey.toLowerCase()))
        setDataList(dataOption)
    }, [searchKey])

    useEffect(() => {
        let newData = analyticsFacebookResult?.influencer_brand_affinity.sort(function(a, b){return b-a});
        setDataList(newData)
    }, [sort])

    return (
        <div className={"border px-8 space-y-3 py-3"}>
            <div className={"flex flex-row items-center justify-between mb-3"}>
                <p className={"font-FuturaBT-Medium text-sm ml-5 font-semibold"}>Influencer Brand Affinity</p>
                <div className={"flex flex-col group relative"}>
                    <p className="invisible bg-gray-200 absolute z-10 text-xxs group-hover:visible border border-gray-300 p-3 rounded bottom-6 w-40 right-0">
                        Brand that influencer has mentioned & tagged in their posts
                    </p>
                    <button
                        className="text-sm bg-gray-200 w-5 h-5 rounded-full text-gray-400 hover:text-black inline-block">?
                    </button>
                </div>
            </div>
            <div className={"flex flex-row justify-between space-x-2"}>
                <div
                    className={"flex flex-row items-center justify-between border border-b-gray-200 height-resp w-full px-3 space-x-1"}>
                    <img className={"w-4 h-4"} src={Search} alt={'search'}/>
                    <input placeholder={"Filter keywords"}
                           className={"border-0 outline-0 font-size-resp w-full"} onChange={e => setSearchKey(e.target.value)}/>
                </div>
                <SortComponent/>
            </div>
            <div className={"h-72 overflow-scroll flex flex-col space-y-2"}>
                {
                    !!analyticsFacebookResult?.influencer_brand_affinity &&
                    dataList.map((item, index) => (
                        <div className={"flex flex-row items-center text-xs space-x-3"} key={index}>
                            <embed src={item.profilePicUrl.replace(`media/?size=t`, 'embed')} className="w-12 h-12 rounded-full" />
                            <div>
                                <p className={"font-semibold"}>{item.fullName}</p>
                                <p>{item.username}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default InfluencerBrandAffinityComponent

import React from "react";
import SortComponent from "../../components/sort.component";
import DatePickerComponent from "../../components/date-picker.component";
import ExportComponent from "../../components/export.component";
import PageSizeComponent from "../../components/page-size.component";
import SearchComponent from "../../components/search.component";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const CampaignFilterComponent = () => {
    const {campaignList} = useSelector(state => state.campaignReducer)

    const prepareDataForExport = () => {
        let dataNew = []
        campaignList.forEach(item =>{
            let a = {
                "Title": item.title,
                "Point": item.point,
                "Status": item.status
            }
            dataNew.push(a)
        })

        return dataNew
    }
    return (
        <div className={"flex flex-row items-center justify-between mt-10"}>
            <Link to={`/campaign/create-campaign`}>
                <button className={"bg-milano-red text-white px-6 height-resp flex flex-row items-center"}>
                    <p className={"text-xl mr-1 font-bold"}>+</p>
                    <p className={"font-size-resp"}>Create Campaign</p>
                </button>
            </Link>
            <div className={"flex flex-row items-center space-x-2"}>
                <SortComponent/>
                <DatePickerComponent/>
                <ExportComponent dataSource={prepareDataForExport()} fileName={'Campaign'}/>
                <PageSizeComponent/>
            </div>
            <SearchComponent placeholder={"Search"}/>
        </div>
    )
}

export default CampaignFilterComponent

import React from "react";
import LockIcon from "../../images/svg/ico-lock.svg";
import ArrowUpIcon from "../../images/svg/ico-btn-up.svg";
import ArrowDownIcon from "../../images/svg/ico-btn-down.svg";
import {useDispatch, useSelector} from "react-redux";
import {cloneDeep, partition} from "lodash";
import {QUESTIONER_REDUCER} from "../../reducers/questioner.reducer";
import {changeSequenceList} from "../../ui-util/general-variable";

const PublishedComponent = () => {
    const dispatch = useDispatch()
    const {questionerFormDetail} = useSelector(state => state.questionerReducer)

    const moveToUnpublished = (questionerDetail) => {
        questionerDetail.is_published = 0
        let publishedListCopy = questionerFormDetail?.detail_published
        let partitionList = partition(publishedListCopy, ['sequence', questionerDetail.sequence])
        partitionList[0][0].sequence = questionerFormDetail.detail_unpublished.length + 1
        questionerFormDetail.detail_published = changeSequenceList(partitionList[1])
        questionerFormDetail.detail_unpublished[questionerFormDetail.detail_unpublished.length] = partitionList[0][0]
        dispatch({type: QUESTIONER_REDUCER, payload: {questionerFormDetail}})
    }

    const changeSequence = (index, sequenceType) => {
        let publishedListCopy = cloneDeep(questionerFormDetail?.detail_published)
        let publishedList = cloneDeep(questionerFormDetail?.detail_published)

        if((index !== 0) || (index !== questionerFormDetail.detail_published.length)){
            if(sequenceType === 'up'){
                publishedList[index - 1] = publishedListCopy[index]
                publishedList[index] = publishedListCopy[index - 1]
            } else {
                publishedList[index+1] = publishedListCopy[index]
                publishedList[index] = publishedListCopy[index+1]
            }
            questionerFormDetail.detail_published = changeSequenceList(publishedList)
            dispatch({type: QUESTIONER_REDUCER, payload: {questionerFormDetail}})
        }
    }

    return (
        <div className={"font-FuturaBT-Medium text-xs"}>
            <p className={"text-2xl font-bold border-b pb-1 border-milano-red"}>Published Questionnaire</p>
            <div className={"flex flex-col space-y-5 mt-5"}>
                {
                    !!questionerFormDetail?.detail_published &&
                    questionerFormDetail?.detail_published.map((item, index) => (
                        <div className={"flex flex-row border p-2"} key={index}>
                            <div className={"w-7"}>
                                <p
                                    className={"w-5 h-5 bg-milano-red text-sm font-bold rounded-full text-white flex flex-col items-center justify-center"}>
                                    {item.sequence}
                                </p>
                            </div>
                            <div className={"flex flex-col w-full space-y-3"}>
                                <p>{item.question}</p>
                                {
                                    !!item.image &&
                                    <img src={item.image} className={"w-24"}/>
                                }
                                <div className={"flex flex-col space-y-3"}>
                                    {
                                        item.options.map((option, idx) => (
                                            <div className={"flex flex-row items-center space-x-2"} key={idx}>
                                                <input type={"radio"} checked={option.is_correct && 'checked'}
                                                       onChange={() => null}
                                                       className={"accent-milano-red"}/>
                                                {
                                                    option.type === 'image' ?
                                                        <img src={option.label} className={"w-24"}/> :
                                                        <label htmlFor="age1">{option.label}</label>

                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={"flex flex-row-reverse"}>
                                    <div className={"flex flex-row space-x-3 text-sm"}>
                                        <button className={"flex flex-row space-x-1 items-center bg-gray-100 px-2 h-8"}
                                                onClick={() => moveToUnpublished(item)}
                                        >
                                            <img src={LockIcon} className={"w-6 h-6"}/>
                                            <p>Move To Unpublished List</p>
                                        </button>
                                        <button className={"flex flex-row space-x-1 items-center bg-gray-100 px-2 h-8 disabled:cursor-not-allowed"}
                                                onClick={() => changeSequence(index, 'up')}
                                                disabled={index === 0}
                                        >
                                            <img src={ArrowUpIcon} className={"w-6 h-6"}/>
                                        </button>
                                        <button className={"flex flex-row space-x-1 items-center bg-gray-100 px-2 h-8 disabled:cursor-not-allowed"}
                                                onClick={() => changeSequence(index, 'down')}
                                                disabled={index === (questionerFormDetail?.detail_published.length - 1)}
                                        >
                                            <img src={ArrowDownIcon} className={"w-6 h-6"}/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default PublishedComponent

import React, {useState} from "react";
import {doConvertNumberToRupiahFormat, formatFollower} from "../../ui-util/general-variable";
import Love from "../../images/svg/icon-heart.svg";
import CommentIconRed from "../../images/svg/icon-comment.svg";
import Male from "../../images/svg/icon-male.svg";
import Female from "../../images/svg/icon-female.svg";
import EstimatedMedia from "../../images/svg/icon-engagment.svg";
import Signal from "../../images/svg/icon-signal.svg";

const ReportUserItemComponent = (props) => {
    const {item, index} = props
    const [engagementContent] = useState([
        {
            logo: Love,
            key: 'likes',
            label: 'Likes'
        }, {
            logo: CommentIconRed,
            key: 'comments',
            label: 'Comments'
        }, {
            logo: Male,
            key: 'followers',
            label: 'Followers'
        }
    ])

    const [genderList] = useState([
        {
            logo: Male,
            key: 'male',
            label: 'Laki - Laki'
        }, {
            logo: Female,
            key: 'female',
            label: 'Wanita'
        },
    ])

    const [estimatedList] = useState([
        {
            logo: EstimatedMedia,
            format: 'idr',
            key: 'estimatedMediaValue',
            label: 'Est. Media Values'
        }, {
            logo: Signal,
            format: 'follower',
            key: 'estimatedReach',
            label: 'Est. Reach'
        }, {
            logo: Signal,
            format: 'follower',
            key: 'estimatedImpressions',
            label: 'Est. Impressions'
        },
    ])

    return (
        <div className={"flex flex-row border p-5 font-FuturaBT-Book h-full space-x-10"} key={index}>
            <div className={"w-1/5 h-full"}>
                <iframe src={item.media.urlThumbnail.replace(`media/?size=t`, 'embed')}
                        frameBorder="0" className={"w-full"}/>
                <h6 className="text-xs font-bold py-3">Caption</h6>
                <p className="text-xxs">{item.media.caption}</p>
            </div>
            <div>
                <div className={"flex flex-row items-center space-x-3"}>
                    <img src={item.creator.picture} className={"img-14-responsive rounded-full"} width={50}/>
                    <div className={"text-xs-responsive"}>
                        <p className={"font-bold text-base-responsive capitalize"}>{item.creator.fullName}</p>
                        <p>{item.creator.dob}</p>
                        <p>{item.creator.email}</p>
                    </div>
                </div>
                <div className={"grid grid-cols-2 gap-5 mt-10"}>
                    <div>
                        <p className={"font-bold text-xs-responsive"}>Engagement</p>
                        <div className={"flex flex-row items-center space-x-10-responsive my-7"}>
                            {
                                engagementContent.map((engagement, idx) => (
                                    <div className={"flex flex-col items-center"} key={idx}>
                                        <img src={engagement.logo} className={"img-10-responsive"}/>
                                        <p className={"font-bold text-lg-responsive"}>{item.engagement[engagement.key]}</p>
                                        <p className={"text-gray-500 text-base-responsive"}>{engagement.label}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div>
                        <p className={"font-bold text-xs-responsive"}>Gender Distribution</p>
                        <div className={"flex flex-row items-center space-x-10 my-7"}>
                            {
                                genderList.map((gender, i) => (
                                    <div className={"flex flex-row items-center"} key={i}>
                                        <img src={gender.logo} className={"img-14-responsive"}/>
                                        <div className={"flex flex-col"}>
                                            <p className={"font-bold text-lg-responsive"}>{item.gender[gender.key]}</p>
                                            <p className={"text-gray-500 text-base-responsive"}>{gender.label}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div>
                        <p className={"font-bold text-xs-responsive"}>Age Distributions</p>
                        <div className={"flex flex-row items-center space-x-3 my-7"}>
                            {
                                item.age.buckets.map((age, i) => (
                                    <div key={i}
                                         className={"flex flex-col space-y-2 font-bold text-xl-responsive items-center"}>
                                        <p className={"text-milano-red"}>{age.label}</p>
                                        <p>{age.percent}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div>
                        <p className={"font-bold text-xs-responsive"}>Estimated Reach</p>
                        <div className={"my-7 grid grid-cols-2 gap-5"}>
                            {
                                estimatedList.map((est, i) => (
                                    <div className={"flex flex-row items-center space-x-5"} key={i}>
                                        <img src={est.logo} className={"img-14-responsive"}/>
                                        <div className={"flex flex-col"}>
                                            <p className={"font-bold text-lg-responsive"}>{est.format === 'follower' ? formatFollower(item[est.key]) : doConvertNumberToRupiahFormat(item[est.key])}</p>
                                            <p className={"text-gray-500 text-sm-responsive"}>{est.label}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportUserItemComponent

import React from "react";
import Email from "../images/svg/email.svg";
import {useSelector} from "react-redux";

const SendEmailComponent = () => {
    const {selectedRows} = useSelector(state => state.userRegisteredReducer)

    const getEmailList = () => {
        let email = []
        selectedRows.forEach(i => {
            email.push(i.email)
        })
        return email
    }

    return (
        <>
            {
                selectedRows.length > 0 ?
                    <a href={'mailto:' + getEmailList()} className={"btn height-resp p-2 space-x-1 font-size-resp hover:text-black"}>
                        <img className={"filter-icon-resp"} src={Email}/>
                        <p>Send to Email</p>
                    </a> :
                    <button className={"btn height-resp p-2 space-x-1 font-size-resp cursor-not-allowed bg-gray-200"}>
                        <img className={"filter-icon-resp"} src={Email}/>
                        <p>Send to Email</p>
                    </button>

            }
        </>

    )
}

export default SendEmailComponent

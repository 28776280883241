const INITIAL_STATE = {
    selectedRowKeys: [],
    selectedRows: [],
    userList: [],
    userDetail: {},
    statusToUpdate: '',
    groupToUpdate: '',
    groupDetail: {},
    status: 'waiting verification',
    min_correct: 0,
    max_correct: 0,
    ig_followers_min: 0,
    ig_followers_max: 0,
    ig_er_min: 0,
    ig_er_max: 0,
    ig_reach_min: 0,
    ig_reach_max: 0,
    tiktok_followers_min: 0,
    tiktok_followers_max: 0,
    tiktok_er_min: 0,
    tiktok_er_max: 0,
    tiktok_reach_min: 0,
    tiktok_reach_max: 0,
    social_media_id: null,
    interest_id: null,
    city_id: null,
    reload: false
}

export const USER_REGISTERED_REDUCER = 'USER_REGISTERED_REDUCER';
export const FLUSH_USER_REGISTERED_REDUCER = 'FLUSH_USER_REGISTERED_REDUCER'
export const userRegisteredReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_REGISTERED_REDUCER:
            return {...state, ...action.payload}
        case FLUSH_USER_REGISTERED_REDUCER:
            return {...state,
                selectedRows: [],
                selectedRowKeys: [],
                min_correct: 0,
                max_correct: 0,
                ig_followers_min: 0,
                ig_followers_max: 0,
                ig_er_min: 0,
                ig_er_max: 0,
                ig_reach_min: 0,
                ig_reach_max: 0,
                tiktok_followers_min: 0,
                tiktok_followers_max: 0,
                tiktok_er_min: 0,
                tiktok_er_max: 0,
                tiktok_reach_min: 0,
                tiktok_reach_max: 0,
                social_media_id: null,
                interest_id: null,
                city_id: null,
            }
        default:
            return state
    }
}

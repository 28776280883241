const INITIAL_STATE = {
    adminDetail: {},
    searchKey: '',
    pageSize: 5,
    totalPage: 10,
    totalData: 0,
    currentPage: 1,
    orderBy: 'created_at',
    sort: 'desc',
    cityList: [],
    interestList: [],
    socialMediaList: [],
    selectedMultipleValue: [],
    selectDataProduct: [],
    selectDataEmail: [],
    selectDataGroup: [],
    sideBarSelectedKey: '',
    startDate: '',
    endDate: '',
    reloadDatePicker: false
}

export const TOOLS_REDUCER = 'TOOLS_REDUCER';
export const FLUSH_SORTING = 'FLUSH_SORTING';

export const toolsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOOLS_REDUCER:
            return {...state, ...action.payload}
        case FLUSH_SORTING:
            return {...state, sort: 'desc', orderBy: 'created_at', pageSize: 5, searchKey: '', currentPage: 1, startDate: '', endDate: ''}
        default:
            return state
    }
}

import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getCampaignDetailApi} from "../../api/campaign.api";
import {formatDateToReadable} from "../../ui-util/general-variable";
import {useDispatch, useSelector} from "react-redux";
import {APPROVAL_CAMPAIGN_REDUCER} from "../../reducers/approval-campaign.reducer";

const CampaignDetailComponent = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const {totalData} = useSelector(state => state.toolsReducer)
    const {userList, campaignDetail} = useSelector(state => state.approvalCampaignReducer)

    useEffect(() => {
        getCampaignDetailApi(id)
            .then(response => {
                dispatch({type: APPROVAL_CAMPAIGN_REDUCER, payload: {campaignDetail: response}})
            })
            .catch(err => {
                console.log(err.response)
            })
    }, [])

    return (
        <div className={"flex flex-row items-center font-FuturaBT-Medium justify-between mt-5"}>
            <div className={"flex flex-row items-center"}>
                <img className={"w-64 h-64"} src={campaignDetail?.thumbnail}/>

                <div className={"m-10 space-y-2"}>
                    <p className={"font-bold"}>{campaignDetail?.title}</p>
                    <div className={"flex flex-row space-x-2"}>
                        <p className={"text-sm text-gray-400"}>{formatDateToReadable(campaignDetail?.created_at)}</p>
                        <p className={"font-bold text-sm"}>{campaignDetail?.point} Point</p>
                    </div>
                </div>
            </div>
            <div className={"flex flex-row items-center space-x-1"}>
                {
                    userList.length > 0 &&
                    userList.slice(0, 3).map((item, index) => (
                        <img src={item.photo} className={"w-10 h-10 rounded-full"}
                             key={index}/>
                    ))
                }
                <p className={"text-gray-400 text-sm"}>{totalData} Orang</p>
            </div>
        </div>
    )
}

export default CampaignDetailComponent

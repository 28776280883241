import React, {useEffect, useState} from "react";
import AddInfluencer from "../../images/svg/add-friends.svg";
import PopoverFilterInstagramTiktokComponent from "../../components/popover-filter-instagram-tiktok.component";
import ExportComponent from "../../components/export.component";
import PageSizeComponent from "../../components/page-size.component";
import SendEmailComponent from "../../components/send-email.component";
import SearchComponent from "../../components/search.component";
import GoddessGangUserListComponent from "../goddess-gang/goddess-gang-user-list.component";
import {getGoddessGangUserList} from "../../api/user.api";
import {FLUSH_SORTING, TOOLS_REDUCER} from "../../reducers/tools.reducer";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";
import {useDispatch, useSelector} from "react-redux";
import {createGroupApi} from "../../api/group.api";
import SuccessModal from "../../components/success.modal";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {formatDateToReadable} from "../../ui-util/general-variable";
import {uniq} from "lodash";

const CreateGroupPage = () => {
    const [groupName, setGroupName] = useState('')
    const [userId, setUserId] = useState([])
    const dispatch = useDispatch()
    const {selectedRows} = useSelector(state => state.userRegisteredReducer)
    const {pageSize, currentPage, searchKey, sort, orderBy} = useSelector(state => state.toolsReducer)
    const {
        reload,
        ig_followers_min,
        ig_followers_max,
        ig_er_min,
        ig_er_max,
        ig_reach_min,
        ig_reach_max,
        tiktok_followers_min,
        tiktok_followers_max,
        tiktok_er_min,
        tiktok_er_max,
        tiktok_reach_min,
        tiktok_reach_max,
        selectedRowKeys
    } = useSelector(state => state.userRegisteredReducer)

    useEffect(() => {
        return () => {
            dispatch({type: FLUSH_SORTING})
        }
    }, [])

    useEffect(() => {
        let params = {
            page: currentPage,
            limit: pageSize,
            orderby: orderBy,
            sort
        }
        let bodyRequest = {
            keyword: searchKey,
            status: 'approve',
            min_correct: 0,
            max_correct: 0,
            ig_followers_min,
            ig_followers_max,
            ig_er_min,
            ig_er_max,
            ig_reach_min,
            ig_reach_max,
            tiktok_followers_min,
            tiktok_followers_max,
            tiktok_er_min,
            tiktok_er_max,
            tiktok_reach_min,
            tiktok_reach_max,
            start_date: "",
            end_date: "",
            social_media_id: null,
            interest_id: null,
            city_id: null
        }
        getGoddessGangUserList(bodyRequest, params)
            .then(response => {
                dispatch({
                    type: TOOLS_REDUCER, payload: {
                        totalData: response.total_data
                    }
                })
                dispatch({
                    type: USER_REGISTERED_REDUCER, payload: {
                        userList: response.rows
                    }
                })
            })
            .catch(err => {
                console.log('Error getGoddessGangUserList', err.response)
            })

    }, [
        pageSize,
        reload,
        ig_followers_min,
        ig_followers_max,
        ig_er_min,
        ig_er_max,
        ig_reach_min,
        ig_reach_max,
        tiktok_followers_min,
        tiktok_followers_max,
        tiktok_er_min,
        tiktok_er_max,
        tiktok_reach_min,
        tiktok_reach_max,
        searchKey,
        orderBy,
        sort,
        currentPage
    ])

    const createGroup = () => {
        let requestBody = {
            name: groupName,
            user_ids: userId
        }
        createGroupApi(requestBody)
            .then(response => {
                dispatch({type: MODAL_REDUCER, payload: {display: 'block'}})
                setTimeout(() => {
                    dispatch({type: MODAL_REDUCER, payload: {display: 'hidden'}})
                }, 2000);
                setTimeout(() => {
                    window.location.href = '/goddess-gang/group'
                }, 1000);
            })
            .catch(err => console.log('Error createGroupApi', err.response))
    }

    const prepareDataForExport = () => {
        let dataNew = []

        selectedRows.forEach(item =>{
            let a = {
                "Name": item.full_name,
                "Birthdate": formatDateToReadable(item.birth_date),
                "Email": item.email,
                "Register Session": `${item.goddes_gang_session}x`
            }
            dataNew.push(a)
        })

        return dataNew
    }

    const addInfluencerHandler = () => {
        let union = userId.concat(selectedRowKeys)
        let data = uniq(union)

        setUserId(data)
    }

    return (
        <>
            <input
                className={"w-1/5 border-b-2 border-b-milano-red h-32 text-3xl font-FuturaBT-Medium outline-0 text-gray-400"}
                placeholder={'Tulis Nama Group'} onChange={(e) => setGroupName(e.target.value)}/>
            <p className={"text-milano-red text-sm h-5"}>{groupName.length === 0 ? 'Nama Group wajib diisi' : ''}</p>
            <div className={"flex flex-row items-center justify-between mt-10"}>
                <div className={"flex flex-row w-1/2 items-center justify-between"}>
                    <div>
                        <p className={"font-FuturaBT-Medium font-bold"}>Member</p>
                        <p className={"font-FuturaBT-Book text-sm mt-2"}>{userId.length} Member</p>
                    </div>
                    <button className={"flex flex-row items-center bg-milano-red px-5 h-10 disabled:bg-opacity-70 hover:bg-opacity-90"}
                            disabled={selectedRowKeys.length === 0}
                            onClick={() => addInfluencerHandler()}
                    >
                        <img className={"filter-icon-resp"} src={AddInfluencer} alt={'AddInfluencer'}/>
                        <p className={"text-white text-sm"}>Add Influencer</p>
                    </button>
                </div>
                <button className={"flex flex-row items-center bg-milano-red px-5 h-10 disabled:bg-opacity-70"}
                        disabled={groupName.length === 0}
                        onClick={createGroup}>
                    <p className={"text-white text-sm"}>Add Group</p>
                </button>
            </div>
            <div className={"flex flex-row items-center justify-between mt-10"}>
                <div className={"flex flex-row space-x-2"}>
                    <PopoverFilterInstagramTiktokComponent/>
                    <ExportComponent dataSource={prepareDataForExport()} fileName={"User List Group"} />
                    <SendEmailComponent/>
                    <PageSizeComponent/>
                </div>
                <SearchComponent placeholder={"Search"}/>
            </div>
            <GoddessGangUserListComponent/>
            <SuccessModal message={'Group has been created successfully!'}/>
        </>
    )
}

export default CreateGroupPage

import React from "react";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {useDispatch, useSelector} from "react-redux";
import {TOOLS_REDUCER} from "../../reducers/tools.reducer";
import PopoverFilterInstagramTiktokComponent from "../../components/popover-filter-instagram-tiktok.component";
import DatePickerComponent from "../../components/date-picker.component";
import SelectComponent from "../../components/select.component";
import SearchComponent from "../../components/search.component";

const RejectedFilterComponent = () => {
    const dispatch = useDispatch()
    const {pageSize} = useSelector(state => state.toolsReducer)

    return (
        <div className={"flex flex-row justify-between font-size-resp"}>
            <div className={"flex flex-row items-center"}>
                <button className={"height-resp xl:w-24 2xl:w-28 bg-milano-red"}
                        onClick={() => dispatch({type: MODAL_REDUCER, payload: {display: 'block'}})}>
                    <p className={"xl:text-xs 2xl:text-sm text-white font-FuturaBT-Medium uppercase"}>Update User</p>
                </button>
                <div className={"flex flex-row items-center font-FuturaBT-Book ml-2"}>
                    <input className={"border w-10 height-resp p-2 text-center font-size-resp"} value={pageSize}
                           onChange={(e) => dispatch({type: TOOLS_REDUCER, payload: {pageSize: e.target.value}})}/>
                    <p className={"flex flex-row ml-2 "}>/Page</p>
                </div>
            </div>
            <div className={"flex flex-row space-x-2 items-center"}>
                <PopoverFilterInstagramTiktokComponent/>
                <DatePickerComponent/>

                <SelectComponent title={'All Sosmed'}/>
                <SelectComponent title={'City'}/>

                <SearchComponent placeholder={'Search'}/>
            </div>
        </div>
    )
}

export default RejectedFilterComponent

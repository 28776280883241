import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import XIcon from "../../images/svg/reject-black.svg"
import NoImage from "../../images/no-image.jpg";
import Edit from "../../images/svg/ico-edit.svg";
import Folder from "../../images/svg/ico-folder.svg";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {setSendNotificationApi} from "../../api/user.api";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";

const SendNotificationModal = (props) => {
    const {userIds} = props
    const dispatch = useDispatch()
    const {sendNotificationModalVisibility} = useSelector(state => state.modalReducer)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [imagePreview, setImagePreview] = useState()
    const [thumbnailImage, setThumbnailImage] = useState({})

    const imageProcessing = (event) => {
        const thumbnail = event.target.files[0]
        if (thumbnail.type.includes('image')) {
            let reader = new FileReader();
            reader.onloadend = () => {
                setThumbnailImage(thumbnail)
                let image = reader.result
                setImagePreview(image)
            }
            reader.readAsDataURL(thumbnail)
        } else {
            alert('Mohon masukkan file image')
        }
    }

    const sendNotification = () => {
        const form = new FormData();
        form.append('title', title)
        form.append('description', description)
        form.append('user_ids', JSON.stringify(userIds))
        form.append('image', thumbnailImage)

        setSendNotificationApi(form)
            .then(response => {
                setTitle('')
                setDescription('')
                setThumbnailImage({})
                setImagePreview(null)
                dispatch({type: MODAL_REDUCER,
                    payload: {
                        sendNotificationModalVisibility: 'hidden',
                        userRegisteredText: 'Notification has successfully send',
                        display: 'block'
                    }
                })
                dispatch({type: USER_REGISTERED_REDUCER, payload: {selectedRowKeys: [], selectedRows: []}})
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div
            className={`${sendNotificationModalVisibility} fixed flex z-50 left-0 top-0 w-full h-full justify-center items-center block bg-gray-900 bg-opacity-40`}>
            <div
                className="flex flex-col w-1/4 h-2/3 bg-white border font-FuturaBT-Medium bg-gray-300 p-5">
                <div className={"flex flex-row justify-between"}>
                    <div/>
                    <p className={"text-tiny"}>Form Send Notification</p>
                    <img src={XIcon} className={"w-5 h-5 hover:cursor-pointer"} onClick={() => dispatch({
                        type: MODAL_REDUCER,
                        payload: {sendNotificationModalVisibility: 'hidden'}
                    })}/>
                </div>
                <div className={"flex flex-col w-full overflow-scroll mt-5"}>
                    <input className={"outline-0 bg-transparent border-b border-gray-400 mt-5 text-sm"} value={title}
                           placeholder={"Title Notification"} onChange={(e) => setTitle(e.target.value)}/>
                    {title.length === 0 && <p className={"text-milano-red text-xs"}>Title wajib diisi</p>}
                    <div className={"w-full"}>
                    <textarea className={"outline-0 w-full bg-transparent border-b border-gray-400 mt-10 text-sm"}
                              placeholder={"Description Notification"}
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}/>
                    </div>
                    <div className={"relative mt-10"}>
                        <img src={!!imagePreview ? imagePreview : NoImage} className={"w-full object-cover"}/>
                        <label
                            className={"border border-milano-red absolute top-0 right-0 p-1 rounded-full m-2 bg-linen cursor-pointer"}>
                            <img src={Edit} className={" w-4 h-4 "}/>
                            <input type={"file"} className={"hidden absolute top-0 right-0"} name={"thumbnail"}
                                   onChange={(e) => imageProcessing(e)}
                            />
                        </label>
                    </div>
                    <div className={"mt-5"}>
                        <p className={"text-tiny font-FuturaBT-Medium"}>Gambar Thumbnail</p>
                        <div className={"flex flex-row items-center space-x-2"}>
                            <img src={Folder} className={" w-6 h-6 "}/>
                            <p className={"text-xs text-gray-400"}>{!!thumbnailImage.name ? thumbnailImage.name : 'No Image'}</p>
                        </div>
                    </div>
                    <div className={"flex flex-row w-full justify-end"}>
                        <button className={"bg-milano-red text-white text-sm px-5 py-1 disabled:opacity-60"}
                                disabled={title.length === 0}
                                onClick={() => sendNotification()}
                        >Kirim
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SendNotificationModal

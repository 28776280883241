import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CAMPAIGN_REDUCER} from "../reducers/campaign.reducer";

const InputTagComponent = (props) => {
    const dispatch = useDispatch()
    const [inputValue, setInputValue] = useState('')
    const [tags, setTags] = useState([])
    const {type} = props
    const {mention, hashtag} = useSelector(state => state.campaignReducer)

    // useEffect(() => {
    //
    // }, [mention, hashtag])

    const deleteTag = (item, index) => {
        let dataSelect = tags.filter((i, idx) => index !== idx)
        setTags(dataSelect)
        let payload = {[type]: dataSelect}
        dispatch({type: CAMPAIGN_REDUCER, payload})
    }

    const inputHandler = (key) => {
        if (key === "Enter") {
            let data = tags.concat([inputValue])
            let payload = {[type]: data}
            setTags(data)
            setInputValue('')
            dispatch({type: CAMPAIGN_REDUCER, payload})
        }
    }

    return (
        <div className={"text-sm space-y-1"}>
            <p className={"font-bold"}>{type === 'hashtag' ? 'Hashtag' : 'Mention'}</p>
            <div className={"flex flex-row items-center space-x-2 border h-9 px-3"}>
                {
                    tags.map((item, index) => (
                        <div key={index}
                             className={"flex flex-row items-center justify-between bg-gray-200 space-x-1 px-2 h-7 text-gray-700"}>
                            <p>{item}</p>
                            <button onClick={(item) => deleteTag(item, index)}>x</button>
                        </div>
                    ))
                }
                <input className={"outline-none"}
                       id={type}
                       name={type}
                       type={"text"}
                       value={inputValue}
                       placeholder={`Add new ${type === 'hashtag' ? 'hashtag' : 'mention'}`}
                       onChange={(e) => setInputValue(e.target.value)}
                       onKeyDown={(e) => inputHandler(e.key)}
                />
            </div>
        </div>
    )
}

export default InputTagComponent

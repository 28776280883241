import React from "react";
import Check from "../images/svg/ico-check.svg";
import Reset from "../images/svg/reset.svg";
import Approved from "../images/svg/approve-green.svg";
import {useDispatch, useSelector} from "react-redux";
import {USER_REGISTERED_REDUCER} from "../reducers/user-registered.reducer";

const PopoverCorrectAnsComponent = () => {
    const dispatch = useDispatch()
    const {reload, min_correct, max_correct} = useSelector(state => state.userRegisteredReducer)

    const onChangeAnswer = (payload) => {
        dispatch({type: USER_REGISTERED_REDUCER, payload})
    }
    return (
        <div className="relative group">
            <button className={"btn xl:w-7 2xl:w-8 height-resp justify-center"}>
                <img className={"filter-icon-resp"} src={Check} alt={'check'}/>
            </button>
            <div className={"absolute invisible group-hover:visible"}>
                <div className={"p-3 border bg-white w-72 font-FuturaBT-Book mt-2"}>
                    <div className={"flex flex-row items-center justify-center"}>
                        <p className={"text-milano-red text-tiny font-FuturaBT-Medium "}>Filter By Correct
                            Answer</p>
                    </div>
                    <div
                        className={"flex flex-row items-center justify-between text-sm mt-5"}>
                        <div>
                            <p>Min. Correct Ans.</p>
                            <input value={min_correct} className={"input-small"} type={'number'}
                                   onChange={(e) => onChangeAnswer({min_correct: e.target.value})}
                            />
                        </div>
                        <p>to</p>
                        <div>
                            <p>Max. Correct Ans.</p>
                            <input value={max_correct} className={"input-small"} type={'number'}
                                   onChange={(e) => onChangeAnswer({max_correct: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className={"flex flex-row items-center justify-end mt-5"}>
                        <button className={"flex flex-row"}
                                onClick={() => dispatch({type: USER_REGISTERED_REDUCER, payload: {min_correct: 0, max_correct: 0}})}
                        >
                            <img className={"w-4 h-4"} src={Reset} alt={'reset'}/>
                            <p className={"text-sm text-gray-400"}>Reset</p>
                        </button>
                        <button className={"flex flex-row items-center"}
                                onClick={() => dispatch({type: USER_REGISTERED_REDUCER, payload: {reload: !reload}})}>
                            <img className={"w-8 h-8"} src={Approved} alt={'reset'}/>
                            <p className={"text-tiny text-green-700"}>Apply</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopoverCorrectAnsComponent

import React, {useState} from "react";
import Filter from "../images/svg/ico-filter.svg";
import Reset from "../images/svg/reset.svg";
import CheckGrey from "../images/svg/ico-check-grey.svg";
import CheckRed from "../images/svg/ico-check-red.svg";
import {useDispatch, useSelector} from "react-redux";
import {CAMPAIGN_REDUCER} from "../reducers/campaign.reducer";

const FilterStatusComponent = () => {
    const dispatch = useDispatch()
    const [isVisible, setIsVisible] = useState(false)
    const {status} = useSelector(state => state.campaignReducer)
    return (
        <div className={"font-FuturaBT-Book"}>
            <button className={"border p-1"} onClick={() => setIsVisible(!isVisible)}>
                <img className={"w-5 h-5"} src={Filter} alt={'filter'}/>
            </button>
            <div className={` ${isVisible ? 'visible' : 'invisible'} absolute border mt-2 p-2 space-y-5 bg-white`}>
                <div className={"flex flex-row justify-between"}>
                    <p className={"text-sm "}>Status</p>
                    <div className={"flex flex-row items-center hover:cursor-pointer"} onClick={() => dispatch({type: CAMPAIGN_REDUCER, payload: {status: ''}})} >
                        <img className={"w-3 h-3"} src={Reset}/>
                        <label className={"text-sm text-gray-400"}>Reset</label>
                    </div>
                </div>
                <button
                    className={`rounded-full border px-1 flex flex-row items-center justify-between space-x-2 ${status === 'Publish Konten' && 'border-milano-red'}`}
                    onClick={() => dispatch({type: CAMPAIGN_REDUCER, payload: {status: 'Publish Konten'}})}>
                    <p className={`text-sm ${status === 'Publish Konten' ? 'text-milano-red' : 'text-gray-400'}`}>
                        Konten Published
                    </p>
                    <img className={"w-3 h-3"} src={status === 'Publish Konten' ? CheckRed : CheckGrey}/>
                </button>
            </div>
        </div>
    )
}

export default FilterStatusComponent

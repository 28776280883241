const INITIAL_STATE = {
    description: '',
    shipment_instruction: '',
    invitation_group: [],
    invitation_user: [],
    products: [],
    thumbnail: {},
    selectDataProduct: [],
    selectDataEmail: [],
    selectDataGroup: [],
    tiktok_task_content: '',
    tiktok_task_mention: '',
    tiktok_task_hashtag: '',
    youtube_task_content: '',
    youtube_task_mention: '',
    youtube_task_hashtag: '',
    instagram_task_content: '',
    instagram_task_mention: '',
    instagram_task_hashtag: '',
    facebook_task_content: '',
    facebook_task_mention: '',
    facebook_task_hashtag: '',
    createCampaignErrorMessage: {},
    createCampaignDetail: {},
    campaignDetailEdit: {}
}

export const CREATE_CAMPAIGN_REDUCER = 'CREATE_CAMPAIGN_REDUCER';
export const FLUSH_CAMPAIGN_REDUCER = 'FLUSH_CAMPAIGN_REDUCER';

export const createCampaignReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_CAMPAIGN_REDUCER:
            return {...state, ...action.payload}
        case FLUSH_CAMPAIGN_REDUCER:
            return {...state, ...INITIAL_STATE}
        default:
            return state
    }
}

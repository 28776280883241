import React, {useState} from "react";
import ImageIcon from "../../images/svg/ico-plus-img.svg";
import ImageIconGray from "../../images/svg/ico-img-grey.svg";
import FileNewField from "../../images/svg/ico-new-field.svg";
import {uploadImageQuestionerApi} from "../../api/questioner.api";
import {cloneDeep, filter} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {QUESTIONER_REDUCER} from "../../reducers/questioner.reducer";

const AddNewQuestionComponent = () => {
    const dispatch = useDispatch()
    const formRefCreate = React.createRef()
    const {questionerFormDetail} = useSelector(state => state.questionerReducer)
    const [optionError, setOptionError] = useState(false)
    const [titleError, setTitleError] = useState(false)
    const [questionDetail, setQuestionDetail] = useState({
        question: '',
        image: null,
        type: 'radio',
        sequence: null,
        is_published: 0,
        options: []
    })
    let [options, setOptions] = useState([])

    const insertImage = (event) => {
        const thumbnail = event.target.files[0]
        if (thumbnail.type.includes('image')) {
            let reader = new FileReader();
            reader.onloadend = () => {
                uploadImageQuestioner(thumbnail)
            }
            reader.readAsDataURL(thumbnail)
        } else {
            alert('Mohon masukkan file image')
        }
    }

    const insertImageOptions = (event) => {
        const thumbnail = event.target.files[0]
        if (thumbnail.type.includes('image')) {
            let reader = new FileReader();
            reader.onloadend = () => {
                uploadImage(thumbnail)
            }
            reader.readAsDataURL(thumbnail)
        } else {
            alert('Mohon masukkan file image')
        }
    }

    const uploadImage = (image) => {
        const form = new FormData();
        form.append('image', image)
        uploadImageQuestionerApi(form)
            .then(response => {
                let data = [{
                    type: 'image',
                    is_correct: false,
                    label: response
                }]
                let newOptionsLocal = options.concat(data)
                setOptions(newOptionsLocal)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const uploadImageQuestioner = (image) => {
        const form = new FormData();
        form.append('image', image)
        uploadImageQuestionerApi(form)
            .then(response => {
                setQuestionDetail({...questionDetail, image: response})
            })
            .catch(err => {
                console.log(err)
            })
    }

    const deleteDataFromOptionList = (index) => {
        let newOptions = filter(options, (item, idx) => {
            return idx !== index
        })
        setOptions(newOptions)
    }

    const onSubmit = () => {
        const formValue = document.getElementById("myForm").elements

        if (formValue.question.value.length > 0) {
            const optionValue = formValue.options

            options.map((item, index) => {
                item.is_correct = optionValue[index].checked
                return item
            })

            let is_correct_available = false

            options.filter(item => {
                if (item.is_correct === true) {
                    is_correct_available = true
                }
            })
            if (is_correct_available) {
                questionDetail.options = options
                let unpublishedList = [questionDetail].concat(questionerFormDetail.detail_unpublished)
                unpublishedList.map((item, index) => {
                    item.sequence = index + 1
                })
                questionerFormDetail.detail_unpublished = unpublishedList
                questionerFormDetail.details = questionerFormDetail.detail_published.concat(unpublishedList)
                dispatch({type: QUESTIONER_REDUCER, payload: {questionerFormDetail}})
            } else {
                setOptionError(true)
            }
        } else {
            setTitleError(true)
        }
    }

    return (
        <div className={"border w-full mt-5 p-3 border-milano-red"}>
            <form id="myForm" ref={formRefCreate} className={"flex flex-col space-y-5"}>
                <div>
                    <div className={"flex flex-row items-center justify-between "}>
                        <input name={"question"}
                               className={"outline-none border w-11/12 h-7 px-3 hover:border-milano-red"}
                               onChange={(e) => {
                                   setQuestionDetail({...questionDetail, question: e.target.value})
                               }}/>
                        <label className={"flex flex-row items-center cursor-pointer"}>
                            <img src={ImageIcon} className={"w-7 h-7"}/>
                            <input type={"file"} className={"hidden absolute top-0 right-0"}
                                   name={"thumbnail"}
                                   onChange={(e) => insertImage(e)}/>
                        </label>
                    </div>
                    {titleError && <p className={"mt-3 text-milano-red"}>Harus diisi!</p>}
                </div>
                {
                    !!questionDetail?.image &&
                    <div className={"flex flex-row items-center space-x-5"}>
                        <img src={questionDetail?.image} className={"w-64"}/>
                        <button className={"text-xl text-gray-400"}
                                onClick={() => setQuestionDetail({...questionDetail, image: null})}>x
                        </button>
                    </div>
                }
                {
                    options.map((option, index) => (
                        <div className={"flex flex-row items-center w-2/5 justify-between"} key={index}>
                            <div className={"flex flex-row items-center space-x-5 w-11/12"}>
                                <input type={"radio"} name={"options"} className={"accent-milano-red"}/>
                                {
                                    option.type === 'image' ?
                                        <img name={"optionsLabel"} src={option.label} className={"w-20"}/> :
                                        <input name={"optionsLabel"} defaultValue={option.label}
                                               className={"outline-none w-full focus:border-b focus:border-milano-red"}
                                               onChange={(e) => {
                                                   options[index].label = e.target.value
                                                   setOptions(options)
                                               }}
                                        />
                                }
                            </div>
                            <div className={"text-xl text-gray-400 cursor-pointer"}
                                 onClick={() => deleteDataFromOptionList(index)}>x
                            </div>
                        </div>
                    ))
                }
                <div className={"flex flex-row items-center w-2/5 justify-between"}>
                    <div className={"flex flex-row items-center space-x-5 w-11/12"}>
                        <input type={"radio"} name={"options"} className={"accent-milano-red"}/>
                        <label className={"italic text-soft-dimgray cursor-pointer"} onClick={() => {
                            let data = [{
                                type: 'text',
                                is_correct: false,
                                label: 'Opsi baru'
                            }]
                            let newDataOptions = cloneDeep(options)
                            let newOptionsLocal = newDataOptions.concat(data)
                            setOptions(newOptionsLocal)
                        }}>Tambah opsi</label>
                    </div>
                    <label className={"cursor-pointer"}>
                        <img src={ImageIconGray}/>
                        <input type={"file"} className={"hidden absolute top-0 right-0"}
                               name={"thumbnail"}
                               onChange={(e) => insertImageOptions(e)}/>
                    </label>
                </div>
            </form>
            {optionError && <p className={"mt-3 text-milano-red"}>Opsi yang benar harus dipilih!</p>}
            <div className={"flex flex-row-reverse"}>
                <button className={"flex flex-row items-center justify-center bg-milano-red text-white pr-2"}
                        onClick={() => onSubmit()}
                >
                    <img src={FileNewField} className={"w-7"}/>
                    <p className={"font-bold"}>Add New Field</p>
                </button>
            </div>

        </div>
    )
}

export default AddNewQuestionComponent

import React, {useState} from "react";
import Filter from "../images/svg/ico-filter.svg";
import Reset from "../images/svg/reset.svg";
import {useDispatch, useSelector} from "react-redux";
import {USER_REGISTERED_REDUCER} from "../reducers/user-registered.reducer";

const PopoverFilterInstagramTiktokComponent = () => {
    const dispatch = useDispatch()
    const {userRegisteredReducer} = useSelector(state => state)

    const [socialMediaFilterList] = useState([
        {
            label: 'Instagram',
            component: [
                {
                    label: 'Followers',
                    min: 'ig_followers_min',
                    max: 'ig_followers_max'
                }, {
                    label: 'ER',
                    min: 'ig_er_min',
                    max: 'ig_er_max'
                }, {
                    label: 'Reach',
                    min: 'ig_reach_min',
                    max: 'ig_reach_max'
                }
            ]
        },
        {
            label: 'Tiktok',
            component: [
                {
                    label: 'Followers',
                    min: 'tiktok_followers_min',
                    max: 'tiktok_followers_max'
                }, {
                    label: 'ER',
                    min: 'tiktok_er_min',
                    max: 'tiktok_er_max'
                }, {
                    label: 'Reach',
                    min: 'tiktok_reach_min',
                    max: 'tiktok_reach_max'
                }
            ]
        }
    ])

    const onChangeInput = (item, value) => {
        dispatch({type: USER_REGISTERED_REDUCER, payload: {[item]: value}})
    }

    const onClickReset = (label) => {
        if (label === 'Instagram') {
            dispatch({
                type: USER_REGISTERED_REDUCER, payload: {
                    ig_followers_min: 0,
                    ig_followers_max: 0,
                    ig_er_min: 0,
                    ig_er_max: 0,
                    ig_reach_min: 0,
                    ig_reach_max: 0
                }
            })
        } else {
            dispatch({
                type: USER_REGISTERED_REDUCER, payload: {
                    tiktok_followers_min: 0,
                    tiktok_followers_max: 0,
                    tiktok_er_min: 0,
                    tiktok_er_max: 0,
                    tiktok_reach_min: 0,
                    tiktok_reach_max: 0,
                }
            })
        }
    }

    return (
        <div className="relative font-FuturaBT-Book text-sm group">
            <button className={"btn p-2 height-resp"}>
                <img className={"img-4-responsive"} src={Filter} alt={'filter'}/>
            </button>
            <div className={"absolute invisible group-hover:visible"}>
                <div className={"flex flex-row bg-white border p-4"}>
                    {
                        socialMediaFilterList.map((socialMedia, idx) => (
                            <div key={idx.toString()} className={"flex flex-row"}>
                                <div key={idx.toString()}>
                                    <p className={"font-FuturaBT-Medium"}>{socialMedia.label}</p>
                                    {
                                        socialMedia.component.map((item, index) => (
                                            <div className={"mt-5"} key={index.toString()}>
                                                <p>{item.label}</p>
                                                <div className={"flex flex-row space-x-2 items-center mt-3"}>
                                                    <input className={"input-small h-9 border-gray-300 p-1"}
                                                           placeholder={'0'} type={"number"}
                                                           value={userRegisteredReducer[item.min]}
                                                           onChange={(e) => onChangeInput(item.min, e.target.value)}
                                                    />
                                                    <p>-</p>
                                                    <input className={"input-small h-9 border-gray-300 p-1"}
                                                           placeholder={'Max'} type={"number"}
                                                           value={userRegisteredReducer[item.max]}
                                                           onChange={(e) => onChangeInput(item.max, e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <button className={"flex flex-row w-16"} onClick={() => onClickReset(socialMedia.label)}>
                                    <img className={"w-4 h-4"} src={Reset} alt={'reset'}/>
                                    <p className={"text-sm text-gray-400"}>Reset</p>
                                </button>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default PopoverFilterInstagramTiktokComponent

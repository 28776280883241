import React, {useState} from "react";
import ArrowDown from "../images/svg/arrow-down.svg";
import ArrowUp from "../images/svg/arrow-up.svg";
import {useDispatch} from "react-redux";
import {TOOLS_REDUCER} from "../reducers/tools.reducer";

const SortingTableComponent = (props) => {
    const dispatch = useDispatch()
    const {orderBy} = props
    const [descending, setDescending] = useState(true)

    const onClickButtonSortHandler = () => {
        setDescending(!descending)
        dispatch({type: TOOLS_REDUCER, payload: {orderBy: orderBy, sort: !descending ? 'desc' : 'asc'}})
    }

    return (
        <button onClick={onClickButtonSortHandler}>
            <img className={"img-2-responsive"} src={descending ? ArrowDown : ArrowUp}/>
        </button>
    )
}

export default SortingTableComponent

import React, {useEffect} from "react";
import CampaignDetailComponent from "./campaign-detail.component";
import UserFilterComponent from "./user-filter.component";
import UserListComponent from "./user-list.component";
import CampaignTabComponent from "./campaign-tab.component";
import SuccessModal from "../../components/success.modal";
import {useDispatch, useSelector} from "react-redux";
import ContentModal from "./content.modal";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";
import {APPROVAL_CAMPAIGN_REDUCER} from "../../reducers/approval-campaign.reducer";

const ApprovalCampaignPage = () => {
    const dispatch = useDispatch()
    const {alertMessage, userDetailDraftContent} = useSelector(state => state.approvalCampaignReducer)

    useEffect(() => {
        dispatch({type: USER_REGISTERED_REDUCER, payload: {selectedRowKeys: [], selectedRows: []}})
        dispatch({type: APPROVAL_CAMPAIGN_REDUCER, payload: {activeTab: 'Waiting Approval'}})
    }, [])
    return (
        <div>
            <CampaignTabComponent />
            <CampaignDetailComponent/>
            <UserFilterComponent/>
            <UserListComponent />
            <SuccessModal message={alertMessage}/>
            {!!userDetailDraftContent.full_name && <ContentModal/>}
        </div>
    )
}

export default ApprovalCampaignPage

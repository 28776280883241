import './App.css';
import {Provider} from "react-redux";
import {applyMiddleware, createStore} from 'redux'
import rootReducer from './reducers'
import thunk from 'redux-thunk';
import {BrowserRouter} from "react-router-dom";
import DashboardComponent from "./components/dashboard.component";
import RoutesPage from "./routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const store = createStore(rootReducer, {}, applyMiddleware(thunk))

function App() {
  return (
      <Provider store={store}>
        <BrowserRouter>
          <DashboardComponent content={<RoutesPage/>}/>
        </BrowserRouter>
      </Provider>
  );
}

export default App;

import React from "react";
import CheckedGreen from "../../images/svg/approve-green.svg";
import RejectRed from "../../images/svg/reject-red.svg";
import ExportComponent from "../../components/export.component";
import PageSizeComponent from "../../components/page-size.component";
import SearchComponent from "../../components/search.component";
import FilterPotentialInfluencerComponent from "../../components/filter-potential-influencer.component";
import {useDispatch, useSelector} from "react-redux";
import {
    approvalUserToEnterCampaignApi,
    approveDraftContentApi,
    doneCampaignApi,
    sendProductApi
} from "../../api/campaign.api";
import {useParams} from "react-router-dom";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {APPROVAL_CAMPAIGN_REDUCER} from "../../reducers/approval-campaign.reducer";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";
import {formatDateToReadable} from "../../ui-util/general-variable";

const UserFilterComponent = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const {selectedRowKeys} = useSelector(state => state.userRegisteredReducer)
    const {reload, activeTab, userList} = useSelector(state => state.approvalCampaignReducer)

    const approvalUserList = (status) => {
        let requestBody = {
            status,
            user_ids: selectedRowKeys
        }

        approvalUserToEnterCampaignApi(id, requestBody)
            .then(response => {
                let message = status === 'Reject' ? 'Successfully reject user' : 'Successfully approve user'
                dispatch({type: APPROVAL_CAMPAIGN_REDUCER, payload: {alertMessage: message, reload: !reload}})
                dispatch({type: MODAL_REDUCER, payload: {display: 'block'}})
                dispatch({type: USER_REGISTERED_REDUCER, payload: {selectedRowKeys: [], selectedRows: []}})
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const statusDefine = (statusText) => {
        switch (statusText) {
            case "Done":
                return 'Detail Content'
                break;
            case "Konten sedang direview":
                return 'List Draft Content'
                break;
            default:
                return statusText
        }
    }

    const prepareDataForExport = () => {
        let dataNew = []

        switch (activeTab) {
            case 'Send Product':
                userList.forEach(item => {
                    let a = {}
                    if(!!item.campaign_users[0].campaign_products){
                        item.campaign_users[0].campaign_products.forEach((product, index) => {
                            if (index === 0) {
                                a = {
                                    "Name": item.full_name,
                                    "Email": item.email,
                                    "Phone": item.phone,
                                    "Date of Birth": formatDateToReadable(item.birth_date),
                                    "Total Point": `${item.point} Point`,
                                    "Tanggal Join Campaign": formatDateToReadable(item.campaign_users[0].created_at),
                                    "Detail Alamat": `${item?.campaign_user_addresses[0].address}, ${item?.campaign_user_addresses[0].district}, ${item?.campaign_user_addresses[0].city}, ${item?.campaign_user_addresses[0].province}`,
                                    "Notes": item?.campaign_user_addresses[0].description,
                                    "Status": statusDefine(item.campaign_users[0].status),
                                    "Nama Produk": product.product.full_name
                                }
                            } else {
                                a = {
                                    "Name": '',
                                    "Email": '',
                                    "Phone": '',
                                    "Date of Birth": '',
                                    "Total Point": '',
                                    "Tanggal Join Campaign": '',
                                    "Detail Alamat": '',
                                    "Notes": '-',
                                    "Status": '',
                                    "Nama Produk": product.product.full_name
                                }
                            }
                            dataNew.push(a)
                        })
                    }
                })
                break;
            default:
                userList.forEach(item => {
                    let a = {
                        "Name": item.full_name,
                        "Email": item.email,
                        "Date of Birth": formatDateToReadable(item.birth_date),
                        "Total Point": `${item.point} Point`,
                        "Tanggal Join Campaign": formatDateToReadable(item.campaign_users[0].created_at),
                        "Status": statusDefine(item.campaign_users[0].status)
                    }
                    dataNew.push(a)
                })
        }

        return dataNew
    }

    const sendProduct = () => {
        let requestBody = {
            user_id: selectedRowKeys
        }
        sendProductApi(id, requestBody)
            .then(response => {
                dispatch({
                    type: APPROVAL_CAMPAIGN_REDUCER,
                    payload: {alertMessage: 'Successfully updated status', reload: !reload}
                })
                dispatch({type: MODAL_REDUCER, payload: {display: 'block'}})
                dispatch({type: USER_REGISTERED_REDUCER, payload: {selectedRowKeys: [], selectedRows: []}})
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const approveDraftContent = () => {
        let requestBody = {
            user_ids: selectedRowKeys
        }
        approveDraftContentApi(id, requestBody)
            .then(response => {
                dispatch({
                    type: APPROVAL_CAMPAIGN_REDUCER,
                    payload: {alertMessage: 'Successfully approve draft content', reload: !reload}
                })
                dispatch({type: MODAL_REDUCER, payload: {display: 'block'}})
                dispatch({type: USER_REGISTERED_REDUCER, payload: {selectedRowKeys: [], selectedRows: []}})
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const doneCampaign = () => {
        let requestBody = {
            user_ids: selectedRowKeys
        }
        doneCampaignApi(id, requestBody)
            .then(response => {
                dispatch({
                    type: APPROVAL_CAMPAIGN_REDUCER,
                    payload: {alertMessage: 'Successfully done user content', reload: !reload}
                })
                dispatch({type: MODAL_REDUCER, payload: {display: 'block'}})
                dispatch({type: USER_REGISTERED_REDUCER, payload: {selectedRowKeys: [], selectedRows: []}})
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const rightItemFilter = () => {
        switch (activeTab) {
            case 'Waiting Approval':
            case 'Approve':
                return <SearchComponent placeholder={'Search'}/>
                break;
            case 'Send Product':
                return <button className={"bg-milano-red text-white h-7 text-sm px-4 disabled:opacity-60"}
                               onClick={sendProduct} disabled={selectedRowKeys.length === 0}>Kirim Product</button>
                break;
            case 'Draft Konten':
                return <button className={"bg-milano-red text-white h-7 text-sm px-4 disabled:opacity-60"}
                               onClick={approveDraftContent} disabled={selectedRowKeys.length === 0}>Approve Draft
                    Content</button>
                break;
            case 'Publish Konten':
                return <button className={"bg-milano-red text-white h-7 text-sm px-4 disabled:opacity-60"}
                               onClick={doneCampaign} disabled={selectedRowKeys.length === 0}>Done</button>
                break;

        }
    }

    return (
        <div className={"font-FuturaBT-Medium mt-10"}>
            <div className={"flex flex-row justify-between items-end"}>
                <div className={"flex flex-row space-x-1 items-end"}>
                    {
                        activeTab === 'Waiting Approval' &&
                        <div>
                            <p className={"text-tiny"}>Approval</p>
                            <div className={"flex flex-row items-center space-x-1 mt-2"}>
                                <button className={"border disabled:opacity-40"} disabled={selectedRowKeys.length === 0}
                                        onClick={() => approvalUserList('Approve')}>
                                    <img src={CheckedGreen} className={"w-7 h-7"}/>
                                </button>
                                <button className={"border disabled:opacity-40"} disabled={selectedRowKeys.length === 0}
                                        onClick={() => approvalUserList('Reject')}>
                                    <img src={RejectRed} className={"w-7 h-7"}/>
                                </button>
                            </div>
                        </div>
                    }
                    <FilterPotentialInfluencerComponent/>
                    <ExportComponent dataSource={userList.length > 0 ? prepareDataForExport() : []} fileName={'Campaign User List'}/>
                    {(activeTab === 'Send Product' || activeTab === 'Draft Konten' || activeTab === 'Publish Konten') &&
                        <SearchComponent placeholder={'Search'}/>}
                    <PageSizeComponent/>
                </div>
                {rightItemFilter()}
            </div>
        </div>
    )
}

export default UserFilterComponent

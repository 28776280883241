import React from "react";
import AnalyticsData from "../../images/svg/analytics-data.svg";
import ArrowRight from "../../images/svg/arrow-right-white.svg";
import TotalFollowers from "../../images/bg-followers/bg-followers.jpg";
import TotalAge from "../../images/bg-age/bg-age.jpg";
import TotalGender from "../../images/bg-gender/bg-gender.jpg";
import {useSelector} from "react-redux";
import {formatAgeAndGender, formatFollower} from "../../ui-util/general-variable";
import {Link, useLocation} from "react-router-dom";

const SocialMediaComponent = () => {
    const location = useLocation();
    const pathRaw = location.pathname.split('/')
    const {userDetail} = useSelector(state => state.userRegisteredReducer)

    return (
        <div className={"w-full"}>
            {userDetail?.social_medias?.length > 0 &&
                userDetail?.social_medias.map((item, index) => {
                    switch (item.name) {
                        case 'Facebook':
                        case 'Instagram':
                        case 'TikTok':
                        case 'Youtube':
                            return (
                                <div className={"flex flex-row mt-10 text-xs font-FuturaBT-Book w-full"} key={index}>
                                    <img src={item.logo} className={"w-10 h-10"}/>
                                    <div className={"flex flex-col space-y-2 w-full"}>
                                        <div>
                                            <p className={"font-FuturaBT-Medium font-semibold text-base"}>{item.name}</p>
                                            <p>@{item.username}</p>
                                        </div>
                                        <div className={"flex flex-row items-center justify-between space-x-20"}>
                                            <div className={"flex flex-row items-center space-x-3"}>
                                                <img src={item.photo} className={"w-14 h-14 rounded-full"}/>
                                                <div className={"space-y-3"}>
                                                    <p className={"text-sm font-FuturaBT-Medium"}>{item.name} Bio</p>
                                                    <p className={"text-xxs w-52"}>{item.biography ? item.biography : '-'}</p>
                                                    <div>
                                                        <Link
                                                            to={`/mission/${pathRaw[2]}/user-detail/${item.id}/${userDetail?.id}`}>
                                                            <button
                                                                className={"bg-milano-red text-white px-5 h-8 flex flex-row items-center space-x-2"}>
                                                                <img src={AnalyticsData} className={"w-5 h-5"}/>
                                                                <p>See Analytics Data</p>
                                                                <img src={ArrowRight} className={"w-3 h-3"}/>
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={"flex flex-row items-center justify-between col-span-2 space-x-5 w-3/5"}>
                                                <div className={"flex"}>
                                                    <img src={TotalFollowers} className={"relative w-36"}/>
                                                    <div className={"absolute p-3 text-sm font-FuturaBT-Medium"}>
                                                        <p className={""}>Followers</p>
                                                        <p className={"text-milano-red"}>{formatFollower(item.followers)}</p>
                                                    </div>
                                                </div>
                                                <div className={"flex"}>
                                                    <img src={TotalAge} className={"relative w-36"}/>
                                                    <div className={"absolute p-3 text-sm font-FuturaBT-Medium"}>
                                                        <p className={""}>Age Distribution</p>
                                                        <p className={"text-milano-red"}>{item.age_distribution && formatAgeAndGender(item.age_distribution).data}</p>
                                                        <p className={"text-milano-red text-xs"}>{item.age_distribution && formatAgeAndGender(item.age_distribution).description}</p>
                                                    </div>
                                                </div>
                                                <div className={"flex"}>
                                                    <img src={TotalGender} className={"relative w-36"}/>
                                                    <div className={"absolute p-3 text-sm font-FuturaBT-Medium"}>
                                                        <p className={""}>Gender Distribution</p>
                                                        <p className={"text-milano-red"}>{item.gender_distribution && formatAgeAndGender(item.gender_distribution).data}</p>
                                                        <p className={"text-milano-red text-xs"}>{item.gender_distribution && formatAgeAndGender(item.gender_distribution).description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            break;
                        default:
                            return
                    }
                })
            }
        </div>
    )
}

export default SocialMediaComponent

import React, {useEffect, useState} from "react";
import CampaignDetailComponent from "./campaign-detail.component";
import CampaignTaskComponent from "./campaign-task.component";
import CampaignSendProductComponent from "./campaign-send-product.component";
import {getGroupListAllApi} from "../../api/group.api";
import {TOOLS_REDUCER} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import {getProductListApi} from "../../api/product.api";
import {createCampaignApi, updateCampaignApi} from "../../api/campaign.api";
import SuccessModal from "../../components/success.modal";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import StandardModal from "../../components/modal/standard.modal";
import {CREATE_CAMPAIGN_REDUCER, FLUSH_CAMPAIGN_REDUCER} from "../../reducers/create-campaign.reducer";
import {campaignValidation} from "../../ui-util/campaign.validation";
import {every} from "lodash";
import {useLocation} from "react-router-dom";

const CreateMissionPage = () => {
    const location = useLocation();
    const pathRaw = location.pathname.split('/')
    const dispatch = useDispatch()
    const [alertMessage, setAlertMessage] = useState('')
    const [message, setMessage] = useState('')
    const {createCampaignReducer} = useSelector(state => state)
    const {standardModalVisibility} = useSelector(state => state.modalReducer)
    const formRef = React.createRef()
    let {
        description,
        shipment_instruction,
        products,
        invitation_group,
        invitation_user,
        thumbnail,
        tiktok_task_content,
        tiktok_task_mention,
        tiktok_task_hashtag,
        youtube_task_content,
        youtube_task_mention,
        youtube_task_hashtag,
        instagram_task_content,
        instagram_task_mention,
        instagram_task_hashtag,
        facebook_task_content,
        facebook_task_mention,
        facebook_task_hashtag,
        createCampaignDetail,
        campaignDetailEdit,
        createCampaignErrorMessage
    } = useSelector(state => state.createCampaignReducer)

    useEffect(() => {
        Promise.all([getGroupListAllApi(), getProductListApi()])
            .then(response => {
                const optionGroups = response[0].map(d => ({
                    id: d.id,
                    full_name: d.name
                }))
                const optionProducts = response[1].map(d => ({
                    id: d.id,
                    full_name: d.product.name + " - " + d.name,
                    name: d.name
                }))
                dispatch({
                    type: TOOLS_REDUCER, payload: {
                        selectDataProduct: optionProducts,
                        selectDataGroup: optionGroups
                    }
                })
            })
            .catch(err => {
                console.log('Error getGroupListAllApi create mission', err.response)
            })

        return () => {
            dispatch({type: FLUSH_CAMPAIGN_REDUCER})
        }
    }, [])

    const onSubmit = (status) => {
        const form = new FormData();
        let result = dataChecking()

        if (result) {
            form.append('title', createCampaignDetail.title)
            form.append('description', description)
            form.append('point', createCampaignDetail.point)
            form.append('campaign_setting', createCampaignDetail.campaign_setting)
            form.append('registration_start_date', createCampaignDetail.registration_start_date)
            form.append('registration_end_date', createCampaignDetail.registration_end_date)
            form.append('registration_start_time', createCampaignDetail.registration_start_time)
            form.append('registration_end_time', createCampaignDetail.registration_end_time)
            form.append('campaign_start_date', createCampaignDetail.campaign_start_date)
            form.append('campaign_end_date', createCampaignDetail.campaign_end_date)
            form.append('campaign_start_time', createCampaignDetail.campaign_start_time)
            form.append('campaign_end_time', createCampaignDetail.campaign_end_time)
            form.append('status', status)
            form.append('flag_send_product', createCampaignDetail.flag_send_product)
            form.append(`products`, JSON.stringify(products))
            form.append('shipment_intructions', shipment_instruction)
            form.append('tiktok_task[content]', tiktok_task_content)
            form.append('tiktok_task[mention]', tiktok_task_mention)
            form.append('tiktok_task[hashtag]', tiktok_task_hashtag)
            form.append('instagram_task[content]', instagram_task_content)
            form.append('instagram_task[mention]', instagram_task_mention)
            form.append('instagram_task[hashtag]', instagram_task_hashtag)
            form.append('youtube_task[content]', youtube_task_content)
            form.append('youtube_task[mention]', youtube_task_mention)
            form.append('youtube_task[hashtag]', youtube_task_hashtag)
            form.append('facebook_task[content]', facebook_task_content)
            form.append('facebook_task[mention]', facebook_task_mention)
            form.append('facebook_task[hashtag]', facebook_task_hashtag)

            if (createCampaignDetail.campaign_setting === "3") {
                invitation_user.forEach((item, index) => {
                    form.append(`invitations[${index}][type]`, 'user')
                    form.append(`invitations[${index}][user_id]`, item.user_id)
                })
            } else if (createCampaignDetail.campaign_setting === "4") {
                invitation_group.forEach((item, index) => {
                    form.append(`invitations[${index}][type]`, 'group')
                    form.append(`invitations[${index}][group_id]`, item.id)
                })
            }

            if(!thumbnail.name.includes('http')){
                form.append('thumbnail', thumbnail)
            }

            if(pathRaw[3] === 'edit-campaign'){
                editMission(form, status)
            } else {
                createMission(form, status)
            }
        }
    }

    const createMission = (form, status) => {
        createCampaignApi(form)
            .then(response => {
                let message = status === 'Publish' ? 'Campaign has been created successfully!' : 'Campaign has been saved to draft successfully!'
                setAlertMessage(message)
                dispatch({type: MODAL_REDUCER, payload: {display: 'block'}})
                setTimeout(() => {
                    dispatch({type: MODAL_REDUCER, payload: {display: 'hidden'}})
                }, 2000);
                setTimeout(() => {
                    window.location.href = '/campaign/list-campaign'
                }, 1000);
            })
            .catch(err => {
                console.log(err.response)
                if (err.response.data.message.includes('You are only allowed to')) {
                    setMessage(err.response.data.message)
                    dispatch({type: MODAL_REDUCER, payload: {standardModalVisibility: 'block'}})
                }
            })
    }

    const editMission = (requestBody, status) => {
        updateCampaignApi(campaignDetailEdit?.id, requestBody)
            .then(response => {
                let message = (status === 'Publish') ? 'Campaign has been updated successfully!' : 'Campaign has been saved to draft successfully!'
                dispatch({type: MODAL_REDUCER, payload: {display: 'block', successModalText: message}})
                setTimeout(() => {
                    dispatch({type: MODAL_REDUCER, payload: {display: 'hidden'}})
                }, 2000);
                setTimeout(() => {
                    window.location.href = '/campaign/list-campaign'
                }, 1000);
            })
            .catch(err => {
                console.log(err)
                if(err.data.code === 422) {
                    dispatch({type: MODAL_REDUCER, payload: {standardModalVisibility: 'block'}})
                    setMessage(err.data.message[0].msg)
                }
            })
    }

    const dataChecking = () => {
        const x = document.getElementById("myForm").elements

        let error = {}
        campaignValidation.forEach((item) => {
            switch (item.key) {
                case 'thumbnail':
                    if ((!thumbnail.name)) {
                        error[item.key] = item.message
                    }
                    break;
                case 'description':
                    if (description.length === 0 || description === "<p><br></p>") {
                        error[item.key] = item.message
                    }
                    break;
                case 'campaign_setting':
                    if ((x[item.key].value === "3") && (invitation_user.length === 0)) {
                        error[item.key] = 'Email wajib diisi'
                    } else if ((x[item.key].value === "4") && (invitation_group.length === 0)) {
                        error[item.key] = 'Group wajib diisi'
                    } else {
                        createCampaignDetail[item.key] = x[item.key].value
                    }
                    break;
                case 'flag_send_product':
                    if ((x[item.key].value === "1")) {
                        if (products.length === 0) {
                            error[item.key] = 'Produk wajib diisi'
                        }
                        if (shipment_instruction.length === 0) {
                            error['shipment_instruction'] = 'Alamat pengiriman wajib diisi'
                        }
                    }
                    createCampaignDetail[item.key] = x[item.key].value
                    break;
                case 'task':
                    let isError = every(x[item.key], ['checked', false])
                    if (isError) {
                        error[item.key] = item.message
                    }
                    break;
                case 'tiktok_task_content':
                case 'youtube_task_content':
                case 'facebook_task_content':
                case 'instagram_task_content':
                    if(createCampaignReducer[item.key] === "<p><br></p>"){
                        error[item.key] = item.message
                    }
                    break;
                default:
                    if (!x[item.key].value) {
                        error[item.key] = item.message
                    } else {
                        createCampaignDetail[item.key] = x[item.key].value
                    }
            }
        })

        dispatch({type: CREATE_CAMPAIGN_REDUCER, payload: {createCampaignErrorMessage: error}})
        if (Object.keys(error).length === 0) {
            dispatch({type: CREATE_CAMPAIGN_REDUCER, payload: {createCampaignErrorMessage: {}}})
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <form id="myForm" ref={formRef}>
                <CampaignDetailComponent/>
                <CampaignTaskComponent/>
                <CampaignSendProductComponent/>
            </form>
            <div className={"flex flex-row-reverse mb-10 pr-10 font-FuturaBT-Medium "}>
                <button className={"border h-8 w-28 text-sm ml-5 bg-milano-red text-white "}
                        onClick={() => onSubmit('Publish')}>Publish
                </button>
                <button className={"border h-8 w-28 text-sm border-milano-red text-milano-red"}
                        onClick={() => onSubmit('Draft')}>Save as Draft
                </button>
            </div>
            <SuccessModal message={alertMessage}/>
            {
                standardModalVisibility &&
                <StandardModal type={'warning'} message={message}/>
            }
        </>
    )
}

export default CreateMissionPage

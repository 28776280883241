import React from "react";
import RedeemFilterComponent from "./redeem-filter.component";
import RedeemListComponent from "./redeem-list.component";
import SuccessModal from "../../components/success.modal";

const RedeemPage = () => {
    return (
        <div>
            <div className={"flex flex-col"}>
                <RedeemFilterComponent/>
                <RedeemListComponent/>
            </div>
            <SuccessModal message={"Successfully updated status withdraw"}/>
        </div>
    )
}

export default RedeemPage

import React, {useState} from "react";
import ArrowDown from "../../images/svg/arrow-down.svg";
import ArrowUp from "../../images/svg/arrow-up.svg";
import BarChartComponent from "../../components/bar-chart.component";
import {formatPercent} from "../../ui-util/general-variable";
import CircleChartComponent from "../../components/circle-chart.component";

const ReportDistributionComponent = (props) => {
    const [isHide, setIsHide] = useState(false)
    const {socialMediaData} = props

    return (
        <div id={"printableArea"}>
            <div
                onClick={() => setIsHide(!isHide)}
                className={"border-b flex flex-row items-center justify-between text-milano-red text-sm font-FuturaBT-Medium mt-5 pb-2 hover:cursor-pointer"}>
                <p>Distribution</p>
                <div className={"flex flex-row items-center space-x-2"}>
                    <p className={"text-black"}>Hide</p>
                    <img src={isHide ? ArrowDown : ArrowUp} className={"w-2 h-2"}/>
                </div>
            </div>
            {
                !isHide &&
                <div className={"grid grid-cols-3 gap-5 mt-5"}>
                    <BarChartComponent
                        title={'Age Distribution'}
                        tooltip={'Age distribution of influencer\'s engaged followers'}
                        data={socialMediaData?.aggregation?.age}
                    />
                    <CircleChartComponent
                        title={'Gender Distribution'}
                        tooltip={'Gender distribution of influencer\'s engaged followers'}
                        data={socialMediaData?.aggregation?.gender}
                        plotOptions={{
                            pie: {
                                dataLabels: {
                                    offset: -30,
                                },
                            }
                        }}
                        labels={['Perempuan', 'Laki-laki']}
                        type={"pie"}
                        distributeData={
                            <div className={"text-sm font-FuturaBT-Book mt-10"}>
                                <div className={"flex flex-row items-center space-x-2"}>
                                    <div className={"bg-milano-red w-2 h-2"}/>
                                    <p>Perempuan {formatPercent(socialMediaData?.aggregation?.gender[0], (socialMediaData?.aggregation?.gender[0] + socialMediaData?.aggregation?.gender[1]))} User</p>
                                </div>
                                <div className={"flex flex-row items-center space-x-2 text-gray-400"}>
                                    <div className={"bg-gray-400 w-2 h-2"}/>
                                    <p>Laki Laki {formatPercent(socialMediaData?.aggregation?.gender[1], (socialMediaData?.aggregation?.gender[0] + socialMediaData?.aggregation?.gender[1]))} User</p>
                                </div>
                            </div>
                        }
                    />
                    <BarChartComponent
                        title={'Audience Location Distribution'}
                        tooltip={'Location distribution of influencer\'s engaged followers'}
                        data={socialMediaData?.aggLocations}
                    />
                </div>
            }
        </div>
    )
}

export default ReportDistributionComponent

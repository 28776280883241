import React, {useEffect, useState} from "react";
import AddInfluencer from "../../images/svg/add-friends.svg";
import {Link, useParams} from "react-router-dom";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import Calendar from '../../images/svg/ico-calendar-red.svg'
import YoutubeRed from '../../images/svg/youtube.svg'
import TiktokRed from '../../images/svg/tiktok-outline.svg'
import Time from '../../images/svg/ico-time.svg'
import User from '../../images/svg/ico-man.svg'
import Video from '../../images/svg/ico-video.svg'
import Scenery from '../../images/svg/ico-image.svg'
import Plane from '../../images/svg/plane-outline.svg'
import {campaignDetailReportApi, shareCampaignApi} from "../../api/campaign.api";
import {CAMPAIGN_REDUCER} from "../../reducers/campaign.reducer";
import {customDateToReadable, totalCalculation} from "../../ui-util/general-variable";
import HTMLReactParser from 'html-react-parser';
import InstagramRed from "../../images/svg/insta-outline.svg";
import FacebookRed from "../../images/svg/facebook-outline.svg";
import Share from "../../images/svg/ico-share.svg";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";

const CampaignDetailComponent = () => {
    const dispatch = useDispatch()
    const {id} = useParams();
    const {campaignDetail, activeTab} = useSelector(state => state.campaignReducer)
    const [TotalList] = useState([
        {
            key: 'influencer',
            icon: User,
            label: 'Total Influencers',
            unit: 'People',
            extra: false
        }, {
            key: 'engagement',
            icon: Plane,
            label: 'Total Engagement',
            unit: 'People',
            extra: true
        }, {
            key: 'stories',
            icon: Video,
            label: 'Total Stories',
            unit: 'Story',
            extra: false
        }, {
            key: 'posts',
            icon: Scenery,
            label: 'Total Post',
            unit: 'Post',
            extra: false
        }
    ])

    useEffect(() => {
        campaignDetailReportApi(id)
            .then(response => {
                dispatch({type: CAMPAIGN_REDUCER, payload: {campaignDetail: response}})
            })
            .catch(err => console.log(err.response))
    }, [])

    const shareCampaignButton = () => {
        shareCampaignApi(id)
            .then(response => {
                dispatch({
                    type: MODAL_REDUCER,
                    payload: {display: 'block', userRegisteredText: 'Send notification success'}
                })
            })
            .catch(err => console.log(err))
    }

    return (
        <div className={"mt-10 font-FuturaBT-Book"}>
            <div className={"flex flex-row justify-between items-center"}>
                <p className={"font-bold capitalize"}>{campaignDetail?.data?.title}</p>
                <div className={"flex flex-row space-x-5"}>
                    {
                        moment(customDateToReadable(moment(), 'YYYY-MM-DD')).isBefore(campaignDetail?.data?.registration_start_date) &&
                        <button className={"flex flex-row items-center bg-milano-red px-5 h-10 hover:bg-opacity-90 space-x-2"}
                                onClick={() => shareCampaignButton()}
                        >
                            <img className={"w-4 h-4"} src={Share} alt={'Share'}/>
                            <p className={"text-white text-sm"}>Share Campaign</p>
                        </button>
                    }
                    <Link to={"/goddess-gang/all-users"}>
                        <button className={"flex flex-row items-center bg-milano-red px-5 h-10 hover:bg-opacity-90 space-x-2"}>
                            <img className={"filter-icon-resp"} src={AddInfluencer} alt={'AddInfluencer'}/>
                            <p className={"text-white text-sm"}>Add Influencer</p>
                        </button>
                    </Link>
                </div>
            </div>
            <div className={"grid grid-cols-4 gap-5 mt-5"}>
                <img src={campaignDetail?.data?.thumbnail} className={"w-full"}/>
                <div className={"text-sm font-FuturaBT-Book"}>
                    <div className={"flex flex-row justify-between"}>
                        <div className={"flex flex-col space-y-5"}>
                            <div>
                                <p className={"font-bold"}>Start date</p>
                                <div className={"flex flex-row items-center"}>
                                    <img src={Calendar} className={"w-5 h-5"}/>
                                    <p className={"text-tiny"}>{customDateToReadable(campaignDetail?.data?.campaign_start_date, 'dddd')}, {customDateToReadable(campaignDetail?.data?.campaign_start_date, 'LL')}</p>
                                </div>
                            </div>
                            <div>
                                <p className={"font-bold"}>Start time</p>
                                <div className={"flex flex-row items-center space-x-1"}>
                                    <img src={Time} className={"w-4 h-4"}/>
                                    <p className={"text-tiny"}>{campaignDetail?.data?.campaign_start_time}</p>
                                </div>
                            </div>
                            <div>
                                <p className={"font-bold"}>Point Campaign</p>
                                <p className={"text-tiny"}>{campaignDetail?.data?.point} Point</p>
                            </div>
                        </div>
                        <div className={"flex flex-col space-y-5"}>
                            <div>
                                <p className={"font-bold"}>End date</p>
                                <div className={"flex flex-row items-center"}>
                                    <img src={Calendar} className={"w-5 h-5"}/>
                                    <p className={"text-tiny"}>{customDateToReadable(campaignDetail?.data?.campaign_end_date, 'dddd')}, {customDateToReadable(campaignDetail?.data?.campaign_end_date, 'LL')}</p>
                                </div>
                            </div>
                            <div>
                                <p className={"font-bold"}>End time</p>
                                <div className={"flex flex-row items-center space-x-1"}>
                                    <img src={Time} className={"w-4 h-4"}/>
                                    <p className={"text-tiny"}>{campaignDetail?.data?.campaign_end_time}</p>
                                </div>
                            </div>
                            <div>
                                <p className={"font-bold"}>Upload</p>
                                <div className={"flex flex-row items-center space-x-4"}>
                                    {
                                        !!campaignDetail?.data?.facebook_task?.c_id &&
                                        <img src={FacebookRed} className={"w-3 h-3"}/>
                                    }
                                    {
                                        !!campaignDetail?.data?.youtube_task?.c_id &&
                                        <img src={YoutubeRed} className={"w-5 h-5"}/>
                                    }
                                    {
                                        !!campaignDetail?.data?.tiktok_task?.c_id &&
                                        <img src={TiktokRed} className={"w-3 h-3"}/>
                                    }
                                    {
                                        !!campaignDetail?.data?.instagram_task?.c_id &&
                                        <img src={InstagramRed} className={"w-3 h-3"}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p className={"font-bold text-sm"}>Deskripsi Campaign</p>
                    <div className={"border w-full outline-0 mt-2 p-3 text-sm min-h-20"}>
                        {!!campaignDetail?.data?.description && HTMLReactParser(campaignDetail?.data?.description)}
                    </div>
                </div>
                <div>
                    <div className={"grid grid-cols-2 gap-3 font-FuturaBT-Medium"}>
                        {
                            TotalList.map((item, index) => (
                                <div
                                    className={"border p-3 bg-no-repeat bg-right-bottom bg-70% bg-campaign-detail-background h-24 relative"}
                                    key={index}>
                                    <p className={"text-sm"}>{item.label}</p>
                                    <p className={"text-milano-red text-base"}>{!!campaignDetail?.calculation && totalCalculation(item.key, campaignDetail?.calculation[item.key]?.total)} {item.unit}</p>
                                    <p className={"text-xxs text-milano-red"}>{item.extra && !!campaignDetail?.calculation && campaignDetail?.calculation[item.key]?.caption}</p>
                                    <img src={item.icon} className={"w-6 h-6 absolute bottom-0 right-0"}/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignDetailComponent

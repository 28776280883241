import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {formatDateToReadable} from "../../ui-util/general-variable";
import moment from "moment";
import Approve from "../../images/svg/approve-green.svg";
import Reject from "../../images/svg/reject-red.svg";
import {approvalContentApi} from "../../api/campaign.api";
import {APPROVAL_CAMPAIGN_REDUCER} from "../../reducers/approval-campaign.reducer";
import InstagramGrey from "../../images/svg/insta-outline-grey.svg";
import FacebookGrey from "../../images/svg/facebook-outline-grey.svg";
import TiktokGrey from "../../images/svg/tiktok-outline-grey.svg";
import YoutubeGrey from "../../images/svg/youtube-grey.svg";

const ContentModal = () => {
    const dispatch = useDispatch()
    const idLocale = require('moment/locale/id');
    const {draftContentModalVisibility} = useSelector(state => state.modalReducer)
    const {
        userDetailDraftContent,
        campaignDetail,
        reload,
        contentDetail
    } = useSelector(state => state.approvalCampaignReducer)
    const [rejectNote, setRejectNote] = useState(false)
    const [noteValue, setNoteValue] = useState('')
    const [socialMediaList] = useState([
        {
            key: 'instagram_task',
            iconGrey: InstagramGrey
        }, {
            key: 'facebook_task',
            iconGrey: FacebookGrey,
        }, {
            key: 'tiktok_task',
            iconGrey: TiktokGrey,
        }, {
            key: 'youtube_task',
            iconGrey: YoutubeGrey,
        }
    ])

    const closeModal = () => {
        dispatch({type: MODAL_REDUCER, payload: {draftContentModalVisibility: 'hidden'}})
    }

    const formatDayAndDateTime = (date) => {
        moment.locale('id', idLocale);
        let day = moment(date).locale('id').format('dddd')
        let time = moment(date).format('LT')
        let dateFormat = moment(date).format("DD MMMM YYYY")

        let final = `${time} ${day} ${dateFormat}`

        return final
    }

    const approvalContent = (status) => {
        let id = contentDetail.id
        let requestBody = {
            status,
            note: noteValue
        }
        approvalContentApi(id, requestBody)
            .then(response => {
                let message = status === 'Reject' ? 'Successfully reject content' : 'Successfully approve content'
                dispatch({type: APPROVAL_CAMPAIGN_REDUCER, payload: {alertMessage: message, reload: !reload}})
                dispatch({type: MODAL_REDUCER, payload: {draftContentModalVisibility: 'hidden', display: 'block'}})
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const approvalStatusContent = () => {
        switch (contentDetail.status) {
            case 'Approve':
                return (
                    <p className={"text-green-leaf font-bold"}>Approved</p>
                )
                break;
            case 'Reject':
                return (
                    <>
                        <p className={"text-milano-red font-bold"}>Rejected</p>
                        <p>{contentDetail?.note}</p>
                    </>
                )
                break;
            default:
                return (
                    <div className={"flex-col"}>
                        <button className={"flex flex-row items-center"}
                                onClick={() => approvalContent('Approve')}
                        >
                            <img className={"w-10 h-10"} src={Approve}/>
                            <p className={"text-base text-green-leaf"}>Approve</p>
                        </button>
                        <button className={"flex flex-row items-center"}
                                onClick={() => setRejectNote(!rejectNote)}
                        >
                            <img className={"w-10 h-10"} src={Reject}/>
                            <p className={"text-base text-milano-red"}>Reject</p>
                        </button>
                        {
                            rejectNote &&
                            <div className={"flex flex-col space-y-2"}>
                                <p className={"mt-3 font-bold"}>Reject note</p>
                                <input type={'textarea'} placeholder={"Write the rejection note here!"}
                                       className={"border h-20 p-1 outline-0"}
                                       onChange={(e) => setNoteValue(e.target.value)}/>
                                <button className={"bg-gray-300 border h-10"}
                                        onClick={() => approvalContent('Reject')}>Send Rejection
                                </button>
                            </div>
                        }
                    </div>
                )
        }
    }

    return (
        <div
            className={`${draftContentModalVisibility} fixed flex left-0 top-0 w-full h-full justify-center items-center block bg-gray-900 bg-opacity-40 z-50`}>
            <div className="flex flex-col w-2/5 bg-white border font-FuturaBT-Medium">
                <div className={"bg-gray-300"}>
                    <div className={"m-5"}>
                        <div className={"flex flex-row justify-between items-center"}>
                            <p>Detail Draft Konten</p>
                            <button className={"flex justify-end"} onClick={() => closeModal()}>X</button>
                        </div>
                        <div className={"flex flex-row justify-between mt-5 text-sm"}>
                            <div>
                                <p className={"text-sm font-bold"}>Profile</p>
                                <div className={"mt-5 flex flex-row items-center space-x-2"}>
                                    <img className={"w-10 h-10"} src={userDetailDraftContent?.photo}/>
                                    <div>
                                        <p className={"font-bold"}>{userDetailDraftContent?.full_name}</p>
                                        <p>{userDetailDraftContent?.email}</p>
                                        <p>{formatDateToReadable(userDetailDraftContent?.birth_date)}</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className={"text-sm font-bold"}>Task</p>
                                <div className={"flex flex-row items-center space-x-2"}>
                                    <img className={"w-32 h-32"} src={campaignDetail?.thumbnail}/>
                                    <div className={"flex flex-col"}>
                                        <p className={"font-bold"}>{campaignDetail?.title}</p>
                                        <div className={"flex flex-row items-center space-x-1"}>
                                            {
                                                userDetailDraftContent?.campaign_user_submits.map((i, idx) => {
                                                    return (
                                                        <div key={idx}>
                                                            {
                                                                socialMediaList.map((item, index) => {
                                                                    if (item.key === i.task_name) {
                                                                        return (
                                                                            <img className={"w-4 h-4"}
                                                                                 src={item.iconGrey} key={index}/>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className={"flex flex-row items-center font-xs space-x-2"}>
                                            <p className={"text-gray-400"}>{formatDateToReadable(campaignDetail?.created_at)}</p>
                                            <p className={"font-xxs"}>{campaignDetail?.point} Point</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={"m-5 grid grid-cols-3 gap-2"}>
                        <div className={"h-44"}>
                            <p className={"text-sm font-bold"}>Content</p>
                            <div className={"flex"}>
                                <embed src={contentDetail.content} className={"w-full pr-2"} width={150} height={200}/>
                            </div>
                        </div>
                        <div>
                            <p className={"text-sm font-bold"}>Caption</p>
                            <div className={"p-2 border h-52 overflow-scroll"}>
                                <p>{contentDetail.caption}</p>
                            </div>
                        </div>
                        <div className={"text-xs"}>
                            <p className={"mt-5"}>Published Content</p>
                            <p>{formatDayAndDateTime(contentDetail.created_at)}</p>

                            <p className={"mt-3 font-bold"}>Submit</p>
                            {approvalStatusContent()}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ContentModal

import React, {useEffect} from "react";

const LandingPage = () => {

    useEffect(() => {
        window.addEventListener('message', function (event) {
            if (event.origin.includes('staging.dashboard.lookecosmetics.com')) {
                if (event.data) {
                    localStorage.setItem("TOKEN_AUTH", event.data);
                } else {
                    localStorage.removeItem("TOKEN_AUTH");
                }
            }
        });

        return () => {
            window.removeEventListener('message', function (event) {
            })
        }
    }, [])

    return (
        <div className={"flex w-full items-center justify-center"}>
            Logout
        </div>
    )
}

export default LandingPage

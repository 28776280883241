import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const token = localStorage.getItem('TOKEN_AUTH');

const createCampaignApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/campaign/create`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const getCampaignListApi = (requestBody, params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/campaign`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const getCampaignDetailApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v4/admin/campaign/detail/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const userListCampaignApi = (id, requestBody, params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/user-campaign/${id}`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const approvalUserToEnterCampaignApi = (id, requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/user-campaign/approval/${id}`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const approvalContentApi = (id, requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/user-content/approval/${id}`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const sendProductApi = (id, requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user-campaign/send-product/${id}`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const approveDraftContentApi = (id, requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/user-content/approve-draft/${id}`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const doneCampaignApi = (id, requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/campaign/done/${id}`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const campaignDetailReportApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v4/admin/campaign-report/detail/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const getInfluencerListApi = (id, requestBody, params) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/campaign-report/influencer/${id}`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
                params
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const deleteUserFromCampaignApi = (id, campaignId) => {
    return new Promise((resolve, reject) => {
        axios.delete(
            `${cloudFunctionBaseUrl()}/api/v2/admin/user-campaign/${campaignId}/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const getContentResultCampaignApi = (campaignId) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v4/admin/campaign-report/report/${campaignId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const getContentFromCampaignApi = (campaignId, requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/campaign-report/content/${campaignId}`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                },
            },
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const addUserToCampaignApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/campaign/invite-user`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err.response)
        })
    })
}

const deleteCampaignApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v4/admin/campaign/delete/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err.response)
        })
    })
}

const requestReportCampaignApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v4/admin/campaign-report/request/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err.response)
        })
    })
}

const updateCampaignApi = (id, requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/api/v4/admin/campaign/update/${id}`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err.response)
        })
    })
}

const shareCampaignApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${cloudFunctionBaseUrl()}/api/v2/admin/campaign/share/${id}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err.response)
        })
    })
}

const deleteInfluencerApi = (campaignId, userId) => {
    return new Promise((resolve, reject) => {
        axios.delete(
            `${cloudFunctionBaseUrl()}/api/v4/admin/user-campaign/${campaignId}/${userId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': token
                }
            }
        )
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err.response)
        })
    })
}

export {
    createCampaignApi,
    getCampaignListApi,
    approvalUserToEnterCampaignApi,
    getCampaignDetailApi,
    userListCampaignApi,
    sendProductApi,
    approvalContentApi,
    approveDraftContentApi,
    doneCampaignApi,
    campaignDetailReportApi,
    getInfluencerListApi,
    deleteUserFromCampaignApi,
    getContentResultCampaignApi,
    addUserToCampaignApi,
    deleteCampaignApi,
    requestReportCampaignApi,
    updateCampaignApi,
    shareCampaignApi,
    getContentFromCampaignApi,
    deleteInfluencerApi
}

import React, {useEffect} from "react";
import {getUserDetailUserRegisteredApi, setUserStatusApi} from "../../api/user-registered.api";
import {useParams} from "react-router-dom";
import {formatDateToReadable} from "../../ui-util/general-variable";
import PotentialInfluencer from "../../images/svg/potential-influencer.svg";
import ApproveWhite from "../../images/svg/approve-white.svg";
import RejectWhite from "../../images/svg/reject-white.svg";
import {useDispatch, useSelector} from "react-redux";
import {USER_REGISTERED_REDUCER} from "../../reducers/user-registered.reducer";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";

const UserProfileComponent = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const {userDetail, reload} = useSelector(state => state.userRegisteredReducer)

    useEffect(() => {
        getUserDetailUserRegisteredApi(id)
            .then(response => {
                dispatch({type: USER_REGISTERED_REDUCER, payload: {userDetail: response}})
            })
            .catch(err => {
                console.log(err)
            })
    }, [reload])

    const setUserStatus = (status) => {
        let requestBody = {status}
        setUserStatusApi(userDetail.id, requestBody)
            .then(result => {
                dispatch({
                    type: MODAL_REDUCER,
                    payload: {userRegisteredText: 'Berhasil mengubah status user', display: 'block'}
                })
                dispatch({type: USER_REGISTERED_REDUCER, payload: {reload: !reload}})
            })
            .catch(err => {
                console.log(err)
            })
    }

    const userStatusContent = () => {
        switch (userDetail?.goddes_gang_status) {
            case 'approve':
                return <p className={"font-FuturaBT-Medium text-green-leaf font-bold"}>Approved</p>
                break;
            case 'reject':
                return <p className={"font-FuturaBT-Medium text-milano-red font-bold"}>Rejected</p>
                break;
            default:
                return (
                    <div className={"text-white flex flex-row space-x-2"}>
                        <button className={"bg-green-leaf h-7 px-5 flex flex-row items-center space-x-2"}
                                onClick={() => setUserStatus('approve')}
                        >
                            <img src={ApproveWhite} className={"w-4 h-4"}/>
                            <p>Accept</p>
                        </button>
                        <button className={"bg-milano-red h-7 px-5 flex flex-row items-center space-x-2"}
                                onClick={() => setUserStatus('reject')}
                        >
                            <img src={RejectWhite} className={"w-5 h-5"}/>
                            <p>Reject</p>
                        </button>
                    </div>
                )
        }
    }
    return (
        <div className={"font-FuturaBT-Book flex flex-row items-center justify-between space-x-24"}>
            <div className={"flex flex-row items-center space-x-5 text-tiny"}>
                <img src={userDetail?.photo} className={"w-16 h-16 rounded-full"}/>
                <div className={"flex flex-col space-y-1"}>
                    <p className={"font-FuturaBT-Medium capitalize text-lg"}>{userDetail?.full_name}</p>
                    <p className={""}>{formatDateToReadable(userDetail?.birth_date)}</p>
                    <div className={"flex flex-row items-center"}>
                        <img src={PotentialInfluencer} className={"h-3 w-3"}/>
                        <p className={" text-milano-red ml-1"}>Potential Influencer</p>
                    </div>
                </div>
            </div>
            <div className={"border-l pl-5 text-tiny flex flex-col space-y-2"}>
                <div>
                    <p className={"font-FuturaBT-Medium"}>Address</p>
                    <p>{userDetail?.address}</p>
                </div>
                <div>
                    <p className={"font-FuturaBT-Medium"}>Phone Number</p>
                    <p>{userDetail?.phone}</p>
                </div>
            </div>
            <div className={"border-l pl-5 text-tiny flex flex-col space-y-1"}>
                <p className={"font-FuturaBT-Medium"}>Approval</p>
                <p>{userDetail?.full_name} wants to be a member of Goddess Gang</p>
                {
                    userStatusContent()
                }
            </div>
        </div>
    )
}

export default UserProfileComponent

import React from "react";
import Chart from "react-apexcharts";
import {useSelector} from "react-redux";

const BarChartComponent = (props) => {
    const {title, data, tooltip} = props
    const {analyticsFacebookResult} = useSelector(state => state.analyticsReducer)
    return (
        <div className={"border p-3-responsive w-full"}>
            <div className={"flex flex-row items-center justify-between mb-3"}>
                <p className={"font-FuturaBT-Medium text-sm ml-5 font-semibold"}>{title}</p>
                <div className={"flex flex-col group relative"}>
                    <p className="invisible bg-gray-200 absolute z-10 text-xxs group-hover:visible border border-gray-300 p-3 rounded bottom-6 w-40 right-0">
                        {tooltip}
                    </p>
                    <button
                        className="text-sm bg-gray-200 w-5 h-5 rounded-full text-gray-400 hover:text-black inline-block">?
                    </button>
                </div>
            </div>

            <Chart
                height={150}
                width={120}
                type="bar"
                options={{
                    chart: {
                        toolbar: {
                            export: {
                                csv: {
                                    filename: 'Age Distribution - ' + analyticsFacebookResult?.username,
                                },
                                svg: {
                                    filename: 'Age Distribution - ' + analyticsFacebookResult?.username,
                                },
                                png: {
                                    filename: 'Age Distribution - ' + analyticsFacebookResult?.username,
                                }
                            }
                        }
                    },
                    tooltip: {
                        x: {show: false}
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '40%',
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        labels: {
                            style: {
                                colors: '#626262',
                                fontFamily: "'FuturaBT-Book'",
                                fontSize: 6,
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return (Math.ceil(value)).toFixed(0) + "%"
                            },
                            style: {
                                colors: '#626262',
                                fontFamily: "'FuturaBT-Book'",
                                fontSize: 6,
                            }
                        }
                    },
                }}

                series={[
                    {
                        name: "Jumlah",
                        data
                    }
                ]}
            />
        </div>
    )
}

export default BarChartComponent

const INITIAL_STATE = {
    groupListAll: [],
    groupToUpdate: '',
    groupList: [],
    groupDetail: {},
    groupDetailToShow: {},
    reload: false
}

export const GROUP_REDUCER = 'GROUP_REDUCER';

export const groupReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GROUP_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}

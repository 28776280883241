const INITIAL_STATE = {
    userDetail: {},
    analyticsFacebookResult: {},
    limit_similliar_influencer: 5
}

export const ANALYTICS_REDUCER = 'ANALYTICS_REDUCER';

export const analyticsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ANALYTICS_REDUCER:
            return {...state, ...action.payload}
        default:
            return state
    }
}

import React from "react";
import Warning from "../../images/svg/warning.svg";
import GreenCheckLargeIcon from "../../images/svg/approve-green-large.svg";
import {useDispatch, useSelector} from "react-redux";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";

const StandardModal = (props) => {
    const {type, message} = props
    const dispatch = useDispatch()
    const {standardModalVisibility} = useSelector(state => state.modalReducer)

    const renderImage = () => {
        switch (type) {
            case 'warning':
                return Warning
                break;
            case 'success':
                return GreenCheckLargeIcon
                break;
        }
    }

    const closeModal = () => {
        dispatch({type: MODAL_REDUCER, payload: {standardModalVisibility: 'hidden'}})
    }

    return (
        <div
            className={`${standardModalVisibility} fixed flex left-0 top-0 w-full h-full justify-center items-center block bg-gray-900 bg-opacity-40`}>
            <div className="flex flex-col w-2/6 bg-white p-5 border z-50 ">
                <button className={"flex justify-end"} onClick={() => closeModal()}>X</button>
                <div className={"flex flex-col justify-center items-center w-full h-full"}>
                    <img className={"animate-bounce font-FuturaBT-Book"} src={renderImage()}/>
                    <p className={"font-FuturaBT-Book mt-5"}>{message}</p>
                </div>
            </div>
        </div>
    )
}

export default StandardModal

import React, {useEffect, useState} from "react";
import ImageIcon from "../../images/svg/ico-plus-img.svg";
import {cloneDeep, filter} from "lodash";
import ImageIconGray from "../../images/svg/ico-img-grey.svg";
import SaveIcon from "../../images/svg/ico-save-white.svg";
import {uploadImageQuestionerApi} from "../../api/questioner.api";
import {QuestionerItemObject} from "../../models/questioner.model";
import {useDispatch, useSelector} from "react-redux";
import {QUESTIONER_REDUCER} from "../../reducers/questioner.reducer";

const EditQuestionerComponent = (props) => {
    const dispatch = useDispatch()
    const {item, index, saveDataEdit} = props
    const formRef = React.createRef()
    let {questionerFormDetail} = useSelector(state => state.questionerReducer)
    const [titleError, setTitleError] = useState(false)
    let [options, setOptions] = useState([])
    let [thumbnailImage, setThumbnailImage] = useState(null)

    useEffect(() => {
        setOptions(item.options)
        setThumbnailImage(item.image)
    }, [])

    const deleteDataFromOptionList = (i) => {
        options = filter(options, (item, idx) => {
            if (idx !== i) {
                return item
            }
        })
        setOptions(options)
    }

    const insertImageOptions = (event, text) => {
        const thumbnail = event.target.files[0]
        if (thumbnail.type.includes('image')) {
            let reader = new FileReader();
            reader.onloadend = () => {
                uploadImage(thumbnail, text)
            }
            reader.readAsDataURL(thumbnail)
        } else {
            alert('Mohon masukkan file image')
        }
    }

    const uploadImage = (image, text) => {
        const form = new FormData();
        form.append('image', image)
        uploadImageQuestionerApi(form)
            .then(response => {
                afterUpload(text, response)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const afterUpload = (text, response) => {
        if (text === 'option') {
            let data = [{
                type: 'image',
                is_correct: false,
                label: response
            }]
            let newOptionsLocal = options.concat(data)
            setOptions(newOptionsLocal)
        } else {
            setThumbnailImage(response)
        }
    }

    const saveButtonHandler = () => {
        const formValue = document.getElementById(`questionerForm${index}`).elements
        if (formValue.question.value.length > 0) {
            const optionValue = formValue.options
            let is_correct_available = false

            options.map((item, index) => {
                item.is_correct = optionValue[index].checked
                return item
            })

            options.filter(item => {
                if (item.is_correct === true) {
                    is_correct_available = true
                }
            })

            if (is_correct_available) {
                let questionerDetail = {...QuestionerItemObject}
                questionerDetail.question = formValue.question.value
                questionerDetail.image = thumbnailImage
                questionerDetail.options = options
                questionerDetail.sequence = questionerFormDetail.detail_unpublished[index].sequence
                questionerFormDetail.detail_unpublished[index] = questionerDetail
                dispatch({type: QUESTIONER_REDUCER, payload: {questionerFormDetail}})
                saveDataEdit()
            }
        } else {
            setTitleError(true)
        }
    }

    return (
        <div className={"flex flex-row border p-3"}>
            <div className={"w-7"}>
                <p
                    className={"w-5 h-5 bg-milano-red text-sm font-bold rounded-full text-white flex flex-col items-center justify-center"}>
                    {item.sequence}
                </p>
            </div>
            <div className={"flex flex-col w-full"}>
                <form id={`questionerForm${index}`} ref={formRef} className={"flex flex-col space-y-5 w-full"}>
                    <div>
                        <div className={"flex flex-row items-center justify-between "}>
                            <input name={"question"}
                                   className={"outline-none border w-11/12 h-7 px-3 hover:border-milano-red"}
                                   defaultValue={item.question}/>
                            <label className={"flex flex-row items-center cursor-pointer"}>
                                <img src={ImageIcon} className={"w-7 h-7"}/>
                                <input type={"file"} className={"hidden absolute top-0 right-0"}
                                       name={"thumbnail"}
                                       onChange={(e) => insertImageOptions(e, 'thumbnail')}/>
                            </label>
                        </div>
                        {titleError && <p className={"mt-3 text-milano-red"}>Harus diisi!</p>}
                    </div>
                    {
                        !!thumbnailImage &&
                        <div className={"flex flex-row items-center space-x-5"}>
                            <img src={thumbnailImage} className={"w-64"}/>
                            <button className={"text-xl text-gray-400"}
                                    onClick={() => setThumbnailImage(null)}>x
                            </button>
                        </div>
                    }
                    {
                        options.length > 0 &&
                        options.map((option, idx) => (
                            <div className={"flex flex-row items-center w-2/5 justify-between"} key={idx}>
                                <div className={"flex flex-row items-center space-x-5 w-11/12"}>
                                    <input type={"radio"} name={"options"} className={"accent-milano-red"}
                                           defaultChecked={option.is_correct}/>
                                    {
                                        option.type === 'image' ?
                                            <img name={"optionsLabel"} src={option.label} className={"w-20"}/> :
                                            <input
                                                name={option.label}
                                                value={option.label}
                                                className={"outline-none w-full focus:border-b focus:border-milano-red"}
                                                onChange={(e) => {
                                                    console.log(e.target.value)
                                                    options[idx].label = e.target.value
                                                    setOptions(options)
                                                }}
                                            />
                                    }
                                </div>
                                <div className={"text-xl text-gray-400 cursor-pointer"} key={idx}
                                     onClick={() => deleteDataFromOptionList(idx)}>x
                                </div>
                            </div>
                        ))
                    }
                    <div className={"flex flex-row items-center w-2/5 justify-between"}>
                        <div className={"flex flex-row items-center space-x-5 w-11/12"}>
                            <input type={"radio"} name={"options"} className={"accent-milano-red"} disabled={true}/>
                            <label className={"italic text-soft-dimgray cursor-pointer"} onClick={() => {
                                let data = [{
                                    type: 'text',
                                    is_correct: false,
                                    label: 'Opsi baru'
                                }]
                                let newDataOptions = cloneDeep(options)
                                let newOptionsLocal = newDataOptions.concat(data)
                                setOptions(newOptionsLocal)
                            }}>Tambah opsi</label>
                        </div>
                        <label className={"cursor-pointer"}>
                            <img src={ImageIconGray}/>
                            <input type={"file"} className={"hidden absolute top-0 right-0"}
                                   name={"thumbnail"}
                                   onChange={(e) => insertImageOptions(e, 'option')}/>
                        </label>
                    </div>
                </form>
                <div className={"flex flex-row-reverse"}>
                    <button className={"bg-milano-red text-white flex flex-row items-center space-x-2 pr-2"}
                            onClick={() => saveButtonHandler()}
                    >
                        <img src={SaveIcon} className={"w-6 h-6"}/>
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EditQuestionerComponent

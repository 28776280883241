import {Route, Routes} from "react-router-dom";
import {useEffect, useState} from "react";
import UserRegisteredPage from "../pages/user-registered";
import {PrivateRoute} from "./privateRoute";
import CreateMissionPage from "../pages/create-mission";
import RejectedPage from "../pages/rejected";
import GoddessGangPage from "../pages/goddess-gang";
import GroupPage from "../pages/group";
import CreateGroupPage from "../pages/create-group";
import CampaignPage from "../pages/campaign";
import LandingPage from "../pages/landing";
import GroupDetailPage from "../pages/group-detail";
import ApprovementCampaignPage from "../pages/approvement-campaign";
import ApprovalCampaign from "../pages/approval-campaign";
import UserDetailPage from "../pages/user-detail";
import UserDetailGoddessGangPage from "../pages/user-detail-goddess-gang";
import AnalyticsSocialMediaPage from "../pages/analytics-social-media";
import CampaignDetailPage from "../pages/campaign-detail";
import BannerGoddessGangPage from "../pages/banner-goddess-gang";
import QuestionerFormPage from "../pages/questioner-form";
import LogAffablePage from "../pages/log-affable";
import TimelinePage from "../pages/timeline";
import EditMissionPage from "../pages/edit-mission";
import {getDetailAdminApi} from "../api/user.api";
import {useDispatch, useSelector} from "react-redux";
import {TOOLS_REDUCER} from "../reducers/tools.reducer";
import RedeemPage from "../pages/redeem";
import RedeemDetailPage from "../pages/redeem-detail";

export default function RoutesPage() {
    const {adminDetail} = useSelector(state => state.toolsReducer)

    const [routeList] = useState([
        {
            path: '/mission/user-registered',
            component: <UserRegisteredPage/>
        }, {
            path: '/mission/rejected',
            component: <RejectedPage/>
        }, {
            path: '/mission/user-registered/user-detail/:id',
            component: <UserDetailPage/>
        }, {
            path: '/mission/rejected/user-detail/:id',
            component: <UserDetailPage/>
        }, {
            path: '/mission/user-registered/user-detail/1/:id',
            component: <AnalyticsSocialMediaPage/>
        }, {
            path: '/mission/user-registered/user-detail/2/:id',
            component: <AnalyticsSocialMediaPage/>
        }, {
            path: '/mission/user-registered/user-detail/4/:id',
            component: <AnalyticsSocialMediaPage/>
        }, {
            path: '/mission/user-registered/user-detail/5/:id',
            component: <AnalyticsSocialMediaPage/>
        }, {
            path: '/mission/rejected/user-detail/1/:id',
            component: <AnalyticsSocialMediaPage/>
        }, {
            path: '/mission/rejected/user-detail/2/:id',
            component: <AnalyticsSocialMediaPage/>
        }, {
            path: '/mission/rejected/user-detail/4/:id',
            component: <AnalyticsSocialMediaPage/>
        }, {
            path: '/mission/rejected/user-detail/5/:id',
            component: <AnalyticsSocialMediaPage/>
        }, {
            path: '/goddess-gang/gg/user-detail/1/:id',
            component: <AnalyticsSocialMediaPage/>
        }, {
            path: '/goddess-gang/gg/user-detail/2/:id',
            component: <AnalyticsSocialMediaPage/>
        }, {
            path: '/goddess-gang/gg/user-detail/4/:id',
            component: <AnalyticsSocialMediaPage/>
        }, {
            path: '/goddess-gang/gg/user-detail/5/:id',
            component: <AnalyticsSocialMediaPage/>
        }, {
            path: '/campaign/create-campaign',
            component: <CreateMissionPage/>
        }, {
            path: '/campaign/list-campaign/edit-campaign/:id',
            component: <EditMissionPage/>
        }, {
            path: '/campaign/list-campaign',
            component: <CampaignPage/>
        }, {
            path: '/campaign/list-campaign/detail-campaign/:id',
            component: <CampaignDetailPage/>
        }, {
            path: '/campaign/approvement-campaign',
            component: <ApprovementCampaignPage/>
        }, {
            path: '/campaign/approvement-campaign/:id',
            component: <ApprovalCampaign/>
        }, {
            path: '/goddess-gang/all-users',
            component: <GoddessGangPage/>
        }, {
            path: '/goddess-gang/gg/user-detail/:id',
            component: <UserDetailGoddessGangPage/>
        }, {
            path: '/goddess-gang/group',
            component: <GroupPage/>
        }, {
            path: '/goddess-gang/group/group-detail/:id',
            component: <GroupDetailPage/>
        }, {
            path: '/goddess-gang/group/create',
            component: <CreateGroupPage/>
        }, {
            path: '/setting/banner-goddess-gang',
            component: <BannerGoddessGangPage/>
        }, {
            path: '/setting/create-questioner-form',
            component: <QuestionerFormPage/>
        }, {
            path: '/setting/log-affable',
            component: <LogAffablePage/>
        }, {
            path: '/setting/create-timeline',
            component: <TimelinePage/>
        }, {
            path: '/redeem/redeem-point',
            component: <RedeemPage/>
        }, {
            path: '/redeem/redeem-point/redeem-detail/:id',
            component: <RedeemDetailPage/>
        },
    ])

    return (
        <Routes>
            <Route path="/" exact element={!!adminDetail.full_name ? <UserRegisteredPage/> : <LandingPage/>}/>
            {
                routeList.map((item, index) => (
                    <Route key={index.toString()} path={item.path}
                           element={<PrivateRoute component={item.component}/>}/>
                ))
            }
        </Routes>
    )
}

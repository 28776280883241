import React from "react";
import Folder from "../../images/svg/ico-folder.svg";
import {useDispatch, useSelector} from "react-redux";
import {updateBannerApi} from "../../api/banner.api";
import {BANNER_REDUCER} from "../../reducers/banner.reducer";

const BannerItemOtherComponent = () => {
    const dispatch = useDispatch()
    const {reload, bannerOtherList} = useSelector(state => state.bannerReducer)

    const imageProcessing = (event, bannerDetail) => {
        const thumbnail = event.target.files[0]
        if (thumbnail.type.includes('image')) {
            uploadImage(bannerDetail.title, thumbnail)
        } else {
            alert('Mohon masukkan file image')
        }
    }

    const uploadImage = (title, image) => {
        const form = new FormData();
        form.append('title', title)
        form.append('image', image)
        form.append('is_active', '1')

        updateBannerApi(form)
            .then(response => {
                dispatch({type: BANNER_REDUCER, payload: {reload: !reload}})
            })
            .catch(err => console.log(err))
    }

    return (
        <div className={"grid grid-cols-2 gap-16 my-10"}>
            {
                bannerOtherList.map((item, index) => {
                    return (
                        <div key={index}>
                            <p className={"font-bold text-gray-400 capitalize"}>{item.title}</p>
                            <div
                                className={"mt-5 border rounded-lg p-10 flex flex-col items-center justify-center text-xs h-full"}>
                                <div className={"h-3/4 flex  justify-center"}>
                                    <img src={item.image} className={"w-2/3 object-contain"}/>
                                </div>
                                <div className={"h-1/4 flex flex-col justify-center items-center"}>
                                    <p className={"mt-5 text-gray-400"}>Upload Gambar</p>
                                    <p className={"text-gray-400"}>Ukuran 382px X 115px</p>

                                    <div className={"mt-5"}>
                                        <label>
                                            <div
                                                className={"flex flex-row items-center space-x-2 bg-red-50 border-milano-red border py-1 px-3 cursor-pointer"}>
                                                <img src={Folder} className={" w-6 h-6 "}/>
                                                <p>Choose File</p>
                                            </div>
                                            <input type={"file"} className={"hidden absolute top-0 right-0"}
                                                   name={"thumbnail"}
                                                   onChange={(e) => imageProcessing(e, item)}/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default BannerItemOtherComponent

import React from "react";

const TimelineItemComponent = (props) => {
    const {item, index} = props

    return (
        <div key={index} className={"grid grid-cols-2 gap-10"}>
            <div className={"flex flex-col space-y-2"}>
                <p className={"font-bold"}>Title</p>
                <input defaultValue={item.title} disabled={true}
                       className={"border h-8 px-5 text-xs font-FuturaBT-Book"}/>
            </div>
            <div className={"flex flex-col space-y-2"}>
                <p className={"font-bold"}>Description</p>
                <textarea name={item.title} defaultValue={item.text} className={"border p-3 text-xs font-FuturaBT-Book"}/>
            </div>
        </div>
    )
}

export default TimelineItemComponent

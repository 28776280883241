import React from "react";
import Folder from "../../images/svg/ico-img.svg";
import RtfComponent from "../../components/rtf.component";
import {useDispatch, useSelector} from "react-redux";
import {uploadImageQuestionerApi} from "../../api/questioner.api";
import {QUESTIONER_REDUCER} from "../../reducers/questioner.reducer";

const IntroductionComponent = () => {
    const dispatch = useDispatch()
    const {questionerFormDetail} = useSelector(state => state.questionerReducer)

    const insertImage = (event) => {
        const thumbnail = event.target.files[0]
        if (thumbnail.type.includes('image')) {
            let reader = new FileReader();
            reader.onloadend = () => {
                uploadImage(thumbnail)
            }
            reader.readAsDataURL(thumbnail)
        } else {
            alert('Mohon masukkan file image')
        }
    }

    const uploadImage = (image) => {
        const form = new FormData();
        form.append('image', image)
        uploadImageQuestionerApi(form)
            .then(response => {
                dispatch({
                    type: QUESTIONER_REDUCER,
                    payload: {questionerFormDetail: {...questionerFormDetail, image: response}}
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div className={"font-FuturaBT-Book text-sm"}>
            <p className={"text-2xl font-bold border-b pb-1 border-milano-red"}>Introduction</p>
            <div className={"border p-3 mt-5"}>
                <div className={"flex flex-row items-center space-x-5"}>
                    <input className={"border w-1/2 hover:border-milano-red outline-none px-3 h-6"}
                           defaultValue={questionerFormDetail?.title}
                           onChange={(e) => {
                               dispatch({
                                   type: QUESTIONER_REDUCER, payload: {
                                       questionerFormDetail: {
                                           ...questionerFormDetail,
                                           title: e.target.value
                                       }
                                   }
                               })
                           }}
                    />
                    <div>
                        <label>
                            <div
                                className={"flex flex-row items-center space-x-2 bg-gray-200 cursor-pointer text-sm pr-2"}>
                                <img src={Folder} className={"w-6 h-6"}/>
                                <p>Insert image</p>
                            </div>
                            <input type={"file"} className={"hidden absolute top-0 right-0"}
                                   name={"thumbnail"}
                                   onChange={(e) => insertImage(e)}/>
                        </label>
                    </div>
                </div>
                {
                    !!questionerFormDetail?.image &&
                    <div className={"flex flex-row items-center mt-5 space-x-5"}>
                        <img src={questionerFormDetail?.image} className={"w-64"}/>
                        <button className={"text-xl text-gray-400"} onClick={() => {
                            dispatch({
                                type: QUESTIONER_REDUCER, payload: {
                                    questionerFormDetail: {
                                        ...questionerFormDetail,
                                        image: null
                                    }
                                }
                            })
                        }}>x
                        </button>
                    </div>}
                <div className={"h-44 mt-5"}>
                    {
                        !!questionerFormDetail?.description &&
                        <RtfComponent
                            value={questionerFormDetail?.description}
                            onChange={(value) => {
                                dispatch({
                                    type: QUESTIONER_REDUCER, payload:
                                        {questionerFormDetail: {...questionerFormDetail, ...{description: value}}}
                                })
                            }}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default IntroductionComponent

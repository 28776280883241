import React from "react";
import Detail from '../../images/svg/ico-detail.svg'
import Trash from '../../images/svg/trash-red.svg'
import Approval from "../../images/svg/ico-check-white.svg";
import Report from "../../images/svg/ico-info.svg";
import AddInfluencer from "../../images/svg/add-friends.svg";
import Share from "../../images/svg/ico-share.svg";
import Edit from "../../images/svg/ico-edit.svg";
import {useDispatch, useSelector} from "react-redux";
import {formatDateToReadable} from "../../ui-util/general-variable";
import {Link} from "react-router-dom";
import {MODAL_REDUCER} from "../../reducers/modal.reducer";
import {CAMPAIGN_REDUCER} from "../../reducers/campaign.reducer";
import {requestReportCampaignApi, shareCampaignApi} from "../../api/campaign.api";

const CampaignListComponent = () => {
    const dispatch = useDispatch()
    const {activeTab, reload, campaignList} = useSelector(state => state.campaignReducer)

    const requestCampaignReport = (item) => {
        requestReportCampaignApi(item.id)
            .then(response => {
                dispatch({type: CAMPAIGN_REDUCER, payload: {reload: !reload}})
                dispatch({
                    type: MODAL_REDUCER,
                    payload: {
                        display: 'block',
                        successModalText: 'Report has been successfully requested. You just can request report campaign one times!'
                    }
                })
            })
            .catch(err => console.log(err))
    }

    const shareCampaignButton = (id) => {
        shareCampaignApi(id)
            .then(response => {
                dispatch({
                    type: MODAL_REDUCER,
                    payload: {display: 'block', successModalText: 'Send notification success'}
                })
            })
            .catch(err => console.log(err))
    }

    const campaignActionButton = (item) => {
        switch (activeTab) {
            case 'upcoming':
                return (
                    <div className={"flex flex-row justify-between mt-3 items-center"}>
                        <button
                            onClick={() => shareCampaignButton(item.id)}
                            className={"flex flex-row items-center justify-center space-x-2 bg-milano-red py-2 px-3"}>
                            <img src={Share} className={"w-4 h-4"}/>
                            <p className={"text-white text-sm"}>Share</p>
                        </button>
                        <Link to={"/goddess-gang/all-users"}>
                            <button
                                className={"flex flex-row items-center justify-center space-x-2 bg-milano-red py-2 px-3"}>
                                <img src={AddInfluencer} className={"w-4 h-4"}/>
                                <p className={"text-white text-sm"}>Add Influencer</p>
                            </button>
                        </Link>
                        <p className={"font-bold text-sm"}>{item.point} Point</p>
                    </div>
                )
                break;
            case 'approvement':
            case 'ongoing':
                return (
                    <>
                        <div className={"flex flex-row justify-between items-center mt-3"}>
                            <div className={"flex flex-row items-center space-x-1 h-10"}>
                                {
                                    item.campaign_users.slice(0, 3).map((item, index) => (
                                        <img src={item.user.photo} className={"w-10 h-10 rounded-full"}
                                             key={index}/>
                                    ))
                                }
                                <p className={"text-gray-400 text-sm"}>{item.total_user} Orang</p>
                            </div>
                            <p className={"font-bold text-sm"}>{item.point} Point</p>
                        </div>
                        <div className={"flex flex-row space-x-2 mt-5"}>
                            <Link to={`/campaign/approvement-campaign/${item.id}`}>
                                <button className={"bg-milano-red flex flex-row items-center py-2 px-3 space-x-1"}>
                                    <img src={Approval} className={"w-5 h-5"}/>
                                    <p className={"text-white"}>Approval</p>
                                </button>
                            </Link>
                        </div>
                    </>
                )
                break;
            case 'past':
                return (
                    <div className={"space-y-3"}>
                        <div className={"flex flex-row justify-between items-center mt-3"}>
                            <div className={"flex flex-row items-center space-x-1 h-10"}>
                                {
                                    item.campaign_users.slice(0, 3).map((item, index) => (
                                        <img src={item.user.photo} className={"w-10 h-10 rounded-full"}
                                             key={index}/>
                                    ))
                                }
                                <p className={"text-gray-400 text-sm"}>{item.total_user} Orang</p>
                            </div>
                            <p className={"font-bold text-sm"}>{item.point} Point</p>
                        </div>
                        <div className={"flex flex-row space-x-2"}>
                            <Link to={`/campaign/approvement-campaign/${item.id}`}>
                                <button className={"bg-milano-red flex flex-row items-center py-2 px-3 space-x-1"}>
                                    <img src={Approval} className={"w-5 h-5"}/>
                                    <p className={"text-white"}>Approval</p>
                                </button>
                            </Link>
                            <button
                                className={"bg-milano-red flex flex-row items-center py-2 px-3 space-x-1 disabled:opacity-60"}
                                onClick={() => requestCampaignReport(item)}
                                disabled={item.is_report_requested !== 0}
                            >
                                <img src={Report} className={"w-5 h-5"}/>
                                <p className={"text-white"}>{item.is_report_requested === 0 ? 'Report' : 'Requested'}</p>
                            </button>
                        </div>
                    </div>
                )
            default:
                return (
                    <p className={"font-bold text-sm text-right mt-3"}>{item.point} Point</p>
                )
        }
    }

    const dateShowed = (item) => {
        switch (activeTab) {
            case 'upcoming':
                return 'Publish'
                break;
            case 'approvement':
            case 'ongoing':
                return formatDateToReadable(item.campaign_start_date)
                break;
            case 'past':
                return formatDateToReadable(item.campaign_end_date)
                break;
            default:
                return ''
        }
    }

    return (
        <>
            <div className={"campaign-list"}>
                {
                    campaignList.map((item, index) => (
                        <div key={index}>
                            <Link to={`/campaign/list-campaign/detail-campaign/${item.id}`}
                                  className={"hover:text-milano-red"}>
                                <img className={"h-48 w-full object-cover"} src={item.thumbnail}/>
                            </Link>
                            <div className={"flex flex-row justify-between mt-5"}>
                                <p className={"font-FuturaBT-Medium font-bold"}>{item.title}</p>
                                <div className={"flex flex-row space-x-1 "}>
                                    {
                                        (activeTab === 'past' || activeTab === 'ongoing') ?
                                            <Link to={`/campaign/list-campaign/detail-campaign/${item.id}`}>
                                                <button
                                                    className={"bg-linen border-milano-red flex flex-row items-center justify-center border rounded-full w-8 h-8"}>
                                                    <img className={"w-3 h-3 "} src={Detail}/>
                                                </button>
                                            </Link>
                                            :
                                            <Link to={`/campaign/list-campaign/edit-campaign/${item.id}`}>
                                                <button
                                                    className={"bg-linen border-milano-red flex flex-row items-center justify-center border rounded-full w-8 h-8"}>
                                                    <img className={"w-3 h-3 "} src={Edit}/>
                                                </button>
                                            </Link>
                                    }

                                    <button
                                        className={"bg-linen border-milano-red flex flex-row items-center justify-center border rounded-2xl w-8 h-8"}
                                        onClick={() => {
                                            dispatch({
                                                type: MODAL_REDUCER,
                                                payload: {deleteGroupModalVisibility: 'block'}
                                            })
                                            dispatch({
                                                type: CAMPAIGN_REDUCER, payload: {
                                                    campaignIdToDelete: item.id
                                                }
                                            })
                                        }}
                                    >
                                        <img className={"w-5 h-5 "} src={Trash}/>
                                    </button>
                                </div>
                            </div>
                            <p className={"text-gray-400 text-sm"}>{dateShowed(item)}</p>
                            {campaignActionButton(item)}
                        </div>
                    ))
                }
            </div>
            {
                campaignList.length === 0 &&
                <div className={"font-FuturaBT-Book flex justify-center w-full p-5"}>No data</div>
            }
        </>
    )
}

export default CampaignListComponent

import React, {useEffect, useState} from "react";
import SelectMultipleComponent from "../../components/select-multiple.component";
import {useDispatch, useSelector} from "react-redux";
import RtfComponent from "../../components/rtf.component";
import {CREATE_CAMPAIGN_REDUCER} from "../../reducers/create-campaign.reducer";
import ErrorMessageComponent from "../../components/error-message.component";

const CampaignSendProductComponent = () => {
    const dispatch = useDispatch()
    const {selectDataProduct} = useSelector(state => state.toolsReducer)
    const {
        shipment_instruction,
        createCampaignErrorMessage,
        campaignDetailEdit
    } = useSelector(state => state.createCampaignReducer)
    const [sendProduct, setSendProduct] = useState(0)

    useEffect(() => {
        if (!!campaignDetailEdit.flag_send_product) {
            setSendProduct(campaignDetailEdit.flag_send_product)
        }
    }, [campaignDetailEdit])

    const onChangeRtf = (value) => {
        dispatch({type: CREATE_CAMPAIGN_REDUCER, payload: {shipment_instruction: value}})
    }

    return (
        <div>
            <p className={"text-2xl text-gray-400 font-FuturaBT-Medium font-bold"}>Send Product</p>
            <div className={"grid grid-cols-2 gap-y-10 my-5"}>
                <div className={"pr-10"}>
                    <div className={"flex flex-row items-center space-x-5"}>
                        {
                            [{label: 'Send', value: 1}, {label: 'No', value: 0}].map((item, index) => {
                                return (
                                    <div className={"flex flex-row items-center space-x-2"} key={index.toString()}>
                                        <input type={"radio"} name="flag_send_product" value={item.value}
                                               className={"accent-milano-red"}
                                               checked={sendProduct === item.value}
                                               onChange={() => setSendProduct(item.value)}
                                        />
                                        <p className={"text-sm font-FuturaBT-Book"}>{item.label}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        sendProduct === 1 && selectDataProduct.length > 0 &&
                        <SelectMultipleComponent placeholder={'pilih product'} data={selectDataProduct}/>
                    }
                    {!!createCampaignErrorMessage['flag_send_product'] &&
                        <ErrorMessageComponent message={createCampaignErrorMessage['flag_send_product']}/>}
                </div>
                {
                    sendProduct === 1 && selectDataProduct.length > 0 &&
                    <div className={"h-52"}>
                        <p className={"text-tiny font-FuturaBT-Medium mb-2"}>Shipment Address Instruction</p>
                        <div className={"mb-12"}>
                            <RtfComponent
                                value={shipment_instruction}
                                onChange={onChangeRtf}
                            />
                        </div>
                        {!!createCampaignErrorMessage['shipment_instruction'] &&
                            <ErrorMessageComponent message={createCampaignErrorMessage['shipment_instruction']}/>}
                    </div>
                }
            </div>
        </div>
    )
}

export default CampaignSendProductComponent

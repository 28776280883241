import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {USER_REGISTERED_REDUCER} from "../reducers/user-registered.reducer";
import PaginationComponent from "./pagination.component";
import SortingTableComponent from "./sorting-table.component";
import {filter} from "lodash";

const TableComponent = (props) => {
    const {columns, dataSource} = props
    const dispatch = useDispatch()
    const {selectedRowKeys, selectedRows} = useSelector(state => state.userRegisteredReducer)
    const [checkedAll, setCheckedAll] = useState(false)

    useEffect(() => {
        return () => {
            setCheckedAll(false)
            dispatch({type: USER_REGISTERED_REDUCER, payload: {selectedRowKeys: [], selectedRows: []}})
        }
    }, [dataSource])

    const onCheckedAllHandler = (e) => {
        if (e.target.checked) {
            let data = getRowKeys(dataSource)
            dispatch({type: USER_REGISTERED_REDUCER, payload: {selectedRowKeys: data.rowKeys, selectedRows: data.rows}})
        } else {
            dispatch({type: USER_REGISTERED_REDUCER, payload: {selectedRowKeys: [], selectedRows: []}})
        }
        setCheckedAll(e.target.checked)
    }

    const getRowKeys = (data) => {
        let rowKeys = []
        let rows = []
        data.forEach(item => {
            if(!!item.campaign_users && (item?.campaign_users[0]?.status === 'Menunggu Konfirmasi' || item?.campaign_users[0]?.status === 'Done')){
                return
            } else {
                rowKeys.push(item.id)
                rows.push(item)
            }
        })

        return {rowKeys, rows}
    }

    const onCheckHandler = (e, value) => {
        let rows = []

        if (e.target.checked) {
            rows = [...selectedRows, value]
        } else {
            rows = selectedRows.filter((item) => item !== value)
        }

        let data = getRowKeys(rows)

        dispatch({type: USER_REGISTERED_REDUCER, payload: {selectedRowKeys: data.rowKeys, selectedRows: rows}})
    }

    return (
        <>
            <>
                <table className={'font-FuturaBT-Book mt-10 w-full'}>
                    <thead className={"text-milano-red"}>
                    <tr className={"bg-snow h-10"}>
                        {
                            columns.map((item, index) => {
                                switch (item.type) {
                                    case "checkbox":
                                        return (
                                            <th data-priority={(index + 1).toString()} key={index.toString()}>
                                                <div
                                                    className={"flex flex-col p-1.5 items-center justify-around border-r-2 border-r-white"}>
                                                    <input type={"checkbox"} className={"accent-milano-red"}
                                                           checked={checkedAll || (selectedRowKeys.length > 0 && (selectedRowKeys.length === dataSource.length))}
                                                           onChange={e => onCheckedAllHandler(e)}/>
                                                </div>
                                            </th>
                                        )
                                    default:
                                        return (
                                            <th data-priority={(index + 1).toString()} key={index.toString()}>
                                                <div
                                                    className={`${item.isRightBorderAvailable && 'border-r-2 border-r-white'} flex flex-row items-center justify-around`}>
                                                    <div className={"flex flex-row items-center space-x-2"}>
                                                        {item.icon &&
                                                            <img className={"img-3-responsive"} src={item.icon}/>}
                                                        <p className={"text-xs-responsive"}>{item.name}</p>
                                                    </div>
                                                    {item.arrow &&
                                                        <SortingTableComponent orderBy={item.dataIndex}/>}
                                                </div>
                                                <span className={"h-10 bg-black w-3"}/>
                                            </th>
                                        )
                                }
                            })
                        }
                    </tr>
                    </thead>

                    <tbody className={"mt-40"}>
                    {
                        dataSource.map((item, index) => (
                            <tr key={index.toString()}>
                                {
                                    columns.map((columnItem, idx) => {
                                            if (idx === 0) {
                                                return (
                                                    <td key={index.toString().concat(idx.toString())}
                                                        className={"text-center"}>
                                                        <input type={"checkbox"}
                                                               className={"accent-milano-red h-32 border"}
                                                               checked={selectedRowKeys.includes(item.id)}
                                                               disabled={!!item?.campaign_users && (item?.campaign_users[0].status === 'Menunggu Konfirmasi' || item.campaign_users[0].status === 'Done')}
                                                               onChange={(e) => onCheckHandler(e, item, index)}/>
                                                    </td>
                                                )
                                            } else {
                                                if (!!columnItem.render) {
                                                    return <td
                                                        key={index.toString().concat(idx.toString())}>{columnItem.render(item, item[columnItem.dataIndex])}</td>
                                                }
                                                return (
                                                    <td key={index.toString().concat(idx.toString())}
                                                        className={"text-center"}>{item[columnItem.dataIndex]}</td>
                                                )
                                            }
                                        }
                                    )
                                }
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                {dataSource.length === 0 &&
                    <div
                        className={"flex flex-row justify-center mt-20 items-center text-milano-red font-FuturaBT-Book"}>No
                        data</div>}
                <PaginationComponent/>
            </>
        </>
    )
}

export default TableComponent
